export const SUPPORTED_CITY = [
  { key: "nablus", title: "نابلس" },
  { key: "ramallah", title: "رام الله" },
  { key: "jerusalem", title: "القدس" },
  { key: "bethlahem", title: "بيت لحم" },
  { key: "hebron", title: "الخليل" },
  { key: "tulkarem", title: "طولكرم" },
  { key: "qalqilya", title: "قلقيلية" },
  { key: "tubas", title: "طوباس" },
  { key: "jenin", title: "جنين" },
  { key: "jarico", title: "اريحا" },
  { key: "salfet", title: "سلفيت" },
  { key: "48area", title: "مناطق 48" },
  { key: "east_jerusalem", title: "القدس الشرقية" },
  { key: "west_jerusalem", title: "القدس الغربية" },
  { key: "outskirts_jerusalem", title: "ضواحي القدس" },
];
