import { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import OrganizationService from "service/organization";

const OrganizationInfo = (props) => {
  const [organization, setOrganization] = useState(null);

  useEffect(() => {
    populate();
  }, [props]);

  const populate = () => {
    OrganizationService.getCurrentOrganization(false).then((res) => {
      setOrganization(res.data);
    });
  };

  return (
    <Card className="bg-secondary shadow card">
      <CardHeader className="bg-white border-0">
        <Row className="align-items-center">
          <Col lg="12">
            <h3 className="mb-0">معلومات التاجر</h3>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        {organization && (
          <Row>
            <Col lg="12">
              <p className="font-weight-bold">{organization.name}</p>
            </Col>

            <Col lg="12">
              <p>{organization.address}</p>
            </Col>
            <Col lg="12">
              <p>{organization.phone}</p>
            </Col>
          </Row>
        )}
      </CardBody>
    </Card>
  );
};

export default OrganizationInfo;
