import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MessageService from "service/message";

const ChatList = (props) => {
  const [list, setList] = useState([]);
  const [search, setSearch] = useState("");

  const populateList = () => {
    MessageService.gerMessageSummary(-1).then((res) => {
      setList(res.data);
    });
  };

  useEffect(() => {
    populateList();
  }, [props]);

  const renderPoto = (message) => {
    if (message.userPhoto) {
      return <img src={message.userPhoto} />;
    } else {
      return (
        <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
          <i className="fas fa-user"></i>
        </div>
      );
    }
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="chat-header-title">الرسائل</div>
      </div>
      <div className="search-box">
        <input type="text" placeholder="بحث..." onChange={(event) => setSearch(event.target.value)} />
      </div>
      <div className="chat-list">
        <ul>
          {list
            .filter((m) => m.userName.indexOf(search.trim()) !== -1)
            .map((m, i) => {
              return (
                <li key={i} className={m.userId == props.selectedUserId ? "selected" : ""}>
                  <Link to={"/messages?userId=" + m.userId}>
                    <div className="message-item">
                      {renderPoto(m)}
                      <div className="mr-2">
                        <p className="name">
                          {m.userName}
                          <span className="mr-2 badge badge-primary text-dark">
                            {m.lastMessageDate} {m.lastMessageTime}
                          </span>
                        </p>
                        <p className="last-message">{m.lastMessage}</p>
                      </div>
                    </div>
                  </Link>
                </li>
              );
            })}
        </ul>
      </div>
    </>
  );
};

export default ChatList;
