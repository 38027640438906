import {useState } from "react";
import { trackPromise } from "react-promise-tracker";
import {Container} from "reactstrap";
import CustomerService from "service/customer";
import CustomerTable from "./CustomerTable";

const CustomersList = (props) => {
  const [customerList, setCustomerList] = useState([]);
  const [totalCustomers, setTotalCustomer] = useState([]);

  const populateCustomerList = (props) => {
    trackPromise(
      CustomerService.getCustomerList({ searchTerm: "", pageSize: props.pageSize, pageNumber: props.pageNumber }).then((res) => {
        setTotalCustomer(res.data.totalCustomers);
        setCustomerList(res.data.customers);
      })
    );
  };

  return (
    <>
      <Container className="pt-2 pt-md-8" fluid>
        {customerList && <CustomerTable data={customerList} totalCustomers={totalCustomers} onDataChange={populateCustomerList} />}
        <p></p>
      </Container>
    </>
  );
};

export default CustomersList;
