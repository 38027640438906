import { Card, CardBody, CardHeader, Container, Row, Table } from "reactstrap";

import CreateNewOrganizationhModal from "components/Modals/Organization/CreateNewOrganizationhModal";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OrganizationService from "service/organization";
import UtilityService from "service/utility";
import { trackPromise } from "react-promise-tracker";

const OrganizationManagment = (props) => {
  const [organizationList, setOrganizationList] = useState([]);

  useEffect(() => {
    populateOrganizationList();
  }, [props]);

  const populateOrganizationList = () => {
    trackPromise(
      OrganizationService.getAllOrganizationList(false, true).then((res) => {
        setOrganizationList(res.data);
      })
    );
  };

  return (
    <>
      <Container className="pt-2 pt-md-8" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">ادارة الشركات</h3>
                  </div>
                  <div className="col text-left">
                    <i className="fas fa-redo mx-4" role="button" onClick={populateOrganizationList}></i>
                    <CreateNewOrganizationhModal onChange={populateOrganizationList} />
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="p-0">
                <Row className="m-0">
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th className="px-4">#</th>
                        <th>الاسم</th>
                        <th>المدينة</th>
                        <th>العنوان</th>
                        <th>تايخ الانشاء</th>
                        <th className="text-center">عدد الطرود</th>
                        <th className="text-center">مجموع التحصيلات</th>
                        <th className="text-center">عدد الفروع</th>
                        <th className="text-center">عدد المستخدمين</th>
                        <th>الحالة</th>
                      </tr>
                    </thead>
                    <tbody>
                      {organizationList.map(function (organization) {
                        return (
                          <tr key={organization.id}>
                            <td className="px-4">
                              <Link className="text-dark text-underline" to={"/organization/" + organization.id}>
                                {organization.id}
                              </Link>
                            </td>
                            <td>{organization.name}</td>
                            <td>{UtilityService.renderCity(organization.city)}</td>
                            <td>{organization.address}</td>
                            <td>{UtilityService.formatTimestamp(organization.created)}</td>
                            <td className="text-center">{organization.totalPackages || 0}</td>
                            <td className="text-center">{UtilityService.formatPrice(organization.totalCollections)}</td>
                            <td className="text-center">{organization.totalBranchs || 0}</td>
                            <td className="text-center">{organization.totalUsers || 0}</td>
                            <td>{UtilityService.renderActiveOrInactiveStatus(organization.status)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default OrganizationManagment;
