export const MESSAGES = {
  PACKAGE_STATUS_N: {
    text: "جديد",
    badge: "success",
    hint: "",
  },
  PACKAGE_STATUS_J: {
    text: "تم الالغاء",
    badge: "danger",
    hint: "تم الغاء الطرد من قبل التاجر قبل التسليم.",
  },
  PACKAGE_STATUS_M: {
    text: "جاهز لشحن",
    badge: "info",
    hint: "الطرد جاهز لشحن من قبل التاجر.",
  },
  PACKAGE_STATUS_D: {
    text: "تم التوصيل",
    badge: "success",
    hint: "تم توصيل الطرد لزبون.",
  },
  PACKAGE_STATUS_A: {
    text: "قيد الفرز",
    badge: "info",
    hint: "تم توصيل الطرد من التاجر الى مركز الفرز",
  },
  PACKAGE_STATUS_P: {
    text: "استلام الطرد من التاجر",
    badge: "info",
    hint: "تم استلام الطرد من التاجر",
  },
  PACKAGE_STATUS_G: {
    text: "قيد التغليف",
    badge: "info",
    hint: "قيد التغليف",
  },
  PACKAGE_STATUS_E: {
    text: "جاهز لتوصيل",
    badge: "info",
    hint: "جاهز لتوصيل",
  },
  PACKAGE_STATUS_O: {
    text: "قيد التوصيل",
    badge: "info",
    hint: "قيد التوصيل",
  },
  PACKAGE_STATUS_V: {
    text: "تعذر التوصيل",
    badge: "info",
    hint: "تعذر التوصيل",
  },
  PACKAGE_STATUS_R: {
    text: "تم الرفض من قبل الزبون",
    badge: "info",
    hint: "تم الرفض من قبل الزبون",
  },
  PACKAGE_STATUS_C: {
    text: "قيد االرجاع",
    badge: "info",
    hint: "قيد االرجاع",
  },
  PACKAGE_STATUS_X: {
    text: "مرتجع",
    badge: "info",
    hint: "مرتجع",
  },
  PACKAGE_STATUS_L: {
    text: "مفقود",
    badge: "info",
    hint: "مفقود",
  },
  PACKAGE_STATUS_H: {
    text: "مكتمل راجع",
    badge: "dangers",
    hint: "مكتمل راجع",
  },
  PACKAGE_STATUS_Z: {
    text: "مكتمل",
    badge: "success",
    hint: "مكتمل",
  },
  PACKAGE_STATUS_S: {
    text: "مؤجل",
    badge: "info",
    hint: "مؤجل",
  },
  COLLECTION_STATUS_NEW: {
    text: "جديد",
    badge: "info",
    hint: "جديد",
  },
  COLLECTION_STATUS_PAID: {
    text: "مدفوع",
    badge: "success",
    hint: "مدفوع",
  },
};
