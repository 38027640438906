import { Card, CardBody, CardHeader, Container, Row, Table } from "reactstrap";

import CreateNewBranchModal from "components/Modals/CreateNewBranchModal";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import OrganizationService from "service/organization";
import UtilityService from "service/utility";
import BranchActions from "./BranchActions";

const BranchManagment = (props) => {
  const [branchList, setBranchList] = useState([]);

  useEffect(() => {
    populateBranchList();
  }, [props]);

  const populateBranchList = () => {
    trackPromise(
      OrganizationService.getBranchList().then((res) => {
        setBranchList(res.data);
      })
    );
  };

  const onDisableBranch = (branchId) => {
    trackPromise(
      OrganizationService.updateBranchStatus(branchId, false).then((res) => {
        populateBranchList();
      })
    );
  };

  const onEnableBranch = (branchId) => {
    trackPromise(
      OrganizationService.updateBranchStatus(branchId, true).then((res) => {
        populateBranchList();
      })
    );
  };

  const onEditBranch = () => {
    populateBranchList();
  };

  return (
    <>
      <Container className="pt-2 pt-md-8" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">قائمة الفروع</h3>
                  </div>
                  <div className="col text-left">
                    <i className="fas fa-redo mx-4" role="button" onClick={populateBranchList}></i>
                    <CreateNewBranchModal title="انشاء فرع جديد" onChange={populateBranchList} />
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="p-0">
                <Row className="m-0">
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th className="pr-4">الاسم</th>
                        <th>تايخ الانشاء</th>
                        <th>المدينة</th>
                        <th>العنوان</th>
                        <th>هاتف</th>
                        <th className="text-center">عدد الطرود</th>
                        <th className="text-center">مجموع التحصيلات</th>
                        <th>الحالة</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {branchList.map(function (row) {
                        const branch = row.branch;
                        return (
                          <tr key={branch.id}>
                            <td className="pr-4">{branch.name}</td>
                            <td>{UtilityService.formatTimestamp(branch.created)}</td>
                            <td>{branch.city}</td>
                            <td>
                              {UtilityService.renderCity(branch.city)}, {row.branch.address}
                            </td>
                            <td>{branch.phone}</td>
                            <td className="text-center">{row.totalPackage}</td>
                            <td className="text-center">{UtilityService.formatPrice(row.totalCollection)}</td>
                            <td>{UtilityService.renderActiveOrInactiveStatus(branch.status)}</td>
                            <td>
                              <BranchActions
                                branch={row.branch}
                                onDisableBranch={() => onDisableBranch(branch.id)}
                                onEnableBranch={() => onEnableBranch(branch.id)}
                                onEditBranch={onEditBranch}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default BranchManagment;
