import APIClient from "./axios.js";
import { GET_AREA_BY_CITY_API_URL, GET_ALL_AREA_API_URL } from "../variables/constant";

const AreaAPI = {
  getAreaByCity: function (city) {
    return APIClient.get(GET_AREA_BY_CITY_API_URL.replace("{city}", city));
  },
  getAllArea: function () {
    return APIClient.get(GET_ALL_AREA_API_URL);
  },
};

export default AreaAPI;
