import APIClient from "./axios.js";
import {
  GET_ALL_LOGS_FILE_API_URL,
  GET_LOGS_FILE_CONTENT_API_URL,
  DOWNLOAD_LOGS_FILE_API_URL,
} from "../variables/constant";

const LogsAPI = {
  getLogsFileList: function () {
    return APIClient.get(GET_ALL_LOGS_FILE_API_URL);
  },
  getLogsFileContent: function (fileName) {
    return APIClient.get(GET_LOGS_FILE_CONTENT_API_URL, { params: { fileName } });
  },
  downloadLogsFile: function (fileName) {
    return APIClient.get(DOWNLOAD_LOGS_FILE_API_URL, { params: { fileName } });
  },
};

export default LogsAPI;
