import React from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { authService } from "service/auth";

const PrivateRoute = (props) => {
  const isLogin = authService.isLogin();

  return isLogin ? <Outlet /> : <Routes><Route path="*" element={<Navigate to="/auth/login" replace={true} />} /></Routes>;
};

export default PrivateRoute;
