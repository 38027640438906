import StatisticAPI from 'api/statistic';

const StatisticService = {
  getCustomerStatistic: function () {
    return StatisticAPI.getCustomerStatistic();
  },
  getPackageByCityStatistic: function () {
    return StatisticAPI.getPackageByCityStatistic();
  },
  getCollectionStatistic: function () {
    return StatisticAPI.getCollectionStatistic();
  },
  getSummaryStatistic: function () {
    return StatisticAPI.getSummaryStatistic();
  },
  getPackageStatistic: function () {
    return StatisticAPI.getPackageStatistic();
  },
};

export default StatisticService;
