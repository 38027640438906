import React from "react";
import { Button, Col, Form, FormGroup, Input, Modal, Row } from "reactstrap";
import { ConfigService } from "service/config";

class AddEditConfigModal extends React.Component {
  state = {
    isOpen: false,
    showError: false,
    validation: false,
    errorMessage: "",
    name: "",
    value: "",
  };

  closeModal = () => {
    this.setState({
      isOpen: false,
    });
  };

  openModal = () => {
    const config = this.props.config;
    if (config) {
      this.setState({
        isOpen: true,
        showError: false,
        validation: false,
        errorMessage: "",
        name: config.name,
        value: config.value,
      });
    } else {
      this.setState({
        isOpen: true,
        showError: false,
        validation: false,
        errorMessage: "",
        name: "",
        value: "",
      });
    }
  };

  handelSubmit = () => {
    this.setErrorMessage("");
    const self = this;
    if (this.validateForm()) {
      const { name, value } = this.state;
      ConfigService.saveConfig(name, value)
        .then((res) => {
          if (self.props.onChange) {
            self.props.onChange();
          }
          self.closeModal();
        })
        .catch((error) => {
          this.setErrorMessage("حدث خطا اثناء حفظ البيانات, يرجى المحاولة مرة اخرى");
        });
    }
  };

  validateForm = () => {
    this.setState({ validation: true });
    const { name } = this.state;
    let isValid = true;

    if (name.trim().length === 0) {
      isValid = false;
      this.setErrorMessage("الرجاء ادخال جميع الحقول المطلوبة.");
    }

    return isValid;
  };

  setErrorMessage = (message) => {
    this.setState({ errorMessage: message });
  };

  render() {
    const { errorMessage, validation, name, value } = this.state;
    return (
      <>
        {this.props.isEdit && <i role="button" className="fas fa-edit" onClick={(e) => this.openModal(e)} />}
        {!this.props.isEdit && (
          <Button color="success" type="button" size="sm" onClick={(e) => this.openModal(e)}>
            جديد <i className="fas fa-plus"></i>
          </Button>
        )}

        <Modal className="modal-dialog-centered" isOpen={this.state.isOpen} toggle={() => this.closeModal()}>
          <div className="modal-header border-bottom">
            <h3 className="modal-title">جديد / تعديل</h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.closeModal()}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Form>
              <Row>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label">الاسم:</label>
                    <Input
                      disabled={this.props.config?.name}
                      className={`form-control ${validation && !name ? "is-invalid" : ""}`}
                      type="text"
                      value={name}
                      onChange={(e) => this.setState({ name: e.target.value.trim() })}
                    />
                  </FormGroup>
                </Col>
                <Col lg="12">
                  <FormGroup>
                    <label className="form-control-label">القيمة:</label>
                    <Input
                      className="form-control"
                      type="text"
                      value={value}
                      onChange={(e) => this.setState({ value: e.target.value.trim() })}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </Form>
          </div>
          <div className="modal-footer border-top">
            <span className="text-danger ml-auto">{errorMessage}</span>
            <Button color="secondary" data-dismiss="modal" type="button" onClick={() => this.closeModal()}>
              اغلاق
            </Button>
            <Button color="primary" type="button" onClick={() => this.handelSubmit()}>
              حفظ
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default AddEditConfigModal;
