import apiClient from './axios.js';
import { LOGIN_API_URL } from '../variables/constant';
import {
  CUSTOMER_SEARCH_API_URL,
  RESET_PASSWORD_API_URL,
} from '../variables/constant';
export const authAPI = {
  login: function (userName, password) {
    return apiClient.post(LOGIN_API_URL, {
      userName: userName,
      password: password,
    });
  },
  findCustomer: function (searchTearm) {
    return apiClient.get(CUSTOMER_SEARCH_API_URL, {
      searchTearm: searchTearm,
    });
  },
  resetPassword: function (data) {
    return apiClient.post(RESET_PASSWORD_API_URL, data);
  },
};
