import {
    MRT_GlobalFilterTextField,
    MRT_ShowHideColumnsButton,
    MRT_ToggleFullScreenButton,
    MRT_ToggleGlobalFilterButton,
} from "material-react-table";

import { Box, Button, lighten } from "@mui/material";

const HistoryPackageTableTopBar = (props) => {
    const { table } = props;


    const handleSelect = () => {
        const ids = table.getSelectedRowModel().flatRows.map((row) => row.getValue("id"));
        alert("Selected Packages: " + ids.join(","));
    };

    const isDisableButton = !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected();

    return (
        <Box
            sx={(theme) => ({
                backgroundColor: lighten(theme.palette.background.default, 0.05),
                display: "flex",
                gap: "0.5rem",
                p: "8px",
                justifyContent: "space-between",
            })}>
            <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
                {/* import MRT sub-components */}
                <MRT_ToggleFullScreenButton table={table} />
                <MRT_ShowHideColumnsButton table={table} />
                <MRT_ToggleGlobalFilterButton table={table} />
                <MRT_GlobalFilterTextField table={table} />
            </Box>
            <Box>
                <Box sx={{ display: "flex", gap: "0.5rem" }}>
                    <Button color="warning" disabled={isDisableButton} onClick={handleSelect} variant="contained">
                        <i className="fas fa-download mx-1" /> تصدير اكسل
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default HistoryPackageTableTopBar;
