import { trackPromise } from "react-promise-tracker";
import { Card, CardBody, CardHeader, Row, Table } from "reactstrap";
import OrganizationService from "service/organization";
import UtilityService from "service/utility";
import UsersActions from "../UsersActions";

const UserList = (props) => {
  const { organization, onRefresh } = props;
  const userList = organization.userList || [];

  const onDisableUser = (userId) => {
    trackPromise(
      OrganizationService.updateUserStatus(userId, false).then((res) => {
        onRefresh();
      })
    );
  };

  const onEnableUser = (userId) => {
    trackPromise(
      OrganizationService.updateUserStatus(userId, true).then((res) => {
        onRefresh();
      })
    );
  };

  return (
    <>
      <Card className="shadow">
        <CardHeader className="bg-transparent">
          <Row className="align-items-center">
            <div className="col">
              <h3 className="mb-0">المستخدمين</h3>
            </div>
          </Row>
        </CardHeader>
        <CardBody className="p-0">
          <Row className="m-0">
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th className="px-4">#</th>
                  <th>تايخ الانشاء</th>
                  <th>اسم المستخدم</th>
                  <th>الفرع</th>
                  <th>الاسم الاول</th>
                  <th>الاسم الاخير</th>
                  <th>رقم الموبايل</th>
                  <th>البريد الالكتروني</th>
                  <th>الحالة</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {userList.map(function (orgUser) {
                  const user = orgUser.user;
                  const isOrgAdmin = orgUser.admin;

                  return (
                    <tr key={user.id}>
                      <td className="px-4">{user.id}</td>
                      <td>{UtilityService.formatTimestamp(user.created)}</td>
                      <td>
                        {user.userName} {isOrgAdmin && <span className="badge badge-success">مسؤول إداري</span>}
                      </td>
                      <td>
                        <span className="badge badge-primary">{orgUser.branch?.name}</span>
                      </td>
                      <td>{user.firstName}</td>
                      <td>{user.lastName}</td>
                      <td>{user.mobile}</td>
                      <td>{user.email}</td>
                      <td>{UtilityService.renderActiveOrInactiveStatus(user.status)}</td>
                      <td>
                        <UsersActions
                          user={user}
                          isOrgAdmin={isOrgAdmin}
                          onDisableUser={() => onDisableUser(user.id)}
                          onEnableUser={() => onEnableUser(user.id)}
                          onEditUser={() => onRefresh()}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default UserList;
