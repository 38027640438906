import { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Row } from "reactstrap";
import UserService from "service/user";

const ChangePassword = (props) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [message, setMessage] = useState("");
  const [messageClass, setMessageClass] = useState("");

  const handleChangePassword = () => {
    setMessage("");
    setMessageClass("");
    if (validateForm()) {
      trackPromise(
        UserService.updateCurrentUserPassword(oldPassword, newPassword)
          .then(() => {
            setMessage("تم تغيير كلمة المرور بنجاح!");
            setMessageClass("success");
            setConfirmNewPassword("");
            setNewPassword("");
            setOldPassword("");
          })
          .catch((error) => {
            if (error.response.status === 403) {
              setMessage("كلمة المرور القديمة غير صحيحة.");
            } else {
              setMessage("فشل في تعديل كلمة المرور. الرجاء المحاولة مرة اخرى لاحقا!");
            }
            setMessageClass("danger");
          })
      );
    }
  };

  const validateForm = () => {
    if (!newPassword || !oldPassword || !confirmNewPassword) {
      setMessage("جميع الحقول مطلوبة");
      setMessageClass("danger");
      return false;
    } else if (newPassword !== confirmNewPassword) {
      setMessage("كلمتا المرور غير متطابقات. الرجاء التأكيد على كلمة المرور مرة أخرى");
      setMessageClass("danger");
      return false;
    }
    return true;
  };

  const showError = message && messageClass === "danger";

  return (
    <Card className="bg-secondary shadow">
      <CardHeader className="bg-white border-0">
        <Row className="align-items-center">
          <Col lg="12">
            <h3 className="mb-0">تعديل كلمة المرور</h3>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Form>
          <Row>
            <Col lg="12">
              <FormGroup>
                <label className="form-control-label">كلمة المرور القديمة:</label>
                <Input
                  className={`form-control ${showError && !oldPassword ? "is-invalid" : ""}`}
                  value={oldPassword}
                  type="password"
                  onChange={(e) => {
                    setOldPassword(e.target.value);
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg="12">
              <FormGroup>
                <label className="form-control-label">كلمة المرور الجديدة:</label>
                <Input
                  className={`form-control ${
                    showError && (confirmNewPassword !== newPassword || !newPassword) ? "is-invalid" : ""
                  }`}
                  value={newPassword}
                  type="password"
                  onChange={(e) => {
                    setNewPassword(e.target.value);
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg="12">
              <FormGroup>
                <label className="form-control-label">تاكيد كلمة المرور الجديدة:</label>
                <Input
                  className={`form-control ${
                    showError && (confirmNewPassword !== newPassword || !confirmNewPassword) ? "is-invalid" : ""
                  }`}
                  value={confirmNewPassword}
                  type="password"
                  onChange={(e) => {
                    setConfirmNewPassword(e.target.value);
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg="12">
              <p className={`text-${messageClass}`}>{message}</p>
              <Button color="success" className="w-100" onClick={handleChangePassword}>
                تعديل
              </Button>
            </Col>
          </Row>
        </Form>
      </CardBody>
    </Card>
  );
};

export default ChangePassword;
