import { useMemo, useState, useRef, useEffect } from "react";

import { MaterialReactTable, useMaterialReactTable } from "material-react-table";

//Date Picker Imports - these should just be in your Context Provider
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { MRT_Localization_AR } from "material-react-table/locales/ar/index.js";

import { ThemeProvider, createTheme, useTheme } from "@mui/material";
import { arSA } from "@mui/material/locale";
import { Link } from "react-router-dom";
import CollectionTableTopBar from "./CollectionTableTopBar";
import UtilityService from "service/utility";
import { ListItemIcon, MenuItem } from "@mui/material";
import { Download, Print, Paid, Delete } from "@mui/icons-material";
import PaymentDialog from "components/Modals/CreatePaymentModal";
import DeleteCollectionInvoiceModel from "components/Modals/DeleteCollectionInvoiceModel";
import CollectionService from "service/collection";
import exportFromJSON from 'export-from-json';
import OrganizationService from 'service/organization';
import { MESSAGES } from "variables/messages";

const Table = (props) => {
  const { data, totalCollections } = props;

  const editingRow = useRef(null);
  const [isChangeModalOpen, setChangeModalOpen] = useState(false);
  const collectionsDetails = useRef(null);
  const organizationInfo = useRef(null);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 25,
  });

  useEffect(() => {
    //when the pagination state changes
    props.onDataChange({ pageNumber: pagination.pageIndex, pageSize: pagination.pageSize });
  }, [pagination.pageIndex, pagination.pageSize]);

  const handleDeleteCollectionInvoice = () => {
    //logic for delete 
    setChangeModalOpen(false);
  };

  const handlePrintCollectionInvoice = (row) => {
    CollectionService.printCollectionInvoice(row.original.id).then((res) => {
      if (res.data.success) {
        const newTab = window.open(process.env.REACT_APP_STATIC_REPORT_URL + res.data.fileName, "_blank");
        if (newTab) {
          newTab.focus();
        }
      }
    });
  };

  const handleDownloadPdfFile = (row) => {
    CollectionService.printCollectionInvoice(row.original.id).then((res) => {
      if (res.data.success) {
        CollectionService.downloadCollectionInvoicePdf(res.data.fileName)
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.download = res.data.fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
          })
          .catch((error) => {
            console.error("Error fetching the file:", error);
          });
      }
    });
  };

  const handelCsvPackageItem = (item) => {
    const PackaeItem = {
      "رقم الطرد": UtilityService.formatPackageId(item.packageId),
      "حالة الطرد": MESSAGES["PACKAGE_STATUS_" + item.packageStatus].text,
      "اسم الزبون": item.csutomerName,
      "رقم المستقبل": item.csutomerMobile,
      "تاريخ": new Date(item.pacakgeDate).toLocaleDateString(),
      "التحصيل": item.totalCollections,
      "التوصيل": item.cost,
      "تحصيل التاجر": item.totalCollections - item.cost,

    }
    return PackaeItem;
  };

  const handelDataForCsvFile = () => {
    // first row in csv file contain organization information and first package information
    const newJson = [];
    const firstItem = {
      "اسم الشركة": organizationInfo.current.name,
      "رقم الموبايل": organizationInfo.current.phone,
      "العنوان": organizationInfo.current.address,
      "تاريخ الإنشاء": new Date(collectionsDetails.current.created).toLocaleDateString(),
      "التحصيل الكلي": UtilityService.formatPrice(collectionsDetails.current.totalCollections),
      "التوصيل الكلي": UtilityService.formatPrice(collectionsDetails.current.totalCost),
      "تحصيل التاجر الكلي": UtilityService.formatPrice(collectionsDetails.current.amount),
      "حالة الفاتورة": MESSAGES["COLLECTION_STATUS_" + collectionsDetails.current.status].text,
      ...handelCsvPackageItem(collectionsDetails.current.items[0])
    };
    newJson.push(firstItem);

    //Add package information except the first item
    for (let i = 1; i < collectionsDetails.current.items.length; i++) {
      newJson.push(handelCsvPackageItem(collectionsDetails.current.items[i]));
    }
    //return josn file
    return newJson;
  };


  const handleDownloadExcelFile = (row) => {
    //get collection information
    CollectionService.getCollectionDetails(row.original.id)
      .then(collectionResponse => {
        collectionsDetails.current = collectionResponse.data;
        // Fetch organization information
        return OrganizationService.getOrganizationDetails(row.original.organizationId);
      })
      .then(organizationResponse => {
        organizationInfo.current = organizationResponse.data;

        //handel data
        const data = handelDataForCsvFile();

        // Convert JSON to CSV and download it
        const fileName = 'collectionInvoice#' + row.original.id;
        const exportType = exportFromJSON.types.csv;
        exportFromJSON({ data, fileName, exportType });
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        id: "collectionId",
        header: "#",
        size: 250,
        Cell: ({ cell }) => (
          <Link className="text-dark text-underline" to={"/collections/" + cell.getValue()}>
            {cell.getValue()}
          </Link>
        ),
      },
      {
        accessorFn: (originalRow) => (originalRow.created ? new Date(originalRow.created) : ""),
        header: "تاريخ الإنشاء",
        size: 250,
        Cell: ({ cell }) => (cell.getValue() ? cell.getValue().toLocaleDateString() : ""),
      },
      {
        accessorKey: "totalCollections",
        header: "مجموع التحصيل",
        size: 250,
        Cell: ({ cell }) =>
          cell.getValue()?.toLocaleString("en-US", {
            style: "currency",
            currency: "ILS",
          }),
      },
      {
        accessorKey: "totalCost",
        header: "تكلفة التوصيل",
        size: 250,
        Cell: ({ cell }) =>
          cell.getValue()?.toLocaleString("en-US", {
            style: "currency",
            currency: "ILS",
          }),
      },
      {
        accessorKey: "amount",
        header: "تحصيل التاجر",
        size: 250,
        Cell: ({ cell }) =>
          cell.getValue()?.toLocaleString("en-US", {
            style: "currency",
            currency: "ILS",
          }),
      },
      {
        accessorKey: "status",
        header: "الحالة",
        size: 200,
        Cell: ({ cell }) => UtilityService.renderStatusMessage("COLLECTION", cell.getValue()),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    pageIndex: pagination.pageNumber,
    enableColumnResizing: true,
    columnResizeDirection: "rtl",
    enableRowSelection: true,
    enableRowActions: true,
    initialState: {
      showColumnFilters: false,
      columnPinning: { right: ["mrt-row-actions"] },
      pagination: { pageSize: 25, pageIndex: 0 },
    },
    localization: { ...MRT_Localization_AR },
    enableFullScreenToggle: true,
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    enableSorting: false,
    enablePagination: true,
    manualPagination: true,
    positionToolbarAlertBanner: "head-overlay",
    enableColumnFilters: false,
    rowCount: totalCollections,
    onPaginationChange: setPagination,
    state: { pagination }, //pass the pagination state to the table
    muiPaginationProps: {
      rowsPerPageOptions: [25, 50, 100],// Set the possible page sizes
    },
    muiTableBodyCellProps: {
      align: 'right', // Set alignment for all table body cells
    },

    renderRowActionMenuItems: ({ closeMenu, row }) => {
      const showPaidAction = row.original.status === "NEW"; 
      return [
        showPaidAction && ( <MenuItem
        key={0}
        onClick={() => {
          setChangeModalOpen(true);
          editingRow.current = { row, key: 0 };
          closeMenu();
        }}
        sx={{ m: 0 }}>
        <ListItemIcon>
          <Delete />
        </ListItemIcon>
        إلغاء
      </MenuItem>
      ),
      showPaidAction && (
      <MenuItem
        key={1}
        onClick={() => {
          setChangeModalOpen(true);
          editingRow.current = { row, key: 1 };
          closeMenu();
        }}
        sx={{ m: 0 }}>
        <ListItemIcon>
          <Paid />
        </ListItemIcon>
        دفع
      </MenuItem>
      ),
      <MenuItem
        key={2}
        onClick={() => {
          handlePrintCollectionInvoice(row);
          closeMenu();
        }}
        sx={{ m: 0 }}>
        <ListItemIcon>
          <Print />
        </ListItemIcon>
        طباعة
      </MenuItem>,
      <MenuItem
        key={3}
        onClick={() => {
          handleDownloadPdfFile(row);
            closeMenu();
        }}
        sx={{ m: 0 }}>
        <ListItemIcon>
          <Download />
        </ListItemIcon>
        تنزيل pdf
      </MenuItem>,
      <MenuItem
        key={4}
        onClick={() => {
          handleDownloadExcelFile(row);
          closeMenu();
        }}
        sx={{ m: 0 }}>
        <ListItemIcon>
          <Download />
        </ListItemIcon>
        تنزيل اكسل
      </MenuItem>,

    ];
    },
    renderTopToolbar: CollectionTableTopBar,
  });

  return (
    <>
      <MaterialReactTable table={table} />
      {editingRow.current && (<PaymentDialog
        show={isChangeModalOpen && editingRow.current.key === 1}
        onHide={() => setChangeModalOpen(false)}
        collectionsId={editingRow.current.row.original.id}
      />
      )};
      {editingRow.current && (
        <DeleteCollectionInvoiceModel
          isOpen={isChangeModalOpen && editingRow.current.key === 0}
          onClose={() => setChangeModalOpen(false)}
          onConfirmDeletion={handleDeleteCollectionInvoice}
          collectionInvoiceId={editingRow.current.row.original.id}
        />
      )};
    </>
  );
};

const CollectionTable = (props) => {
  const theme = useTheme(); //replace with your theme/createTheme

  // define direction for table to solve pagination Icons is in wrong direction
  const direction = { direction: "rtl" };

  return (
    //Setting Material UI locale as best practice to result in better accessibility
    <ThemeProvider theme={createTheme(theme, arSA, direction)}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Table data={props.data} totalCollections={props.totalCollections} onDataChange={props.onDataChange} />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default CollectionTable;
