import { PackageAPI } from "api/package";

const PackageService = {
  createNewPackage: function (
    branchId,
    customerId,
    customerNote,
    packageDetails,
    packagePrice,
    packageWeight,
    packageQuantity,
    freeShipping,
    breakable,
    collected,
    allowOpen,
    needsCooling
  ) {
    return PackageAPI.createNewPackage({
      branchId: branchId,
      customerId: customerId,
      customerNote: customerNote,
      packageDetails: packageDetails,
      packagePrice: packagePrice,
      packageWeight: packageWeight,
      packageQuantity: packageQuantity,
      freeShipping: freeShipping,
      breakable: breakable,
      collected: collected,
      allowOpen: allowOpen,
      needsCooling: needsCooling,
    });
  },

  updatePackage: function (packageId, data) {
    return PackageAPI.updatePackage(packageId, data);
  },

  printBill: function (packageIds) {
    return PackageAPI.printBill(packageIds);
  },

  getActivePackagesList: function () {
    return PackageAPI.getActivePackagesList();
  },
  getPackagesList: function () {
    return PackageAPI.getPackagesList();
  },
  getPackageHistoryList: function (filters) {
    const params = {};
    Object.entries(filters).forEach(([key, value]) => {
      if (value !== null && value !== "") {
        params[key] = value;
      }
    });
    return PackageAPI.getPackageHistoryList(params);
  },
  updatePackageStatus: function (packageId, status) {
    return PackageAPI.updatePackage(packageId, { status: status });
  },
  getPackageDetails: function (packageId) {
    return PackageAPI.getPackageDetails(packageId);
  },
  assigneDriverToPackage: function (packageId, driverId) {
    return PackageAPI.assigneDriverToPackage(packageId, { driverId });
  },
  linkPackageToExternalSystem: function (packageId, data) {
    return PackageAPI.linkPackageToExternalSystem(packageId, data);
  },
  getPackageSummary: function () {
    return PackageAPI.getPackageSummary();
  },
  updatePackageCost:  function (packageId, cost) {
    return PackageAPI.updatePackage(packageId, {cost : cost});
  },
  updatePackageTotalCollection:  function (packageId, totalCollection) {
    return PackageAPI.updatePackage(packageId, {totalCollection: totalCollection});
  },
  updatePackageDeliveryDate: function (packageId, data) {
    return PackageAPI.updatePackage(packageId, data);
  },
};

export default PackageService;
