import APIClient from "./axios.js";
import { GET_MESSAGE_SUMMARY_API_URL, GET_MESSAGE_LIST_API_URL, SEND_MESSAGE_API_URL } from "../variables/constant";

export const MessageAPI = {
  gerMessageSummary: function (data) {
    return APIClient.get(GET_MESSAGE_SUMMARY_API_URL, { params: data });
  },
  gerMessageList: function (data) {
    return APIClient.get(GET_MESSAGE_LIST_API_URL, { params: data });
  },
  sendMessage: function (data) {
    return APIClient.post(SEND_MESSAGE_API_URL, data);
  },
};
