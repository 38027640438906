import { useMemo, useState, useEffect } from "react";

import { MaterialReactTable, useMaterialReactTable } from "material-react-table";

//Date Picker Imports - these should just be in your Context Provider
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { MRT_Localization_AR } from "material-react-table/locales/ar/index.js";

import { ThemeProvider, createTheme, useTheme } from "@mui/material";
import { arSA } from "@mui/material/locale";
import UtilityService from "service/utility";
import { PACKAGE_STATUS_LIST } from "variables/constant";
import HistoryPackageTableTopBar from "./HistoryPackageTableTopBar";

const Table = (props) => {
    const { data, totalPackage } = props;
    const [isSetFilter, setFilter] = useState(false);
    const status = new URLSearchParams(window.location.search).get("status");
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: 10,
    });

    const [columnFilters, setColumnFilters] = useState([]);
    const [globalFilter, setGlobalFilter] = useState("");

    useEffect(() => {
        const timer = setTimeout(() => {
            props.onDataChange({ pagination: pagination, columnFilters: columnFilters, globalFilter: globalFilter });
        }, 1000);
        return () => clearTimeout(timer);
    }, [pagination.pageSize, pagination.pageIndex, columnFilters, globalFilter]);

    const columns = useMemo(
        () => [
            {
                accessorKey: "id",
                enablePinning: true,
                header: "الرقم التسلسلي",
                size: 170,
                Cell: ({ cell }) => UtilityService.formatPackageId(cell.getValue()),
                enableColumnFilter: false,
            },
            {
                header: "الحالة",
                accessorKey: "status",
                filterVariant: "multi-select",
                Cell: ({ cell }) => UtilityService.renderStatusMessage("PACKAGE", cell.getValue()),
                size: 170,
                filterSelectOptions: PACKAGE_STATUS_LIST.map((s) => {
                    return { label: UtilityService.renderStatusMessage("PACKAGE", s, false, true), value: s };
                }),
            },
            {
                accessorFn: (originalRow) => (
                    <>
                        {originalRow.organization?.name}
                        <br />
                        {originalRow.organization?.branch?.name}
                    </>
                ),
                id: "organization",
                header: "المرسل",
                filterVariant: "text",
                muiTableHeadCellProps: {
                    align: "left",
                },
                size: 250,
            },
            {
                accessorFn: (originalRow) =>
                    `${originalRow.address.firstName} ${originalRow.address.lastName ? originalRow.address.lastName : ""}`,
                id: "customerName",
                header: "المستقبل",
                filterVariant: "text",
                enableColumnFilter: false,
            },
            {
                accessorFn:(originalRow) => {
                    const { mobile, mobile2 } = originalRow.address;
                    return (
                      <>
                        {mobile}
                        {mobile2 && <><br />{mobile2}</>}
                      </>
                    );
                  },
                id: "mobile",
                header: "رقم المستقبل",
                filterVariant: "text",
            },
            {
                accessorFn: (originalRow) => UtilityService.formatAddress(originalRow.address, true),
                id: "customerAddress",
                header: "العنوان",
                filterVariant: "text",
                muiTableHeadCellProps: {
                    align: "left",
                },
                enableColumnFilter: false,
            },
            {
                accessorFn: (originalRow) => new Date(originalRow.created), //convert to date for sorting and filtering
                header: "تاريخ الادخال",
                filterVariant: "date-range",
                Cell: ({ cell }) => cell.getValue().toLocaleDateString(), // convert back to string for display
                size: 170,
                enableColumnFilter: false,
            },
            {
                accessorFn: (originalRow) => originalRow.totalCollection - originalRow.cost,
                header: "تحصيل التاجر",
                size: 120,
                Cell: ({ cell }) =>
                    cell.getValue()?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "ILS",
                    }),
                size: 170,
                enableColumnFilter: false,
            },
            {
                accessorKey: "cost",
                header: "التكلفة",
                size: 120,
                Cell: ({ cell }) =>
                    cell.getValue()?.toLocaleString("en-US", {
                        style: "currency",
                        currency: "ILS",
                    }),
                size: 170,
                enableColumnFilter: false,
            },
            {
                accessorFn: (originalRow) => originalRow.description,
                header: "التفاصيل",
                size: 170,
                enableColumnFilter: false,
            },
        ],
        []
    );

    const table = useMaterialReactTable({
        columns,
        data,
        enableColumnResizing: true,
        columnResizeDirection: "rtl",
        enableRowSelection: true,
        enableRowActions: false,
        initialState: {
            showColumnFilters: false,
            columnPinning: { right: ["mrt-row-actions"] },
        },
        localization: { ...MRT_Localization_AR },
        enableFullScreenToggle: true,
        muiSearchTextFieldProps: {
            size: "small",
            variant: "outlined",
        },
        columnFilterDisplayMode: "popover",
        enablePagination: true,
        positionToolbarAlertBanner: "head-overlay",
        rowCount: totalPackage,
        manualPagination: true,
        onPaginationChange: setPagination,
        manualFiltering: true,
        onColumnFiltersChange: (newColumnFilters) => {
            // To prevent setColorFilter called twice when the filter input is cleared

            const actualNewColumnFilters = typeof newColumnFilters === "function" ? newColumnFilters(columnFilters) : newColumnFilters;
            if (JSON.stringify(columnFilters) !== JSON.stringify(actualNewColumnFilters)) {
                setColumnFilters(newColumnFilters);
            }
        },
        onGlobalFilterChange: setGlobalFilter,
        state: { pagination, columnFilters, globalFilter }, //pass the pagination state to the table
        muiTableBodyCellProps: { align: "right" },
        renderTopToolbar: HistoryPackageTableTopBar,
    });


    if (!isSetFilter && status) {
        table.getAllColumns().find((column) => column.id === "status").setFilterValue([status]);
        setFilter(true);
    }

    return <MaterialReactTable table={table} />;
};

const HistoryPackageTable = (props) => {
    const theme = useTheme(); //replace with your theme/createTheme

    // define direction for table to solve pagination Icons is in wrong direction
    const direction = { direction: "rtl" };

    return (
        //Setting Material UI locale as best practice to result in better accessibility
        <ThemeProvider theme={createTheme(theme, arSA, direction)}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Table data={props.data} totalPackage={props.totalPackage} onDataChange={props.onDataChange} />
            </LocalizationProvider>
        </ThemeProvider>
    );
};

export default HistoryPackageTable;
