import { Card, CardBody, CardHeader, Container, Row, Table, Button } from "reactstrap";

import { useEffect, useState } from "react";
import { ConfigService } from "service/config";
import AddEditConfigModal from "./AddEditConfigModal";

const Config = (props) => {
  const [configList, setConfigList] = useState([]);

  useEffect(() => {
    populateConfigList();
  }, [props]);

  const populateConfigList = () => {
    ConfigService.getAllConfig().then((res) => {
      setConfigList(res.data);
    });
  };

  const handleDeleteConfig = (config) => {
    ConfigService.deleteConfig(config.name).then(() => {
      populateConfigList();
    });
  };

  const handleRefreshConfig = (config) => {
    ConfigService.refreshConfig(config.name).then(() => {
      populateConfigList();
    });
  };

  const handleRefreshAllConfig = () => {
    ConfigService.refreshAllConfig().then(() => {
      populateConfigList();
    });
  };

  return (
    <>
      <Container className="pt-2 pt-md-8" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">الفواتير</h3>
                  </div>
                  <div className="col text-left d-flex align-items-center justify-content-end">
                    <i className="fas fa-redo mx-2" role="button" onClick={populateConfigList}></i>
                    <Button
                      color="info"
                      type="button"
                      size="sm"
                      className="mx-2"
                      onClick={() => handleRefreshAllConfig()}>
                      تحديث الكل <i className="fas fa-retweet"></i>
                    </Button>
                    <AddEditConfigModal isEdit={false} onChange={populateConfigList} />
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="p-0">
                <Row className="m-0">
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th className="px-4">#</th>
                        <th>الاسم</th>
                        <th>القيمة</th>
                        <th>الحالة</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {configList &&
                        configList.map(function (config, index) {
                          return (
                            <tr key={config.id}>
                              <th className="px-4">{config.id}</th>
                              <th>{config.name}</th>
                              <td>{config.value}</td>
                              <td>
                                {config.live ? (
                                  <span className="badge badge-success">نشر</span>
                                ) : (
                                  <span className="badge badge-danger">مسودة</span>
                                )}
                              </td>
                              <td>
                                <AddEditConfigModal isEdit={true} config={config} onChange={populateConfigList} />
                                <i
                                  className="fas fa-trash text-danger mx-4"
                                  role="button"
                                  onClick={() => handleDeleteConfig(config)}></i>
                                {!config.live && (
                                  <Button
                                    color="info"
                                    type="button"
                                    size="sm"
                                    className="mx-2"
                                    onClick={() => handleRefreshConfig(config)}>
                                    تحديث <i className="fas fa-retweet"></i>
                                  </Button>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Config;
