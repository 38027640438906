import { Steps } from "antd";
import { Col, Row } from "reactstrap";
import UtilityService from "service/utility";

const TimeLine = (props) => {
  const { data, lastStatus } = props;
  const packageStatus = data.sort((a, b) => a.id - b.id);

  const currentStep = () => {
    if (lastStatus === "N") return 0;
    if (lastStatus === "M") return 1;
    if (lastStatus === "P" || lastStatus === "A" || lastStatus === "E" || lastStatus === "G") return 2;
    if (lastStatus === "O" || lastStatus === "V" || lastStatus === "R" || lastStatus === "C") return 3;
    if (lastStatus === "D" || lastStatus === "L" || lastStatus === "X" || lastStatus === "J") return 4;
  };

  const getMessage = (status) => {
    if (status.newStatus === "N") return "جديد";
    if (status.newStatus === "M") return "جاهز لشحن";
    if (status.newStatus === "J") return "الغاء";
    if (status.newStatus === "P") return "تم استلام الطرد";
    if (status.newStatus === "A") return "قيد الفرز";
    if (status.newStatus === "O") return "قيد التوصيل";
    if (status.newStatus === "D") return "تم التوصيل";
    return "";
  };

  const items = packageStatus?.map((p, i) => {
    // some status should not be display in middel of timeline
    if (p.newStatus === "J" && i !== packageStatus.length - 1) {
      return;
    }

    if (p.newStatus === "N" && i !== 0) {
      return;
    }

    return {
      title: getMessage(p),
      status: i === packageStatus.length - 1 ? "process" : "finish",
      subTitle: (
        <p>
          {UtilityService.formatDate(p.created)} <br />
          {UtilityService.formatTime(p.created)}
        </p>
      ),
    };
  });

  if ("N" === lastStatus) {
    items?.push({ title: "قيد الشحن", subTitle: "غير معروف", status: "wait" });
  }

  if (["O", "V", "R", "C", "D", "L", "X", "J"].indexOf(lastStatus) === -1) {
    items?.push({ title: "قيد التوصيل", subTitle: "غير معروف", status: "wait" });
  }

  if (["D", "L", "X", "J"].indexOf(lastStatus) === -1) {
    items?.push({ title: "تم التسليم", subTitle: "غير معروف", status: "wait" });
  }

  return (
    <Row className="m-0 ">
      <Col lg="12">
        <Steps labelPlacement="vertical" items={items} />
      </Col>
    </Row>
  );
};

export default TimeLine;
