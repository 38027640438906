import { useEffect, useState } from "react";
import { Container } from "reactstrap";
import CollectionTable from "./CollectionTable";
import CollectionService from "service/collection";
import { trackPromise } from "react-promise-tracker";

const CollectionList = (props) => {
  const [collectionList, setCollectionList] = useState([]);
  const [totalCollections, setTotalCollections] = useState([]);

  const populateCollection = (props) => {
    trackPromise(
      CollectionService.getCollectionList({ searchTerm: "", pageSize: props.pageSize, pageNumber: props.pageNumber, })
        .then((res) => {
          setTotalCollections(res.data.totalRecords);
          setCollectionList(res.data.collections);
        })
    );
  };

  return (
    <>
      <Container className="pt-2 pt-md-8" fluid>
        {collectionList && (
          <CollectionTable
            data={collectionList}
            onDataChange={populateCollection}
            totalCollections={totalCollections}
          />
        )}
        <p></p>
      </Container>
    </>
  );
};

export default CollectionList;
