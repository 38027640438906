import { useState } from "react";
import { FormGroup } from "reactstrap";
import { SUPPORTED_CITY } from "variables/cities";

const CitySelect = (props) => {
  const [selectedCity, setSelectedCity] = useState(props.defaultValue || "");

  const handleChange = (e) => {
    setSelectedCity(e.target.value);
    if (props.onChange) props.onChange(e);
  };

  return (
    <FormGroup className={props.className}>
      <label className="form-control-label">المدينة</label>
      <select
        type="text"
        value={selectedCity}
        onChange={handleChange}
        className={`form-control ${props.showError && !selectedCity ? "is-invalid" : ""}`}>
        <option value="">{props.emptyTitle || ""}</option>
        {SUPPORTED_CITY.map((city) => {
          return (
            <option key={city.key} value={city.key.toUpperCase()}>
              {city.title}
            </option>
          );
        })}
      </select>
    </FormGroup>
  );
};

export default CitySelect;
