import React from "react";
import { Button, Modal } from "reactstrap";
import UtilityService from "service/utility";

class DeletePackageFromInvoiceModel extends React.Component {
  render() {
    return (
      <Modal className="modal-dialog-centered" isOpen={this.props.isOpen} toggle={this.props.onClose}>
        <div className="modal-header  pb-2">
          <h3 className="modal-title">هل أنت متأكد من حذف طرد رقم
            {UtilityService.formatPackageId(this.props.packageId)} من كشف التحصيل ؟</h3>
        </div>

        <div className="modal-footer p-2 mt-3">
          <Button color="secondary" data-dismiss="modal" type="button" onClick={() => this.props.onClose()}>
            اغلاق
          </Button>
          <Button color="primary" type="button" onClick={() => this.props.onConfirmDeletion()}>
            حذف
          </Button>
        </div>
      </Modal>
    );
  }
}

export default DeletePackageFromInvoiceModel;
