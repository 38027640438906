import { Card, CardHeader, CardBody, Container, Row, Table } from "reactstrap";

import DriverService from "service/driver";
import { useEffect, useState } from "react";
import UtilityService from "service/utility";
import EditDriverModal from "components/Modals/drivers/EditDriverModal";
import CarActions from "./CarActions";
import { trackPromise } from "react-promise-tracker";

const DriversManagment = (props) => {
  const [driversList, setDriversList] = useState([]);

  useEffect(() => {
    populateDriversList();
  }, [props]);

  const populateDriversList = () => {
    trackPromise(
      DriverService.getAllDriversList().then((res) => {
        setDriversList(res.data);
      })
    );
  };

  const onDisableDriver = (userId) => {
    trackPromise(
      DriverService.updateDriverStatus(userId, false).then((res) => {
        populateDriversList();
      })
    );
  };

  const onEnableDriver = (userId) => {
    trackPromise(
      DriverService.updateDriverStatus(userId, true).then((res) => {
        populateDriversList();
      })
    );
  };

  return (
    <>
      <Container className="pt-2 pt-md-8" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">ادارة السائقين</h3>
                  </div>
                  <div className="col text-left">
                    <i className="fas fa-redo mx-4" role="button" onClick={populateDriversList}></i>
                    <EditDriverModal onChange={populateDriversList} />
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="p-0">
                <Row className="m-0">
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th className="px-4">#</th>
                        <th>تايخ الانشاء</th>
                        <th>اسم المستخدم</th>
                        <th>الاسم</th>
                        <th>السيارة</th>
                        <th>رقم الموبايل</th>
                        <th>البريد الالكتروني</th>
                        <th>الحالة</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {driversList.map(function (driver) {
                        return (
                          <tr key={driver.id}>
                            <td className="px-4">{driver.userId}</td>
                            <td>{UtilityService.formatTimestamp(driver.created)}</td>
                            <td>{driver.userName}</td>
                            <td>
                              {driver.firstName} {driver.lastName}
                            </td>
                            <td></td>
                            <td>{driver.mobile}</td>
                            <td>{driver.email}</td>
                            <td>{UtilityService.renderActiveOrInactiveStatus(driver.status)}</td>
                            <td>
                              <CarActions
                                user={driver}
                                onDisableDriver={() => onDisableDriver(driver.userId)}
                                onEnableDriver={() => onEnableDriver(driver.userId)}
                                onEditDriver={() => populateDriversList()}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default DriversManagment;
