import LogsAPI from "api/logs";

export const LogsService = {
  getLogsFileList: function () {
    return LogsAPI.getLogsFileList();
  },
  getLogsFileContent: function (fileName) {
    return LogsAPI.getLogsFileContent(fileName);
  },
  downloadLogsFile: function (fileName) {
    return LogsAPI.downloadLogsFile(fileName);
  },
};
