import QRCode from "qrcode.react";
import { Col, Row } from "reactstrap";
import UtilityService from "service/utility";

const PackageInfo = (props) => {
  const packageDetails = props.package;

  return (
    <Row className="m-0">
      <Col lg="12">
        <h3>تفاصيل الطرد</h3>
      </Col>
      <Row className="col-12 mt-2 d-flex">
        <Col lg="3">
          <div className="mb-4 d-flex">
            <i className="fas fa-cube" style={{ fontSize: 32 }}></i>
            <span className="mx-2">{packageDetails.description}</span>
          </div>
          <div>
            <span className="font-weight-bold">تاريخ االدخال:</span>
            <span className="mx-2">{UtilityService.formatTimestamp(packageDetails.created)}</span>
          </div>
        </Col>
        <Col lg="3">
          <div className="mb-2">
            <span className="font-weight-bold">تحصيل التاجر:</span>
            <span className="mx-2">{UtilityService.formatPrice(packageDetails.price)}</span>
          </div>
          <div className="mb-2">
            <span className="font-weight-bold">تكلفة التوصيل:</span>
            <span className="mx-2">{UtilityService.formatPrice(packageDetails.cost)}</span>
          </div>
          <div className="mb-2">
            <span className="font-weight-bold">الوزن الاجمالي لطرد:</span>
            <span className="mx-2">{packageDetails.weight}</span>
          </div>
        </Col>
        <Col lg="3" className="d-flex justify-content-center">
          <div className="bg-light px-4 py-2 rounded d-flex align-items-center flex-column justify-content-center">
            <h2 className="font-weight-bold">{UtilityService.formatPrice(packageDetails.totalCollection)}</h2>
            <h2 className="m-0">قيمة التحصيل</h2>
          </div>
        </Col>
        <Col lg="3" className="d-flex justify-content-end">
          <QRCode size={100} value={UtilityService.formatPackageId(packageDetails.id)} />
        </Col>
      </Row>
    </Row>
  );
};

export default PackageInfo;
