import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Row } from "reactstrap";

import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import UserService from "service/user";
import UtilityService from "service/utility";

const BasicInfo = (props) => {
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");

  const [message, setMessage] = useState("");
  const [messageClass, setMessageClass] = useState("");

  useEffect(() => {
    const userDetails = props.userDetails;
    setFirstName(userDetails.firstName);
    setMiddleName(userDetails.middleName);
    setLastName(userDetails.lastName);
    setMobile(userDetails.mobile);
    setEmail(userDetails.email);
  }, [props]);

  const handleSaveProfile = () => {
    setMessage("");
    setMessageClass("");
    if (validateForm()) {
      trackPromise(
        UserService.updateCurrentUserProfile(firstName, middleName, lastName, email, mobile)
          .then(() => {
            setMessage("تم تعديل الملف الشخصي بنجاح!");
            setMessageClass("success");
            if (props.onSubmit) {
              props.onSubmit();
            }
          })
          .catch(() => {
            setMessage("فشل في تعديل الملف الشخصي. الرجاء المحاولة مرة اخرى لاحقا!");
            setMessageClass("danger");
          })
      );
    }
  };

  const validateForm = () => {
    if (!firstName || !lastName) {
      setMessage("الاسم الأول والاسم الأخير مطلوبان");
      setMessageClass("danger");
      return false;
    }
    return true;
  };
  const showError = message && messageClass === "danger";

  return (
    <Card className="bg-secondary shadow">
      <CardHeader className="bg-white border-0">
        <Row className="align-items-center">
          <Col xs="6">
            <h3 className="mb-0">الملف الشخصي</h3>
          </Col>
          <Col className="text-left" xs="6">
            <Button color="success" onClick={handleSaveProfile}>
              حفظ
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Form>
          <h6 className="heading-small text-muted mb-4">معلومات المستخدم</h6>
          <div className="pl-lg-4">
            <Row>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label">اسم المستخدم</label>
                  <Input className="form-control" defaultValue={props.userDetails?.userName} disabled type="text" />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label">تاريخ التسجيل:</label>
                  <Input
                    className="form-control"
                    placeholder="تاريخ التسجيل"
                    disabled
                    type="text"
                    value={UtilityService.formatTimestamp(props.userDetails?.created)}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="4">
                <FormGroup>
                  <label className="form-control-label">الاسم الاول:</label>
                  <Input
                    className={`form-control ${showError && !firstName ? "is-invalid" : ""}`}
                    value={firstName}
                    placeholder="الاسم الاول"
                    type="text"
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label className="form-control-label">اسم الاب:</label>
                  <Input
                    className="form-control"
                    value={middleName}
                    placeholder="اسم الاب"
                    type="text"
                    onChange={(e) => setMiddleName(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label className="form-control-label">الاسم الاخير:</label>
                  <Input
                    className={`form-control ${showError && !lastName ? "is-invalid" : ""}`}
                    value={lastName}
                    placeholder="الاسم الاخير"
                    type="text"
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <label className="form-control-label">رقم الموبايل:</label>
                  <Input
                    className="form-control"
                    value={mobile}
                    placeholder="رقم الموبايل"
                    type="text"
                    onChange={(e) => setMobile(e.target.value)}
                  />
                </FormGroup>
              </Col>
              <Col lg="6">
                <FormGroup>
                  <label className="form-control-label">البريد الالكتروني:</label>
                  <Input
                    className="form-control"
                    type="email"
                    placeholder="البريد الالكتروني"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
        </Form>
        <span className={`text-${messageClass} mx-2`}>{message}</span>
      </CardBody>
    </Card>
  );
};

export default BasicInfo;
