import APIClient from './axios.js';
import { GET_ALL_DRIVERS_API_URL, UPDATE_DRIVER_API_URL, CREATE_NEW_DRIVER_API_URL } from '../variables/constant';

const DriverAPI = {
  getAllDriversList: function () {
    return APIClient.get(GET_ALL_DRIVERS_API_URL);
  },
  createNewDriver: function (data) {
    return APIClient.post(CREATE_NEW_DRIVER_API_URL, data);
  },
  updateDriver: function (userId, data) {
    return APIClient.post(UPDATE_DRIVER_API_URL.replace('{userId}', userId), data);
  },
};

export default DriverAPI;
