import ResetPasswordModal from 'components/Modals/ResetPasswordModal';
import { useState } from 'react';
import { Button, Card, CardBody, Col, Form, FormGroup, Input } from 'reactstrap';
import { authService } from 'service/auth';

const Login = () => {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [validation, setValidation] = useState(false);
  const [error, setError] = useState('');

  const handleSubmit = () => {
    setValidation(true);
    setError('');
    if (userName.trim().length > 0 || password.trim().length > 0) {
      authService
        .login(userName, password)
        .then((response) => {
          localStorage.setItem('jwtToken', response.data.token);
          localStorage.setItem('user', JSON.stringify(response.data.user));

          setTimeout(function () {
            window.location.href = '/';
          }, 1500);
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data && error.response.data.code === '1002') {
              setError('عذرا, غير مسموح لك بتسجيل الدخول في مؤسستك.');
            } else if (error.response.data && error.response.data.code === '1003') {
              setError('عذرا, غير مسموح لك بتسجيل الدخول. الحساب معطل.');
            } else {
              setError('اسم المستخدم وكلمة المرور غير صحيحة ');
            }
          } else {
            setError(' ناسف! الخدمة غير متوفرة حاليا, يرجى المحاولة مرة اخرى');
          }
        });
    }
  };

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  return (
    <>
      <Col lg="5" md="7">
        <Card className="bg-secondary shadow border-0">
          <CardBody className="px-lg-5 py-lg-5">
            <div className="text-center text-muted mb-4">
              <small>قم بتسجيل الدخول للوصول الى حسابك</small>
            </div>
            <Form role="form" onSubmit={handleSubmit}>
              <FormGroup className="mb-3">
                <Input
                  className={`form-control ${(validation && userName === '') || error !== '' ? 'is-invalid' : ''}`}
                  placeholder="اسم المستخدم"
                  type="text"
                  autoComplete="username"
                  onKeyPress={handleKeypress}
                  onChange={(e) => {
                    setUserName(e.target.value);
                  }}
                />
              </FormGroup>
              {/* {UserFocus && userName===''&&<p className="text-muted">{"هذا الحقل مطلوب*"}</p>} */}
              <FormGroup>
                <Input
                  className={(validation && password === '') || error !== '' ? 'is-invalid form-control' : 'form-control'}
                  placeholder="كلمة المرور"
                  type="password"
                  onKeyPress={handleKeypress}
                  autoComplete="password"
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </FormGroup>
              <span className="text-danger">{error}</span>
              <div className="text-center">
                <Button className="my-4 w-100 text-white" color="primary" type="button" onClick={handleSubmit}>
                  تسجيل الدخول
                </Button>
              </div>
              <ResetPasswordModal />
            </Form>
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default Login;
