import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
// reactstrap components
import { Col, Container, Row } from "reactstrap";

// core components
import AuthFooter from "components/Footers/AuthFooter.js";

import { authRoutes } from "routes.js";

const Auth = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.body.classList.add("bg-default");
    return () => {
      document.body.classList.remove("bg-default");
    };
  }, []);

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      const Component = prop.component;
      return <Route path={prop.path} element={<Component />} key={key} />;
    });
  };

  return (
    <>
      <div className="main-content" ref={mainContent}>
        <div className="header bg-primary pb-7 pt-5 py-lg-8">
          <Container>
            <div className="header-body text-center mb-5">
              <Row className="justify-content-center">
                <Col lg="5" md="6">
                  <img className="brand-loog w-75" alt="..." src={require("../assets/img/brand/full_logo_white.png")} />
                </Col>
              </Row>
            </div>
          </Container>
          <div className="separator separator-bottom separator-skew zindex-100">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0">
              <polygon className="fill-default" points="2560 0 2560 100 0 100" />
            </svg>
          </div>
        </div>
        {/* Page content */}
        <Container className="mt--8 pb-4 pb-lg-5">
          <Row className="justify-content-center">
            <Routes>{getRoutes(authRoutes)}</Routes>
          </Row>
        </Container>
      </div>
      <AuthFooter />
    </>
  );
};

export default Auth;
