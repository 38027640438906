import { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Container, Row, Table } from "reactstrap";
import AddressService from "service/address";
import AreaFilters from "./AreaFilter";
import UtilityService from "service/utility";

const AreaManagment = (props) => {
  const [areaList, setAreaList] = useState([]);
  const [filters, setFilters] = useState({ search: "", city: "", status: "all" });

  useEffect(() => {
    populateAreaList();
  }, []);

  const populateAreaList = () => {
    AddressService.getAllArea().then((res) => setAreaList(res.data));
  };

  const filterAreaList = (area) => {
    let valid = true;
    if (
      filters.search &&
      area.arabicName.toLocaleLowerCase().indexOf(filters.search.toLocaleLowerCase()) === -1 &&
      area.englishName.toLocaleLowerCase().indexOf(filters.search.toLocaleLowerCase()) === -1 &&
      area.code.toLocaleLowerCase().indexOf(filters.search.toLocaleLowerCase()) === -1
    ) {
      valid = false;
    }

    if (filters.city && area.city.toLocaleLowerCase().indexOf(filters.city.toLocaleLowerCase()) === -1) {
      valid = false;
    }

    if (
      filters.status &&
      area.status?.toString().toLocaleLowerCase().indexOf(filters.status.toLocaleLowerCase()) === -1
    ) {
      valid = false;
    }

    return valid;
  };

  return (
    <>
      <Container className="pt-2 pt-md-8" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">المناطق</h3>
                  </div>
                  <div className="col text-left">
                    <i className="fas fa-redo" role="button" onClick={populateAreaList}></i>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="p-0">
                <AreaFilters
                  onChange={(search, city, status) => {
                    setFilters({ search, city, status });
                  }}
                />
                <Row className="m-0">
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th className="px-4">الرمز</th>
                        <th>الاسم / انجليزي</th>
                        <th>الاسم / عربي</th>
                        <th>المدينة</th>
                        <th>المنطقة</th>
                        <th>الرمز البريدي</th>
                        <th>الترتيب</th>
                        <th>الحالة</th>
                      </tr>
                    </thead>
                    <tbody>
                      {areaList &&
                        areaList.filter(filterAreaList).map(function (area) {
                          return (
                            <tr key={area.id}>
                              <th className="px-4">{area.code}</th>
                              <td>{area.englishName}</td>
                              <td>{area.arabicName}</td>
                              <td>{area.city}</td>
                              <td>{area.state}</td>
                              <td>{area.zipCode}</td>
                              <td>{area.sequence}</td>
                              <td>{UtilityService.renderActiveOrInactiveStatus(area.status)}</td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default AreaManagment;
