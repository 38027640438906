// reactstrap components
import { Card, CardBody, CardTitle, Row, Col } from "reactstrap";
import { Link } from "react-router-dom";

const StatisticWidget = (props) => {
  const color = props.color || "primary";
  return (
    <Card className={"card-stats mb-0 mb-md-4 mb-xl-0 bg-" + color}>
      <CardBody>
        <Link to={props.url}>
          <Row>
            <div className="col text-center">
              <CardTitle tag="h5" className="text-uppercase text-white mb-0">
                {props.title}
              </CardTitle>
              <span className="h2 font-weight-bold mb-0 text-white">{props.value}</span>
            </div>
            <Col className="col-auto">
              <div className={"icon icon-shape bg-white rounded-circle shadow text-" + color}>
                <i className={props.icon} />
              </div>
            </Col>
          </Row>
        </Link>
      </CardBody>
    </Card>
  );
};

export default StatisticWidget;
