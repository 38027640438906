import { CollectionAPI } from "api/collection";

const CollectionService = {
  createInvoice: function (packageIds) {
    return CollectionAPI.createInvoice(packageIds);
  },
  getCollectionList: function (filters) {
    return CollectionAPI.getCollectionList(filters);
  },
  getCollectionDetails : function (collectionId){
    return CollectionAPI.getCollectionDetails(collectionId);
  },
  printCollectionInvoice: function (collectionId) {
    return CollectionAPI.printCollectionInvoice(collectionId);
  },
  downloadCollectionInvoicePdf: function (fileNmae) {
    return CollectionAPI.downloadCollectionInvoicePdf(fileNmae);  
  },
  reCalculateCollections: function (collectionId) {
    return CollectionAPI.reCalculateCollections(collectionId);
  },
  payCollections: function (collectionId, data) {
    return CollectionAPI.payCollections(collectionId, data);
  }
};

export default CollectionService;
