import {
  GET_CURRENT_USER_API_URL,
  GET_CURRENT_USER_PRIMARY_ADDRESS_API_URL,
  UPDATE_CURRENT_USER_PASSWORD_API_URL,
  UPDATE_CURRENT_USER_PRIMARY_ADDRESS_URL,
  UPDATE_CURRENT_USER_PROFILE_API_URL,
} from "../variables/constant";

import apiClient from "./axios.js";

const UserAPI = {
  getCurrentUser: function () {
    return apiClient.get(GET_CURRENT_USER_API_URL);
  },
  updateCurrentUserProfile: function (data) {
    return apiClient.post(UPDATE_CURRENT_USER_PROFILE_API_URL, data);
  },
  updateCurrentUserPassword: function (data) {
    return apiClient.post(UPDATE_CURRENT_USER_PASSWORD_API_URL, data);
  },
  updateCurrentUserPrimaryAddress: function (data) {
    return apiClient.post(UPDATE_CURRENT_USER_PRIMARY_ADDRESS_URL, data);
  },
  getCurrentUserPrimaryAddress: function () {
    return apiClient.get(GET_CURRENT_USER_PRIMARY_ADDRESS_API_URL);
  },
};

export default UserAPI;
