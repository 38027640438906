import React from "react";
import { Button, Modal, Input, Col } from "reactstrap";
import { authService } from "service/auth";

class ResetPasswordModal extends React.Component {
  state = {
    isOpen: false,
    userName: "",
    errorMessage: "",
    done: false,
  };

  openModal = () => {
    this.setState({
      isOpen: true,
      userName: "",
      errorMessage: "",
      done: false,
    });
  };
  handleName = (Name) => {
    this.setState({
      userName: Name,
    });
    console.log(this.state.userName);
  };

  closeModal = () => {
    this.setState({
      isOpen: false,
    });
  };

  handleResetPassword = () => {
    this.setState({ errorMessage: "" });
    if (this.state.userName) {
      authService
        .resetPassword(this.state.userName)
        .then((res) => {
          this.setState({ done: true });
        })
        .catch(() => {
          this.setState({ errorMessage: "ناسف, فشل في اعادت تعين كلمة المرور. الرجاء المحاولة مرة اخرى لاحقا." });
        });
    } else {
      this.setState({ errorMessage: "الرجاء ادخال اسم المستخدم." });
    }
  };

  render() {
    const { isOpen, done } = this.state;
    return (
      <>
        <>
          <div className="text-center">
            <a href="#pablo" onClick={() => this.openModal()}>
              هل نسيت كلمة المرور؟
            </a>
          </div>

          <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={() => this.closeModal()}>
            <div className="modal-body pb-0">
              <div justify-content="center">
                <h1 className="text-center">هل نسيت كلمة المرور؟</h1>
                {!done && (
                  <div>
                    <p>قم بادخال اسم المستخدم ادناه لاعادة تعين كلمة المرور.</p>
                    <div>
                      <label className="form-control-label">اسم المستخدم:</label>
                      <Input
                        className={"form-control " + (this.state.errorMessage ? "is-invalid" : "")}
                        type="text"
                        onChange={(e) => {
                          this.handleName(e.target.value);
                        }}
                      />
                    </div>
                    <p className="mt-2">سيتم ارسال كلمة مرور جديدة الى هاتفك المسجل لدينا كرسالة نصية SMS.</p>
                    <p className="text-danger mb-0 mt-2">&nbsp;{this.state.errorMessage}</p>
                  </div>
                )}
              </div>
              {done && <p>تم اعادة تعين كلمة المرور الخاصة بحسابك وارساها الى رقم الموبايل المسجل لدينا.</p>}
            </div>

            <div className="modal-footer pt-0">
              {!done && (
                <Button color="primary" type="button" className="w-100" onClick={this.handleResetPassword}>
                  اعادة تعين كلمة المرور
                </Button>
              )}
              {done && (
                <Button
                  color="secondary"
                  data-dismiss="modal"
                  className="w-100"
                  type="button"
                  onClick={this.closeModal}>
                  اغلاق
                </Button>
              )}
            </div>
          </Modal>
        </>
      </>
    );
  }
}

export default ResetPasswordModal;
