import { useEffect, useState } from "react";
import { FormGroup } from "reactstrap";
import OrganizationService from "service/organization";

const OrganizationSelect = (props) => {
  const [organizationId, setOrganizationId] = useState(props.defaultValue || "");
  const [organizationList, setOrganizationList] = useState([]);

  const handleChange = (e) => {
    setOrganizationId(e.target.value);
    if (props.onChange) {
      props.onChange(organizationList.find((o) => o.id == e.target.value));
    }
  };

  useEffect(() => {
    OrganizationService.getAllOrganizationList(true, false).then((res) => {
      setOrganizationList(res.data);
    });
  }, []);

  return (
    <FormGroup className={props.inline ? "d-flex align-items-center mb-0" : ""}>
      <label className={`form-control-label ${props.inline ? "mb-0" : ""}`}>الشركة:</label>
      <select
        type="text"
        value={organizationId}
        onChange={handleChange}
        className={`form-control ${props.showError && !organizationId ? "is-invalid" : ""} ${
          props.inline ? "mr-2" : ""
        }`}>
        <option value=""></option>
        {organizationList.map((org) => {
          return (
            <option key={org.id} value={org.id}>
              {org.name}
            </option>
          );
        })}
      </select>
    </FormGroup>
  );
};

export default OrganizationSelect;
