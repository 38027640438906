import { Col, Container, Row } from "reactstrap";

import UserHeader from "components/Headers/UserHeader.js";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import UserService from "service/user";
import BasicInfo from "./BasicInfo";
import ChangePassword from "./ChangePassword";
import PrimaryAddress from "./PrimaryAddress";
import OrganizationInfo from "./OrganizationInfo";

const Profile = (props) => {
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    populateUserProfile();
  }, [props]);

  const populateUserProfile = () => {
    trackPromise(
      UserService.fetchCurrentUser().then((res) => {
        setUserDetails(res.data);
      })
    );
  };

  return (
    <>
      <UserHeader user={userDetails} />
      {/* Page content */}
      <Container className="pt-2 pt-md-4" fluid>
        <Row>
          <Col className="mb-3" lg="6">
            <BasicInfo userDetails={userDetails} onSubmit={populateUserProfile} />
          </Col>
          <Col lg="3" className="mb-3">
            <ChangePassword />
          </Col>
          <Col className="mb-3" lg="3">
            <OrganizationInfo />
          </Col>

          <Col className="mb-3" lg="6">
            <PrimaryAddress userId={userDetails.id} />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Profile;
