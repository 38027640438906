import React from "react";
import { Route, Routes } from "react-router-dom";

import NotFound from "../views/error/NotFound";

const FullPage = (props) => {
  return (
    <>
      <div className="main-content full-page-layout bg-primary">
        <Routes>
          <Route path="/" exact element={<NotFound />} />
        </Routes>
      </div>
    </>
  );
};

export default FullPage;
