// nodejs library to set properties for components
import UserAvatar from "components/UserAvatar";
import UtilityService from "service/utility";

const CustomerDisplay = (props) => {
  let { address, user } = props;
  const firstName = user && user.firstName ? user.firstName : address?.firstName;
  const middleName = user && user.middleName ? user.middleName : address?.middleName;
  const lastName = user && user.lastName ? user.lastName : address?.lastName;
  const mobile = user && user.mobile ? user.mobile : address?.mobile;
  const mobile2 = address?.mobile2;

  if (!address && user && user.address) {
    address = user.address;
  }

  return (
    <div className="user-display avatar-group">
      <a className="d-flex align-items-center" href="#" onClick={(e) => e.preventDefault()}>
        <UserAvatar photo={user?.photo} />
        <span className="mx-2 text-gray text-wrap">
          <span className="text-dark text-wrap">
            {firstName} {middleName} {lastName}
            {<span className="badge badge-primary text-dark mx-2">{mobile}</span>}
            {<span className="badge badge-primary text-dark mx-2">{mobile2}</span>}
          </span>
          <br /> {address && UtilityService.formatAddress(address, props.fullAddress)}
          {!address && <span>العنوان غير متوفر</span>}
        </span>
      </a>
    </div>
  );
};

export default CustomerDisplay;
