import { Dropdown } from "antd";
import EditUserModal from "components/Modals/EditUserModal";
import AdminResetUserPassword from "components/Modals/AdminResetUserPassword";

const UsersActions = (props) => {
  const user = props.user;

  const handleMenuClick = (e) => {
    switch (e.key) {
      case "ENABLE_USER":
        props.onEnableUser();
        break;
      case "DISABLE_USER":
        props.onDisableUser();
        break;
      default:
        break;
    }
  };

  const actions = [
    {
      label: <EditUserModal user={user} isOrgAdmin={props.isOrgAdmin} onChange={props.onEditUser} />,
      key: "EDIT_USER",
      icon: <i className="fas fa-edit" />,
    },
    {
      label: "تعطيل",
      key: "DISABLE_USER",
      icon: <i className="fas fa-times" />,
      disabled: !user.status,
    },
    {
      label: "تفعيل",
      key: "ENABLE_USER",
      icon: <i className="fas fa-check" />,
      disabled: user.status,
    },
    {
      label: <AdminResetUserPassword userId={user.id} />,
      key: "REST_PASSWORD",
      icon: <i className="fas fa-key" />,
    },
  ];

  return (
    <Dropdown.Button trigger={["click"]} menu={{ items: actions, onClick: handleMenuClick }}>
      خيارات
    </Dropdown.Button>
  );
};

export default UsersActions;
