const Profile = () => {
  return (
    <>
      <div className="d-flex h-100 justify-content-center align-items-center">
        <h1>No Found</h1>
      </div>
    </>
  );
};

export default Profile;
