import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import CustomerDisplay from "./CustomerDisplay";

const CustomerInfo = (props) => {
  const { address, customerNote, customerId } = props;

  const buildWhatsAppLink = () => {
    let number = address.mobile;
    if (!number) return;
    number = number.slice(-9);
    if (number.length === 9) {
      return "https://wa.me/970" + number;
    }
    return;
  };

  const wlink = buildWhatsAppLink();

  return (
    <Row className="m-0">
      <Col lg="12">
        <h3>معلومات الزبون</h3>
      </Col>
      <Col className="col-12 mt-2 d-flex">
        <div>
          <CustomerDisplay address={address} />
        </div>
        <div className="mr-4 border-right pr-4">
          <p className="m-0 font-weight-bold">
            تاريخ التسليم: <span className="font-weight-normal"></span>
          </p>
          <p className="m-0 mt-2 font-weight-bold">
            وقت التسليم: <span className="font-weight-normal">صباحا</span>
          </p>
        </div>
        <div className="mr-4 border-right pr-4">
          <p className="m-0 font-weight-bold">
            ملاحظات الزبون: <span className="font-weight-normal">{customerNote}</span>
          </p>
        </div>
        <div className="d-flex mr-auto">
          <div className="mr-4 d-flex">
            <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
              <Link to={"/messages?userId=" + customerId}>
                <i className="fas fa-comment-dots text-white"></i>
              </Link>
            </div>
          </div>
          <div className="mr-4 d-flex">
            <a href={"tel:" + address.mobile}>
              <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                <i className="fas fa-phone"></i>
              </div>
            </a>
          </div>
          {wlink && (
            <div className="mr-4 d-flex">
              <a href={wlink} target="_blank">
                <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                  <i className="fab fa-whatsapp"></i>
                </div>
              </a>
            </div>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default CustomerInfo;
