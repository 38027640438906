import OrganizationAPI from "api/organization";

const OrganizationService = {
  getCurrentOrganization: function (includeBranchList) {
    return OrganizationAPI.getCurrentOrganization(includeBranchList);
  },
  getOrganizationDetails: function (id) {
    return OrganizationAPI.getOrganizationDetails(id);
  },
  getAllOrganizationList: function (includeBranchList, includeStatistic) {
    return OrganizationAPI.getAllOrganizationList(includeBranchList, includeStatistic);
  },
  getBranchList: function () {
    return OrganizationAPI.getBranchList();
  },
  getUserList: function () {
    return OrganizationAPI.getUserList();
  },
  createNewBranch: function (branchName, city, address, phone, organizationId) {
    return OrganizationAPI.createNewBranch({
      branchName: branchName,
      city: city,
      address: address,
      phone: phone,
      organizationId: organizationId,
    });
  },
  updateBranch: function (branchId, branchName, city, address, phone) {
    return OrganizationAPI.updateBranch(branchId, {
      branchName: branchName,
      city: city,
      address: address,
      phone: phone,
    });
  },
  updateBranchStatus: function (branchId, newStatus) {
    return OrganizationAPI.updateBranch(branchId, { status: newStatus });
  },
  createNewUser: function (
    firstName,
    middleName,
    lastName,
    userName,
    gender,
    mobile,
    email,
    password,
    organizationId,
    branchId,
    admin,
    status
  ) {
    return OrganizationAPI.createNewUser({
      firstName,
      middleName,
      lastName,
      userName,
      gender,
      mobile,
      email,
      password,
      organizationId,
      branchId,
      admin,
      status,
    });
  },
  updateUserStatus: function (userId, newStatus) {
    return OrganizationAPI.updateUser(userId, { status: newStatus });
  },
  updateUser: function (userId, firstName, middleName, lastName, gender, mobile, email, isAdmin, status) {
    return OrganizationAPI.updateUser(userId, {
      firstName,
      middleName,
      lastName,
      mobile,
      gender,
      email,
      admin: isAdmin,
      status,
    });
  },
  resetUserPassword: function (userId, password) {
    return OrganizationAPI.resetUserPassword(userId, { password: password });
  },
  createNewOrganization: function (organizationName, city, address, phone) {
    return OrganizationAPI.createNewOrganization({
      name: organizationName,
      city: city,
      address: address,
      phone: phone,
    });
  },
  updateOrganization: function (organizationId, organizationName, city, address, phone) {
    return OrganizationAPI.updateOrganization(organizationId, {
      name: organizationName,
      city: city,
      address: address,
      phone: phone,
    });
  },
  updateOrganizationStatus: function (organizationId, newStatus) {
    return OrganizationAPI.updateOrganization(organizationId, { status: newStatus });
  },
};

export default OrganizationService;
