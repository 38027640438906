import React from "react";
import { Button, Modal, FormGroup, Form, Input, Row, Col } from "reactstrap";
import AddressForm from "components/Address/AddressForm";
import CustomerService from "service/customer";

class NewCustomerModal extends React.Component {
  state = {
    validation: false,
    isOpen: false,
    firstName: "",
    middleName: "",
    lastName: "",
    mobile: "",
    email: "",
    gender: "MALE",
    address: null,
    errorMessage: "",
  };

  openModal = () => {
    this.setState({
      validation: false,
      isOpen: true,
      firstName: "",
      middleName: "",
      lastName: "",
      mobile: "",
      email: "",
      gender: "MALE",
      address: null,
    });
  };

  closeModal = () => {
    this.setState({
      isOpen: false,
    });
  };

  handleSubmit = () => {
    const self = this;
    this.setErrorMessage("");
    if (this.isValidForm()) {
      const { firstName, middleName, lastName, mobile, email, address, gender } = this.state;
      CustomerService.createNewCustomer(firstName, middleName, lastName, mobile, email, address, gender)
        .then(() => {
          self.closeModal();
        })
        .catch((error) => {
          if (error.response.data.code === "1001") {
            this.setErrorMessage("رقم الجوال الذي أدخلته مسجل لدينا. يرجى ادخال رقم جوال فريد.");
          } else {
            this.setErrorMessage("حدث خطا اثناء حفظ البيانات, يرجى المحاولة مرة اخرى");
          }
        });
    }
  };

  isValidForm = () => {
    let isValid = true;

    if (this.state.firstName.trim() === "" || this.state.mobile.trim() === "") {
      isValid = false;
      this.setErrorMessage("الرجاء ادخال جميع الحقول المطلوبة.");
    }

    if (this.isValidMobile(this.state.mobile)) {
      isValid = false;
      this.setErrorMessage("الرجاء ادخال رقم موبايل صحيح.");
    }

    if (!this.state.address || !this.state.address.areaCode) {
      isValid = false;
      this.setErrorMessage("الرجاء ادخال جميع الحقول المطلوبة.");
    }
    this.setState({ validation: true });
    return isValid;
  };

  isValidMobile = (mobile) => {
    const mobilePattern = /^05\d{8}$/;
    return !mobilePattern.test(mobile.trim());
  };

  setErrorMessage = (message) => {
    this.setState({ errorMessage: message });
  };

  render() {
    const { isOpen, validation, firstName, middleName, lastName, mobile, errorMessage } = this.state;
    return (
      <>
        <Button color="success" type="button" onClick={() => this.openModal()}>
          زبون جديد
        </Button>

        <Modal
          className="modal-dialog-centered modal-lg customer-serach-modal"
          isOpen={isOpen}
          toggle={() => this.closeModal()}>
          <div className="modal-header border-bottom">
            <h3 className="modal-title">ادخال زبون جديد</h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.closeModal()}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Form>
              <div>
                <Row>
                  <Col lg="3">
                    <FormGroup>
                      <label className="form-control-label">الاسم الاول</label>
                      <Input
                        className={`form-control ${validation && firstName.length === 0 ? "is-invalid" : ""}`}
                        type="text"
                        placeholder="الاسم الاول"
                        onChange={(e) => {
                          this.setState({ firstName: e.target.value.trim() });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="3">
                    <FormGroup>
                      <label className="form-control-label">اسم الاب</label>
                      <Input
                        className={`form-control ${validation && middleName.length === 0 ? "is-invalid" : ""}`}
                        type="text"
                        placeholder="اسم الاب"
                        onChange={(e) => {
                          this.setState({ middleName: e.target.value.trim() });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="3">
                    <FormGroup>
                      <label className="form-control-label">الاسم الاخير</label>
                      <Input
                        className={`form-control ${validation && lastName.length === 0 ? "is-invalid" : ""}`}
                        type="text"
                        placeholder="الاسم الاول"
                        onChange={(e) => {
                          this.setState({ lastName: e.target.value });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="3">
                    <FormGroup>
                      <label className="form-control-label">الجنس</label>
                      <select
                        className="form-control"
                        onChange={(e) => {
                          this.setState({ lastName: e.target.value });
                        }}>
                        <option value="MALE">ذكر</option>
                        <option value="FMALE">انثى</option>
                      </select>
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label">رقم الموبايل</label>
                      <Input
                        className={`form-control ${validation && this.isValidMobile(mobile) ? "is-invalid" : ""}`}
                        type="text"
                        placeholder="رقم الموبايل"
                        onChange={(e) => {
                          this.setState({ mobile: e.target.value.trim() });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label">البريد الالكتروني</label>
                      <Input
                        className="form-control"
                        type="text"
                        placeholder="البريد الالكتروني"
                        onChange={(e) => {
                          this.setState({ email: e.target.value });
                        }}
                      />
                    </FormGroup>
                  </Col>
                  <hr className="my-2 col-12" />
                  <Col lg="12">
                    <AddressForm
                      onChange={(address) => {
                        this.setState({ address: address });
                      }}
                      showError={validation}
                    />
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
          <div className="modal-footer border-top">
            <span className="text-danger ml-auto">{errorMessage}</span>
            <Button color="secondary" data-dismiss="modal" type="button" onClick={() => this.closeModal()}>
              اغلاق
            </Button>
            <Button color="primary" type="button" onClick={() => this.handleSubmit()}>
              حفظ
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default NewCustomerModal;
