import { useMemo, useState, useRef } from "react";

import { MaterialReactTable, useMaterialReactTable } from "material-react-table";

//Date Picker Imports - these should just be in your Context Provider
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

//Material UI Imports
import { ListItemIcon, MenuItem } from "@mui/material";

//Icons Imports
import { Person, Print, Check, CalendarToday, Link } from "@mui/icons-material";

import { MRT_Localization_AR } from "material-react-table/locales/ar/index.js";

import { ThemeProvider, createTheme, useTheme } from "@mui/material";
import { arSA } from "@mui/material/locale";
import UtilityService from "service/utility";
import { PACKAGE_STATUS_LIST } from "variables/constant";
import PackageTableTopBar from "./PackageTableTopBar";
import UpdateDeliveryCost from "./UpdateDeliveryCost";
import UpdateCollectionCost from "./UpdateCollectionCost";
import PackageService from "service/package";
import PackageIdentifier from "./PackageIdentifier";
import ChangeDeliveryDateModel from "./ChangeDeliveryDateModel";
import LinkToExternalSystemModal from "./LinkToExternalSystemModal";

const Table = (props) => {
  const { data } = props;
  const editingRow = useRef(null);
  const [isChangeModalOpen, setChangeModalOpen] = useState(false);
  const [isSetFilter, setFilter] = useState(false);
  const status = new URLSearchParams(window.location.search).get("status");

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        enablePinning: true,
        header: "الرقم التسلسلي",
        filterVariant: "text",
        size: 200,
        Cell: ({ cell }) => <PackageIdentifier package={cell.row.original} />,
      },
      {
        accessorKey: "refrencesNumber",
        header: "الرقم المرجعي",
      },
      {
        header: "الحالة",
        accessorKey: "status",
        filterVariant: "multi-select",
        Cell: ({ cell }) => UtilityService.renderStatusMessage("PACKAGE", cell.getValue()),
        size: 170,
        filterSelectOptions: PACKAGE_STATUS_LIST.map((s) => {
          return { label: UtilityService.renderStatusMessage("PACKAGE", s, false, true), value: s };
        }),
      },
      {
        accessorFn: (originalRow) => originalRow.organization.name,
        id: "organizationName",
        header: "المرسل",
        filterVariant: "text",
        muiTableHeadCellProps: {
          align: "left",
        },
      },
      {
        accessorFn: (originalRow) => `${originalRow.address.firstName} ${originalRow.address.lastName ? originalRow.address.lastName : ''}`,
        id: "customerName",
        header: "المستقبل",
        filterVariant: "text",
      },
      {
        accessorFn: (originalRow) => {
          const { mobile, mobile2 } = originalRow.address;
          return (
            <>
              {mobile}
              {mobile2 && <><br />{mobile2}</>}
            </>
          );
        },
        id: "customerMobile",
        header: "رقم المستقبل",
        filterVariant: "text",
      },
      {
        accessorFn: (originalRow) => UtilityService.formatAddress(originalRow.address, true),
        id: "customerAddress",
        header: "العنوان",
        filterVariant: "text",
        muiTableHeadCellProps: {
          align: "left",
        },
      },
      {
        accessorFn: (originalRow) => new Date(originalRow.created), //convert to date for sorting and filtering
        header: "تاريخ الادخال",
        filterVariant: "date-range",
        Cell: ({ cell }) => cell.getValue().toLocaleDateString(), // convert back to string for display
      },
      {
        accessorFn: (originalRow) => originalRow.deliveryDate ? new Date(originalRow.deliveryDate) : "", //convert to date for sorting and filtering
        header: "تاريخ التوصيل",
        filterVariant: "date-range",
        Cell: ({ cell }) => cell.getValue() ? cell.getValue().toLocaleDateString() : "", // convert back to string for display
      },
      {
        accessorKey: "cost",
        header: "التكلفة",
        size: 120,
        Cell: ({ cell }) =>
          cell.getValue()?.toLocaleString("en-US", {
            style: "currency",
            currency: "ILS",
          }),
      },
      {
        accessorKey: "totalCollection",
        header: "التحصيل",
        size: 120,
        Cell: ({ cell }) =>
          cell.getValue()?.toLocaleString("en-US", {
            style: "currency",
            currency: "ILS",
          }),
      },
      {
        accessor: "requiredForMerchant",
        header: "مطلوب لتاجر",
        size: 150,
        Cell: ({ cell }) => {
          const requiredForMerchant = cell.row.original.totalCollection - cell.row.original.cost;
          return requiredForMerchant.toLocaleString("en-US", {
            style: "currency",
            currency: "ILS",
          });
        },
      },
      {
        accessorKey: "trackingNumber",
        header: "رابط مشاركة",
        Cell: ({ cell }) => (
          <a target="_blank" href={process.env.REACT_APP_PACKAGE_TRACKING_URL + "?id=" + cell.getValue()}>
            {cell.getValue()}
          </a>
        ),
      },
      {
        accessorKey: "customerNote",
        header: "ملاحظات",
      },
    ],
    []
  );

  const handlePrintBill = () => {
    const id = [editingRow.current.row.original.id];
    PackageService.printBill(id).then((res) => {
      if (res.data.success) {
        const newTab = window.open(process.env.REACT_APP_STATIC_REPORT_URL + res.data.fileName, "_blank");
        if (newTab) {
          newTab.focus();
        }
      }
    });
  };

  const handleDeliveryCostChange = (newValue) => {
    const id = [editingRow.current.row.original.id];

    PackageService.updatePackageCost(id, newValue).then((res) => {
      if (res.data.success) {
        props.onDataChange();
      }
    });
    setChangeModalOpen(false);

  };

  const handleTotalCollectionChange = (newValue) => {
    const id = [editingRow.current.row.original.id];
    PackageService.updatePackageTotalCollection(id, newValue).then((res) => {
      if (res.data.success) {
        props.onDataChange();
      }
    });
    setChangeModalOpen(false);

  };

  const handleDeliveryDateChange = (newDeliveryDate, isStatusChangeChecked) => {
    const id = [editingRow.current.row.original.id];
    const data = {
      deliveryDate: newDeliveryDate,
    }
    if (isStatusChangeChecked)
      data.status = "S";

    PackageService.updatePackageDeliveryDate(id, data).then((res) => {
      if (res.data.success) {
        props.onDataChange();
      }
    }).catch((error) => {
      console.error('Request failed with error:', error);
    });

    setChangeModalOpen(false);
  };

  const handelLinkToExternalSystem = () => {
    props.onDataChange();
  }

  const table = useMaterialReactTable({
    columns,
    data,
    enableColumnResizing: true,
    columnResizeDirection: "rtl",
    enableRowSelection: true,
    enableRowActions: true,
    initialState: {
      showColumnFilters: false,
      columnPinning: { right: ["mrt-row-actions"] },
    },
    localization: { ...MRT_Localization_AR },
    enableFullScreenToggle: true,
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    columnFilterDisplayMode: "popover",
    enablePagination: true,
    positionToolbarAlertBanner: "head-overlay",
    renderRowActionMenuItems: ({ closeMenu, row }) => [

      <MenuItem
        key={0}
        onClick={() => {
          // تعديل قيمة التوصيل logic...
          setChangeModalOpen(true);
          editingRow.current = { row, key: 0 };
          closeMenu();
        }}
        sx={{ m: 0 }}>
        <ListItemIcon>
          <Check />
        </ListItemIcon>
        تعديل قيمة التوصيل
      </MenuItem>,
      <MenuItem
        key={1}
        onClick={() => {
          // تعديل قيمة التحصيل logic...
          setChangeModalOpen(true);
          editingRow.current = { row, key: 1 };
          closeMenu();
        }}
        sx={{ m: 0 }}>
        <ListItemIcon>
          <Check />
        </ListItemIcon>
        تعديل قيمة التحصيل
      </MenuItem>,
      <MenuItem
        key={2}
        onClick={() => {
          // تعين سائق logic...
          closeMenu();
        }}
        sx={{ m: 0 }}>
        <ListItemIcon>
          <Person />
        </ListItemIcon>
        تعين سائق
      </MenuItem>,
      <MenuItem
        key={3}
        onClick={() => {
          // طباعة الشحن logic...
          editingRow.current = { row, key: 3 };
          handlePrintBill();
          closeMenu();
        }}
        sx={{ m: 0 }}>
        <ListItemIcon>
          <Print />
        </ListItemIcon>
        طباعة بوليصة
      </MenuItem>,
      <MenuItem
        key={4}
        onClick={() => {
          // logic تغير تاريخ التسليم
          setChangeModalOpen(true);
          editingRow.current = { row, key: 4 };
          closeMenu();
        }}
        sx={{ m: 0 }}>
        <ListItemIcon>
          <CalendarToday />
        </ListItemIcon>
        تغير تاريخ التوصيل
      </MenuItem>,
      <MenuItem
        key={5}
        onClick={() => {
          // logic ربط مع نظام خارجي 
          setChangeModalOpen(true);
          editingRow.current = { row, key: 5 };
          closeMenu();
        }}
        sx={{ m: 0 }}>
        <ListItemIcon>
          <Link />
        </ListItemIcon>
        ربط مع نظام خارجي
      </MenuItem>,

    ],
    onDataChange: props.onDataChange,
    muiTableBodyCellProps: { align: "right" },
    renderTopToolbar: PackageTableTopBar,
  });

  if (!isSetFilter && status) {
    table.getAllColumns().find((column) => column.id === "status").setFilterValue([status]);
    setFilter(true);
  }

  return (
    <div>
      <MaterialReactTable table={table} />
      <UpdateDeliveryCost
        isOpen={isChangeModalOpen && editingRow.current.key == 0}
        onClose={() => setChangeModalOpen(false)}
        onChange={handleDeliveryCostChange}
      />
      <UpdateCollectionCost
        isOpen={isChangeModalOpen && editingRow.current.key == 1}
        onClose={() => setChangeModalOpen(false)}
        onChange={handleTotalCollectionChange}

      />
      <ChangeDeliveryDateModel
        isOpen={isChangeModalOpen && editingRow.current.key == 4}
        onClose={() => setChangeModalOpen(false)}
        onChange={handleDeliveryDateChange}
      />
      {editingRow.current && (
        <LinkToExternalSystemModal
          isOpen={isChangeModalOpen && editingRow.current.key == 5}
          onClose={() => setChangeModalOpen(false)}
          packageId={editingRow.current.row.original.id}
          onChanged={handelLinkToExternalSystem}
        />
      )}
    </div>
  );
};

const PackageTable = (props) => {
  const theme = useTheme(); //replace with your theme/createTheme

  // define direction for table to solve pagination Icons is in wrong direction
  const direction = { direction: "rtl" };

  return (
    //Setting Material UI locale as best practice to result in better accessibility
    <ThemeProvider theme={createTheme(theme, arSA, direction)}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Table data={props.data} onDataChange={props.onDataChange} />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default PackageTable;
