import APIClient from "./axios.js";
import {
  GET_CURRENT_ORGANIZATION_API_URL,
  GET_CURRENT_ORGANIZATION_BRANCH_LIST_API_URL,
  ORGANIZATION_CREATE_NEW_BRANCH_API_URL,
  CREATE_NEW_ORGANIZATION_API_URL,
  ORGANIZATION_UPDATE_BRANCH_API_URL,
  UPDATE_ORGANIZATION_API_URL,
  GET_CURRENT_ORGANIZATION_USER_LIST_API_URL,
  ORGANIZATION_CREATE_NEW_USER_API_URL,
  ORGANIZATION_UPDATE_USER_API_URL,
  GET_ALL_ORGANIZATION_API_URL,
  ORGANIZATION_RESET_USER_PASSWORD_API_URL,
  GET_ORGANIZATION_DETAILS_API_URL,
} from "../variables/constant";

const OrganizationAPI = {
  getCurrentOrganization: function (includeBranchList) {
    return APIClient.get(GET_CURRENT_ORGANIZATION_API_URL, { params: { includeBranchList } });
  },
  getOrganizationDetails: function (id) {
    return APIClient.get(GET_ORGANIZATION_DETAILS_API_URL.replace("{organizationId}", id));
  },
  getAllOrganizationList: function (includeBranchList, includeStatistic) {
    return APIClient.get(GET_ALL_ORGANIZATION_API_URL, { params: { includeBranchList, includeStatistic } });
  },
  getBranchList: function () {
    return APIClient.get(GET_CURRENT_ORGANIZATION_BRANCH_LIST_API_URL);
  },
  getUserList: function () {
    return APIClient.get(GET_CURRENT_ORGANIZATION_USER_LIST_API_URL);
  },
  createNewBranch: function (data) {
    return APIClient.post(ORGANIZATION_CREATE_NEW_BRANCH_API_URL, data);
  },
  createNewOrganization: function (data) {
    return APIClient.post(CREATE_NEW_ORGANIZATION_API_URL, data);
  },
  createNewUser: function (data) {
    return APIClient.post(ORGANIZATION_CREATE_NEW_USER_API_URL, data);
  },
  updateBranch: function (branchId, data) {
    return APIClient.post(ORGANIZATION_UPDATE_BRANCH_API_URL.replace("{branchId}", branchId), data);
  },
  updateOrganization: function (organizationId, data) {
    return APIClient.post(UPDATE_ORGANIZATION_API_URL.replace("{organizationId}", organizationId), data);
  },
  updateUser: function (userId, data) {
    return APIClient.post(ORGANIZATION_UPDATE_USER_API_URL.replace("{userId}", userId), data);
  },
  resetUserPassword: function (userId, data) {
    return APIClient.post(ORGANIZATION_RESET_USER_PASSWORD_API_URL.replace("{userId}", userId), data);
  },
};

export default OrganizationAPI;
