import DriverAPI from "api/driver";

const DriverService = {
  getAllDriversList: function () {
    return DriverAPI.getAllDriversList();
  },
  createNewDriver: function (firstName, middleName, lastName, userName, gender, mobile, email, password) {
    return DriverAPI.createNewDriver({ firstName, middleName, lastName, userName, gender, mobile, email, password });
  },
  updateDriverStatus: function (userId, newStatus) {
    return DriverAPI.updateDriver(userId, { status: newStatus });
  },
  updateDriver: function (userId, firstName, middleName, lastName, gender, mobile, email) {
    return DriverAPI.updateDriver(userId, { firstName, middleName, lastName, mobile, gender, email });
  },
};

export default DriverService;
