import { Col, Row, Card, CardBody, CardHeader } from "reactstrap";
import UtilityService from "service/utility";

const BasicInfo = (props) => {
  const { organization } = props;

  return (
    <Card className="shadow">
      <CardHeader className="bg-transparent">
        <Row className="align-items-center">
          <div className="col">
            <h3 className="mb-0">المعلومات الاساسية</h3>
          </div>
          <div className="col text-left d-flex justify-content-end align-items-center"></div>
        </Row>
      </CardHeader>
      <CardBody className="p-2">
        <Row className="m-0">
          <Col className="col-12 mt-2 my-2">
            <p className="m-0 font-weight-bold">
              اسم الشركة / التاجر: <span className="font-weight-normal">{organization.name}</span>
            </p>

            <p className="m-0 mt-2 font-weight-bold">
              تلفون : <span className="font-weight-normal">{organization.phone}</span>
            </p>

            <p className="m-0 mt-2 font-weight-bold">
              العنوان : <span className="font-weight-normal">{organization.address}</span>
            </p>
            <p className="m-0 mt-2 font-weight-bold">
              تاريخ الانشاء :{" "}
              <span className="font-weight-normal">{UtilityService.formatTimestamp(organization.created)}</span>
            </p>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default BasicInfo;
