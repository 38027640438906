import UserAvatar from "components/UserAvatar";
import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";

const DriverInfo = (props) => {
  const { driver, car } = props;

  return (
    <Row className="m-0">
      <Col lg="12">
        <h3>معلومات السائق</h3>
      </Col>
      <Col className="col-12 mt-2 d-flex">
        <div>
          <UserAvatar photo={driver.photo} />
        </div>
        <div className="mr-4">
          <p className="m-0 font-weight-bold">
            {driver.firstName} {driver.middleName} {driver?.lastName} ({driver?.userName})
          </p>
          <p className="m-0 mt-2">{driver?.mobile}</p>
        </div>
        <div className="mr-4 border-right pr-4">
          <div className="d-flex align-items-center">
            <i className="fas fa-truck" style={{ fontSize: 32 }}></i>
            <div className="mr-4">
              <p className="m-0 font-weight-bold">
                رقم السيارة: <span className="font-weight-normal">{car?.number}</span>
              </p>
              <p className="m-0 mt-2 font-weight-bold">
                نوع السيارة:{" "}
                <span className="font-weight-normal">
                  {car?.type} - {car?.color}
                </span>
              </p>
            </div>
          </div>
        </div>
        <div className="d-flex mr-auto">
          <div className="mr-4 d-flex">
            <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
              <Link to={"/messages?userId=" + driver.userId}>
                <i className="fas fa-comment-dots text-white"></i>
              </Link>
            </div>
          </div>
          <div className="mr-4 d-flex">
            <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
              <i className="fas fa-phone"></i>
            </div>
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default DriverInfo;
