import APIClient from './axios.js';
import {
  GET_CUSTOMER_STATISTIC_API_URL,
  GET_COLLECTION_STATISTIC_API_URL,
  GET_SUMMARY_STATISTIC_API_URL,
  GET_PACKAGE_STATISTIC_API_URL,
  GET_PACKAGE_BY_CITY_STATISTIC_API_URL,
} from '../variables/constant';

const StatisticAPI = {
  getCustomerStatistic: function () {
    return APIClient.get(GET_CUSTOMER_STATISTIC_API_URL);
  },
  getCollectionStatistic: function () {
    return APIClient.get(GET_COLLECTION_STATISTIC_API_URL);
  },
  getSummaryStatistic: function () {
    return APIClient.get(GET_SUMMARY_STATISTIC_API_URL);
  },
  getPackageStatistic: function () {
    return APIClient.get(GET_PACKAGE_STATISTIC_API_URL);
  },
  getPackageByCityStatistic: function () {
    return APIClient.get(GET_PACKAGE_BY_CITY_STATISTIC_API_URL);
  },
};

export default StatisticAPI;
