import APIClient from "./axios.js";
import {
  CREAT_COLLECTION_INVOICE_API_URL,
  GET_COLLECTION_LIST_API_URL,
  GET_COLLECTION_DETAILS_API_URL,
  PRINT_COLLECTION_INVOICE_API_URL,
  DOWNLOAD_COLLECTION_INVOICE_FILE_URL,
  RE_CALCULATE_COLLECTIONS_API_URL,
  PAY_COLLECTIONS_API_URL,
} from "../variables/constant";

export const CollectionAPI = {
  createInvoice: function (packageIds) {
    return APIClient.post(CREAT_COLLECTION_INVOICE_API_URL, { packageIds: packageIds });
  },
  getCollectionList: function (data) {
    return APIClient.get(GET_COLLECTION_LIST_API_URL, { params: data });
  },
  getCollectionDetails: function (collectionId) {
    return APIClient.get(GET_COLLECTION_DETAILS_API_URL.replace("{collectionId}", collectionId));
  },
  printCollectionInvoice: function (collectionId) {
    return APIClient.post(PRINT_COLLECTION_INVOICE_API_URL.replace("{collectionId}", collectionId));
  },
  downloadCollectionInvoicePdf: function (fileName) {
    return APIClient.get(DOWNLOAD_COLLECTION_INVOICE_FILE_URL.replace("{fileName}", fileName));
  },
  reCalculateCollections: function (collectionId) {
    return APIClient.post(RE_CALCULATE_COLLECTIONS_API_URL.replace("{collectionId}", collectionId));
  },
  payCollections: function (collectionId, data) {
    return APIClient.post(PAY_COLLECTIONS_API_URL.replace("{collectionId}", collectionId), data);
  },
};
