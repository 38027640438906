import APIClient from "./axios.js";
import {
  GET_ALL_CONFIG_API_URL,
  SAVE_CONFIG_API_URL,
  DELETE_CONFIG_API_URL,
  REFRESH_CONFIG_API_URL,
} from "../variables/constant";

const ConfigAPI = {
  getAllConfig: function () {
    return APIClient.get(GET_ALL_CONFIG_API_URL);
  },
  saveConfig: function (data) {
    return APIClient.post(SAVE_CONFIG_API_URL, data);
  },
  deleteConfig: function (data) {
    return APIClient.post(DELETE_CONFIG_API_URL, data);
  },
  refreshAllConfig: function () {
    return APIClient.post(REFRESH_CONFIG_API_URL);
  },
  refreshConfig: function (data) {
    return APIClient.post(REFRESH_CONFIG_API_URL, data);
  },
};

export default ConfigAPI;
