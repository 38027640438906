import APIClient from './axios.js';
import {
  CUSTOMER_SEARCH_API_URL,
  CRATE_NEW_CUSTOMER_API_URL,
  GET_CUSTOMER_LIST_API_URL,
} from '../variables/constant';

export const CustomerAPI = {
  findCustomer: function (data) {
    return APIClient.get(CUSTOMER_SEARCH_API_URL, { params: data });
  },
  createNewCustomer: function (data) {
    return APIClient.post(CRATE_NEW_CUSTOMER_API_URL, data);
  },
  getCustomerList: function (data) {
    return APIClient.get(GET_CUSTOMER_LIST_API_URL, { params: data });
  },
};
