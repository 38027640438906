import EditOrganizationhModal from "components/Modals/Organization/EditOrganizationhModal";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import OrganizationService from "service/organization";
import BasicInfo from "./BasicInfo";
import BranchList from "./BranchList";
import UserList from "./UserList";

const OrganizationDetails = (props) => {
  const [organization, setOrganization] = useState({});
  const { id } = useParams();

  useEffect(() => {
    populateOrganizationDetails();
  }, [props]);

  const populateOrganizationDetails = () => {
    OrganizationService.getOrganizationDetails(id).then((res) => setOrganization(res.data));
  };

  const handleEnabled = () => {
    OrganizationService.updateOrganizationStatus(organization.id, true).then(() => {
      populateOrganizationDetails();
    });
  };

  const handleDisabled = () => {
    OrganizationService.updateOrganizationStatus(organization.id, false).then(() => {
      populateOrganizationDetails();
    });
  };

  return (
    <>
      <Container className="pt-2 pt-md-8" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">تفاصيل الشركة</h3>
                  </div>
                  <div className="col text-left d-flex justify-content-end align-items-center">
                    <i className="fas fa-redo mx-2" role="button" onClick={populateOrganizationDetails}></i>
                    {organization.status && (
                      <button type="button" className="btn btn-danger btn-sm  mx-2" onClick={handleDisabled}>
                        تعطيل <i className="fas fa-ban"></i>
                      </button>
                    )}
                    {!organization.status && (
                      <button type="button" className="btn btn-success btn-sm  mx-2" onClick={handleEnabled}>
                        تفعيل <i className="fas fa-check"></i>
                      </button>
                    )}
                    <EditOrganizationhModal organization={organization} />
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="p-0">
                {organization && (
                  <Row className="m-0">
                    <Col lg="6" className="p-4">
                      <BasicInfo organization={organization} />
                    </Col>
                    <Col lg="12" className="p-4">
                      <BranchList organization={organization} onRefresh={populateOrganizationDetails} />
                    </Col>
                    <Col lg="12" className="p-4">
                      <UserList organization={organization} onRefresh={populateOrganizationDetails} />
                    </Col>
                  </Row>
                )}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default OrganizationDetails;
