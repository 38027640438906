import CitySelect from "components/Address/CitySelect";
import React from "react";
import { Button, Col, Form, FormGroup, Input, Modal, Row } from "reactstrap";
import OrganizationService from "service/organization";

class EditOrganizationhModal extends React.Component {
  state = {
    isOpen: false,
    name: null,
    address: null,
    city: null,
    phone: null,
    showError: false,
    errorMessage: "",
    organizationId: null,
  };

  closeModal = () => {
    this.setState({
      isOpen: false,
    });
  };

  openModal = (e) => {
    this.setState({
      isOpen: true,
      name: this.props.organization?.name || "",
      address: this.props.organization?.address || "",
      city: this.props.organization?.city || "",
      phone: this.props.organization?.phone || "",
      showError: false,
      errorMessage: "",
    });
  };

  handelSubmit = () => {
    const self = this;
    this.setState({ showError: true, errorMessage: "" });

    if (this.validateForm()) {
      OrganizationService.updateOrganization(
        this.props.organization.id,
        this.state.name,
        this.state.city,
        this.state.address,
        this.state.phone
      )
        .then((res) => {
          self.closeModal();
          if (self.props.onChange) {
            self.props.onChange();
          }
        })
        .catch((error) => {
          self.setState({ errorMessage: "خطا في حفظ البيانات. الرجاء المحاولة مرة اخرى" });
        });
    } else {
      self.setState({ errorMessage: "الرجاء ملء الحقول المطلوبة." });
    }
  };

  validateForm = () => {
    let isValid = true;
    if (!this.state.name) {
      isValid = false;
    }
    return isValid;
  };

  render() {
    const { name, showError, errorMessage, address, city, phone } = this.state;

    return (
      <>
        {this.props.isEdit && <span onClick={(e) => this.openModal(e)}>تعديل</span>}
        {!this.props.isEdit && (
          <Button color="success" type="button" size="sm" onClick={(e) => this.openModal(e)}>
            تحديث بيانات الشركة <i className="fas fa-edit" />
          </Button>
        )}

        <Modal className="modal-dialog-centered modal-lg" isOpen={this.state.isOpen} toggle={() => this.closeModal()}>
          <div className="modal-header border-bottom">
            <h3 className="modal-title">{this.props.title}</h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.closeModal()}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Form>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="6">
                    <FormGroup>
                      <label className="form-control-label">اسم الشركة</label>
                      <Input
                        className={`form-control ${showError && !name ? "is-invalid" : ""}`}
                        type="text"
                        value={name}
                        onChange={(e) => this.setState({ name: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="3">
                    <CitySelect defaultValue={city} onChange={(e) => this.setState({ city: e.target.value })} />
                  </Col>
                  <Col lg="3">
                    <FormGroup>
                      <label className="form-control-label">هاتف</label>
                      <Input
                        className="form-control"
                        type="text"
                        value={phone}
                        onChange={(e) => this.setState({ phone: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <label className="form-control-label">العنوان</label>
                      <Input
                        className="form-control"
                        type="text"
                        value={address}
                        onChange={(e) => this.setState({ address: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
          <div className="modal-footer border-top">
            <span className="text-danger ml-auto">{errorMessage}</span>
            <Button color="secondary" data-dismiss="modal" type="button" onClick={() => this.closeModal()}>
              اغلاق
            </Button>
            <Button color="success" type="button" onClick={this.handelSubmit}>
              حفظ
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default EditOrganizationhModal;
