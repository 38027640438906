import CitySelect from "components/Address/CitySelect";
import { Row, Col, FormGroup, Input } from "reactstrap";
import { DatePicker, Space } from "antd";
import { useEffect, useState } from "react";

const { RangePicker } = DatePicker;

const AreaFilters = (props) => {
  const [search, setSearch] = useState("");
  const [city, setCity] = useState("");
  const [status, setStatus] = useState("");

  useEffect(() => {
    props.onChange(search, city, status);
  }, [search, city, status]);

  return (
    <Row className="p-3">
      <Col lg="2">
        <FormGroup className="mb-0">
          <label className="form-control-label">بحث</label>
          <Input placeholder="رقم الطرد, الزبون, ...." type="text" onChange={(e) => setSearch(e.target.value)} />
        </FormGroup>
      </Col>
      <Col lg="2">
        <CitySelect emptyTitle="الكل" className="mb-0" onChange={(e) => setCity(e.target.value)} />
      </Col>
      <Col lg="2">
        <FormGroup className="m-0">
          <label className="form-control-label">الحالة</label>
          <select className="form-control" type="text" onChange={(e) => setStatus(e.target.value)}>
            <option value="">الكل</option>
            <option value="true">فعال</option>
            <option value="false">معطل</option>
          </select>
        </FormGroup>
      </Col>
    </Row>
  );
};

export default AreaFilters;
