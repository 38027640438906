import React from "react";
import { Button, Modal } from "reactstrap";

class DeleteCollectionInvoiceModel extends React.Component {
  render() {
    return (
      <Modal className="modal-dialog-centered" isOpen={this.props.isOpen} toggle={this.props.onClose}>
      <div className="modal-header pb-2">
        <h3 className="modal-title">هل أنت متأكد من حذف كشف تحصيل رقم {this.props.collectionInvoiceId} ؟</h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => this.props.onClose()}>
          <span aria-hidden={true}>×</span>
        </button>
      </div>

      <div className="modal-footer p-2 mt-3">
        <Button color="secondary" data-dismiss="modal" type="button" onClick={() => this.props.onClose()}>
          اغلاق
        </Button>
        <Button color="primary" type="button" onClick={() => this.props.onConfirmDeletion()}>
          حذف
        </Button>
      </div>
    </Modal>
    );
  }
}

export default DeleteCollectionInvoiceModel;
