import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import UtilityService from "service/utility";
import CustomersResult from "./CustomersResult";
import PackagesResult from "./PackagesResult";

const SearchResultModal = (props) => {
  const { customers, packages } = props.result;

  return (
    <div className={`search-result-modal bg-white border rounded p-4 ${props.open ? "open" : ""}`}>
      <Row className="pb-4 mb-2">
        <CustomersResult customers={customers} />

        <PackagesResult packages={packages} />
      </Row>
    </div>
  );
};

export default SearchResultModal;
