import React from "react";
import { Button, Col, Form, FormGroup, Input, Modal, Row } from "reactstrap";
import OrganizationService from "service/organization";

const defaultState = {
  firstName: "",
  lastName: "",
  mobile: "",
  email: "",
  gender: "MALE",
  organizationId: null,
  isAdmin: false,
  status: false,
};

class EditUserModal extends React.Component {
  state = {
    ...defaultState,
    isOpen: false,
    showError: false,
    validation: false,
    errorMessage: "",
  };

  closeModal = () => {
    this.setState({
      isOpen: false,
    });
  };

  openModal = () => {
    const user = this.props.user;
    if (user) {
      this.setState({
        isOpen: true,
        showError: false,
        validation: false,
        errorMessage: "",
        firstName: user.firstName,
        lastName: user.lastName,
        mobile: user.mobile,
        email: user.email,
        gender: "MALE",
        isAdmin: this.props.isOrgAdmin,
        status: user.status,
      });
    } else {
      this.setState({
        ...defaultState,
        isOpen: true,
        showError: false,
        validation: false,
        errorMessage: "",
      });
    }
  };

  handelSubmit = () => {
    this.setErrorMessage("");
    const self = this;
    if (this.validateForm()) {
      const { firstName, middleName, lastName, mobile, email, gender, isAdmin, status } = this.state;

      OrganizationService.updateUser(
        this.props.user.id,
        firstName,
        middleName,
        lastName,
        gender,
        mobile,
        email,
        isAdmin,
        status
      )
        .then((res) => {
          if (self.props.onChange) {
            self.props.onChange();
          }
          self.closeModal();
        })
        .catch((error) => {
          this.setErrorMessage("حدث خطا اثناء حفظ البيانات, يرجى المحاولة مرة اخرى");
        });
    }
  };

  validateForm = () => {
    this.setState({ validation: true });
    const { firstName, lastName, mobile } = this.state;
    let isValid = true;

    if (firstName.trim().length === 0 || lastName.trim().length === 0 || mobile.trim().length === 0) {
      isValid = false;
      this.setErrorMessage("الرجاء ادخال جميع الحقول المطلوبة.");
    }

    return isValid;
  };

  setErrorMessage = (message) => {
    this.setState({ errorMessage: message });
  };

  render() {
    const { errorMessage, validation, firstName, middleName, lastName, email, mobile, gender, isAdmin, status } =
      this.state;

    return (
      <>
        <span onClick={(e) => this.openModal(e)}>تعديل</span>

        <Modal className="modal-dialog-centered modal-lg" isOpen={this.state.isOpen} toggle={() => this.closeModal()}>
          <div className="modal-header border-bottom">
            <h3 className="modal-title">تعديل بيانات المستخدم</h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.closeModal()}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Form>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label">الاسم الاول</label>
                      <Input
                        className={`form-control ${validation && !firstName ? "is-invalid" : ""}`}
                        type="text"
                        value={firstName}
                        onChange={(e) => this.setState({ firstName: e.target.value.trim() })}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label">اسم الاب</label>
                      <Input
                        className="form-control"
                        type="text"
                        value={middleName}
                        onChange={(e) => this.setState({ middleName: e.target.value.trim() })}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label">الاسم الاخير</label>
                      <Input
                        className={`form-control ${validation && !lastName ? "is-invalid" : ""}`}
                        type="text"
                        value={lastName}
                        onChange={(e) => this.setState({ lastName: e.target.value.trim() })}
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label">الجنس</label>
                      <select
                        className="form-control"
                        value={gender}
                        onChange={(e) => this.setState({ gender: e.target.value })}>
                        <option value="MALE">ذكر</option>
                        <option value="FEMALE">انثى</option>
                      </select>
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label">رقم الموبايل</label>
                      <Input
                        className={`form-control ${validation && !mobile ? "is-invalid" : ""}`}
                        type="text"
                        value={mobile}
                        onChange={(e) => this.setState({ mobile: e.target.value.trim() })}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label">الايميل</label>
                      <Input
                        className="form-control"
                        type="email"
                        value={email}
                        onChange={(e) => this.setState({ email: e.target.value.trim() })}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg="12">
                    <Row>
                      {!this.props.isDriver && (
                        <Col lg="3">
                          <FormGroup className="mb-0">
                            <div className="custom-control custom-checkbox">
                              <input
                                className="custom-control-input"
                                id="isAdmin"
                                type="checkbox"
                                checked={isAdmin}
                                value={isAdmin}
                                onChange={(e) => this.setState({ isAdmin: e.target.checked })}
                              />
                              <label className="custom-control-label" htmlFor="isAdmin">
                                مسؤول اداري
                              </label>
                            </div>
                          </FormGroup>
                        </Col>
                      )}
                      <Col lg="3">
                        <FormGroup className="mb-0">
                          <div className="custom-control custom-checkbox">
                            <input
                              className="custom-control-input"
                              id="status"
                              type="checkbox"
                              checked={status}
                              value={status}
                              onChange={(e) => this.setState({ status: e.target.checked })}
                            />
                            <label className="custom-control-label" htmlFor="status">
                              فعال
                            </label>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
          <div className="modal-footer border-top">
            <span className="text-danger ml-auto">{errorMessage}</span>
            <Button color="secondary" data-dismiss="modal" type="button" onClick={() => this.closeModal()}>
              اغلاق
            </Button>
            <Button color="primary" type="button" onClick={this.handelSubmit}>
              حفظ
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default EditUserModal;
