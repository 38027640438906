import React from "react";
import { Button, Modal, FormGroup, Form, Input, Row, Col, InputGroup } from "reactstrap";
import CustomerService from "service/customer";
import NewCustomerModal from "./NewCustomerModal";
import UserAvatar from "components/UserAvatar";
import UtilityService from "service/utility";

class CustomerSearchModal extends React.Component {
  state = {
    isOpen: false,
    customerList: [],
    selectedCustomer: null,
  };

  openModal = () => {
    this.setState({
      isOpen: true,
    });
  };

  closeModal = () => {
    this.setState({
      isOpen: false,
    });
  };

  selectCustomer = (customer) => {
    this.setState({ selectedCustomer: customer });
  };

  handleSubmit = () => {
    if (this.props.onChange) {
      this.props.onChange(this.state.selectedCustomer);
      this.closeModal();
    }
  };

  handleChanged = (searchTerm) => {
    if (searchTerm.trim().length > 0) {
      CustomerService.findCustomer(searchTerm)
        .then((res) => {
          this.setState({ customerList: res.data.customers });
        })
        .catch((error) => {
          this.setState({ customerList: [] });
        });
    } else {
      this.setState({ customerList: [] });
    }
  };

  render() {
    const { customerList, selectedCustomer, isOpen } = this.state;
    return (
      <>
        <Button color="primary" type="button" onClick={() => this.openModal()}>
          اختر زبون
        </Button>

        <Modal
          className="modal-dialog-centered modal-lg customer-serach-modal"
          isOpen={isOpen}
          toggle={() => this.closeModal()}>
          <div className="modal-header border-bottom">
            <h3 className="modal-title">اختيار زبون</h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.closeModal()}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Form>
              <div>
                <Row>
                  <Col lg="10">
                    <FormGroup>
                      <InputGroup className="mb-4">
                        <Input
                          placeholder="بحث حسب الاسم او رقم الموبايل"
                          type="text"
                          onChange={(e) => {
                            this.handleChanged(e.target.value);
                          }}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                  <Col lg="2">
                    <NewCustomerModal />
                  </Col>
                </Row>
                <Row className="customer-list p-3">
                  {customerList &&
                    customerList.map((customer) => {
                      const selected = selectedCustomer && selectedCustomer.customerId == customer.customerId;
                      const address = customer.address;
                      return (
                        <Col
                          key={customer.customerId}
                          lg="6"
                          className={`d-flex p-3 customer-box ${selected ? "selected" : ""}`}
                          onClick={() => this.selectCustomer(customer)}>
                          <div>
                            {selected && (
                              <div className="icon icon-shape rounded-circle text-white bg-success shadow text-uppercase font-weight-bold">
                                <i className="fas fa-check"></i>
                              </div>
                            )}
                            {!selected && <UserAvatar photo={customer.photo} />}
                          </div>
                          <div className="mr-4">
                            <p className="m-0 font-weight-bold">
                              {customer.firstName || ""} {customer.middleName || ""} {customer.lastName || ""}
                              <span className="badge badge-primary text-dark">{customer.mobile}</span>
                            </p>

                            <p className="m-0">
                              {address && UtilityService.formatAddress(address)}
                              {!address && <span>العنوان غير متوفر</span>}
                            </p>
                          </div>
                        </Col>
                      );
                    })}
                  {customerList && customerList.length === 0 && (
                    <span>لم تيم العثور على اي زبون. يرجى المحاولة مرة اخرى</span>
                  )}
                </Row>
              </div>
            </Form>
          </div>
          <div className="modal-footer border-top">
            <Button color="secondary" data-dismiss="modal" type="button" onClick={() => this.closeModal()}>
              اغلاق
            </Button>
            <Button color="primary" type="button" onClick={() => this.handleSubmit()}>
              حفظ
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default CustomerSearchModal;
