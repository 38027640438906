import { Card, CardBody, CardHeader, Container, Row, Table } from "reactstrap";

import CreateNewUserModal from "components/Modals/CreateNewUserModal";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import OrganizationService from "service/organization";
import UtilityService from "service/utility";
import UsersActions from "views/organization/UsersActions";

const UserManagment = (props) => {
  const [userList, setUserList] = useState([]);

  useEffect(() => {
    populateUserList();
  }, [props]);

  const populateUserList = () => {
    trackPromise(
      OrganizationService.getUserList().then((res) => {
        setUserList(res.data);
      })
    );
  };

  const onDisableUser = (userId) => {
    trackPromise(
      OrganizationService.updateUserStatus(userId, false).then((res) => {
        populateUserList();
      })
    );
  };

  const onEnableUser = (userId) => {
    trackPromise(
      OrganizationService.updateUserStatus(userId, true).then((res) => {
        populateUserList();
      })
    );
  };

  return (
    <>
      <Container className="pt-2 pt-md-8" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">المستخدمين</h3>
                  </div>
                  <div className="col d-flex justify-content-end align-items-center p-0">
                    <i className="fas fa-redo mx-4" role="button" onClick={populateUserList}></i>
                    <CreateNewUserModal onChange={populateUserList} />
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="p-0">
                <Row className="m-0">
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th className="px-4">#</th>
                        <th>تايخ الانشاء</th>
                        <th>اسم المستخدم</th>
                        <th>الاسم</th>
                        <th>رقم الموبايل</th>
                        <th>البريد الالكتروني</th>
                        <th>الحالة</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {userList.map(function (orgUser) {
                        const user = orgUser.user;
                        const isOrgAdmin = orgUser.admin;

                        return (
                          <tr key={user.id}>
                            <td className="px-4">{user.id}</td>
                            <td>{UtilityService.formatTimestamp(user.created)}</td>
                            <td>
                              {user.userName}
                              {orgUser.admin && <span className="badge badge-success mx-2">مسؤول إداري</span>}
                            </td>
                            <td>
                              {user.firstName} {user.middleName} {user.lastName}
                            </td>
                            <td>{user.mobile}</td>
                            <td>{user.email}</td>
                            <td>{UtilityService.renderActiveOrInactiveStatus(user.status)}</td>
                            <td>
                              <UsersActions
                                user={user}
                                isOrgAdmin={isOrgAdmin}
                                onDisableUser={() => onDisableUser(user.id)}
                                onEnableUser={() => onEnableUser(user.id)}
                                onEditUser={() => populateUserList()}
                              />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default UserManagment;
