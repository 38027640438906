import { Dropdown } from "antd";
import EditBranchModal from "components/Modals/EditBranchModal";

const BranchActions = (props) => {
  const branch = props.branch;

  const handleMenuClick = (e) => {
    switch (e.key) {
      case "ENABLE_BRANCH":
        props.onEnableBranch();
        break;
      case "DISABLE_BRANCH":
        props.onDisableBranch();
        break;
      default:
        break;
    }
  };

  const actions = [
    {
      label: <EditBranchModal title="تعديل بيانات الفرع" branch={branch} onChange={props.onEditBranch} />,
      key: "EDIT_BRANCH",
      icon: <i className="fas fa-edit" />,
    },
    {
      label: "تعطيل",
      key: "DISABLE_BRANCH",
      icon: <i className="fas fa-times" />,
      disabled: !branch.status,
    },
    {
      label: "تفعيل",
      key: "ENABLE_BRANCH",
      icon: <i className="fas fa-check" />,
      disabled: branch.status,
    },
  ];

  return (
    <Dropdown.Button trigger={["click"]} menu={{ items: actions, onClick: handleMenuClick }}>
      خيارات
    </Dropdown.Button>
  );
};

export default BranchActions;
