import PriceListAPI from "api/priceList";

const PriceListService = {
  getAllPriceList: function () {
    return PriceListAPI.getAllPriceList();
  },
  resolvePrice: function (orgId, areaCode) {
    return PriceListAPI.resolvePrice(orgId, areaCode);
  },
};

export default PriceListService;
