import APIClient from "./axios.js";
import { GET_ALL_PRIICE_LIST_API_URL, RESOLVE_PRIICE_LIST_API_URL } from "../variables/constant";

const PriceListAPI = {
  getAllPriceList: function () {
    return APIClient.get(GET_ALL_PRIICE_LIST_API_URL);
  },
  resolvePrice: function (orgId, areaCode) {
    return APIClient.get(
      RESOLVE_PRIICE_LIST_API_URL.replace("{organizationId}", orgId).replace("{areaCode}", areaCode)
    );
  },
};

export default PriceListAPI;
