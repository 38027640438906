import React from "react";
import { Button, FormGroup, Modal } from "reactstrap";

class UpdateDeliveryCost extends React.Component {

    state = {
        newValue: null,
        errorMessage: "",
    };
    handleChange = (event) => {
        this.setState({ newValue: event.target.value });
    };

    handleSubmit = () => {
        this.setState({ errorMessage: "" });
        if (this.state.newValue) {
            this.props.onChange(this.state.newValue);
            this.setState({ newValue: null });
        } else {
            this.setState({ errorMessage: "الرجاء ادخال قيمة التوصيل الجديدة" });
        }
    };
    handleCloseModal = () => {
        this.setState({ newValue: null });
        this.setState({ errorMessage: "" });
        this.props.onClose();
    };

    render() {
        return (
            <Modal className="modal-dialog-centered" isOpen={this.props.isOpen} toggle={this.handleCloseModal}>
                <div className="modal-body pb-0">
                    <div justify-content="center">
                        <div>
                            <div>
                                <FormGroup className="mb-2">
                                    <label className="form-control-label">الرجاء ادخال قيمة التوصيل الجديدة :</label>
                                    <input
                                        type="number"
                                        value={this.state.newValue}
                                        onChange={this.handleChange}
                                        placeholder="-- ادخل القيمة --"
                                        className="form-control"
                                    />
                                </FormGroup>
                            </div>
                            <p className="text-danger mb-0 mt-2">&nbsp;{this.state.errorMessage}</p>
                        </div>
                    </div>
                </div>
                <div className="modal-footer pt-0">
                    <Button color="primary" type="button" className="w-100" onClick={this.handleSubmit}>
                        حفظ
                    </Button>
                </div>
            </Modal>
        );
    }
}

export default UpdateDeliveryCost;
