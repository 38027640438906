import { useState } from "react";
import { FormGroup } from "reactstrap";

const BranchSelect = (props) => {
  const [branchId, setBranchId] = useState(props.defaultValue || "");
  const branchList = props.branchList || [];

  const handleChange = (e) => {
    setBranchId(e.target.value);
    if (props.onChange) {
      props.onChange(branchList.find((b) => b.id == e.target.value));
    }
  };

  return (
    <FormGroup className={props.inline ? "d-flex align-items-center mb-0" : ""}>
      <label className={`form-control-label ${props.inline ? "mb-0" : ""}`}>الفرع:</label>
      <select
        type="text"
        value={branchId}
        onChange={handleChange}
        disabled={branchList.length === 0}
        className={`form-control ${props.showError && !branchId ? "is-invalid" : ""} ${props.inline ? "mr-2" : ""}`}>
        <option value=""></option>
        {branchList.map((branch) => {
          return (
            <option key={branch.id} value={branch.id}>
              {branch.name}
            </option>
          );
        })}
      </select>
    </FormGroup>
  );
};

export default BranchSelect;
