import ChatDetails from "./ChatDetails";
import ChatList from "./ChatList";

const Index = (props) => {
  const searchParams = new URLSearchParams(document.location.search);

  return (
    <>
      <div className="chat-list-page">
        <div className="chat-list-details">
          <ChatDetails userId={searchParams.get("userId")} />
        </div>
        <div className="chat-list-navigation">
          <ChatList selectedUserId={searchParams.get("userId")} />
        </div>
      </div>
    </>
  );
};

export default Index;
