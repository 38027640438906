import React from "react";
import { Button, FormGroup, Input, Modal } from "reactstrap";
import PackageService from "service/package";

class LinkToExternalSystemModal extends React.Component {
  state = {
    errorMessage: "",
    externalSystemName: "",
    externalSystemRefrences: "",
    externalSystemURL: "",
  };

  closeModal = () => {
    this.setState({
      errorMessage: "",
      externalSystemName: "",
      externalSystemRefrences: "",
      externalSystemURL: "",
    });
    this.props.onClose();
  };

  handleSubmit = () => {
    this.setState({ errorMessage: "" });
    const self = this;
    if (this.state.externalSystemName && this.state.externalSystemRefrences) {
      PackageService.linkPackageToExternalSystem(this.props.packageId, {
        externalSystem: this.state.externalSystemName,
        externalNumber: this.state.externalSystemRefrences,
        externalURL: this.state.externalSystemURL,
      })
        .then((res) => {
          if (self.props.onChanged) {
            self.props.onChanged();
          }
          self.closeModal();
        })
        .catch(() => {
          this.setState({ errorMessage: "ناسف, فشل في حفظ البيانات. الرجاء المحاولة مرة اخرى لاحقا." });
        });
    } else {
      this.setState({ errorMessage: "الرجاء ملى الحقول المطلوبة" });
    }
  };

  render() {
    const {externalSystemName, externalSystemRefrences, externalSystemURL } = this.state;
    return (
      <>
        <Modal className="modal-dialog-centered" isOpen={this.props.isOpen} toggle={() => this.closeModal()}>
          <div className="modal-body pb-0">
            <div justify-content="center">
              <div>
                <div>
                  <FormGroup className="mb-2">
                    <label className="form-control-label">النظام الخارجي:</label>
                    <select
                      value={externalSystemName}
                      className="form-control"
                      onChange={(e) => {
                        this.setState({ externalSystemName: e.target.value });
                      }}>
                      <option value=""></option>
                      <option value="KANGAROO">Kangaroo</option>
                      <option value="SEKKA">Sekka</option>
                      <option value="ع . م للتوصيل / ע . מ משלוחים">ع . م للتوصيل / ע . מ משלוחים</option>
                    </select>
                  </FormGroup>
                </div>
                <div>
                  <FormGroup className="mb-2">
                    <label className="form-control-label">الرمز المرجعي:</label>
                    <Input
                      className="form-control"
                      type="text"
                      value={externalSystemRefrences}
                      onChange={(e) => {
                        this.setState({ externalSystemRefrences: e.target.value });
                      }}
                    />
                  </FormGroup>
                </div>
                <div>
                  <FormGroup className="mb-2">
                    <label className="form-control-label">الرابط:</label>
                    <Input
                      className="form-control"
                      type="text"
                      value={externalSystemURL}
                      onChange={(e) => {
                        this.setState({ externalSystemURL: e.target.value });
                      }}
                    />
                  </FormGroup>
                </div>
                <p className="text-danger mb-0 mt-2">&nbsp;{this.state.errorMessage}</p>
              </div>
            </div>
          </div>

          <div className="modal-footer pt-0">
            <Button color="primary" type="button" className="w-100" onClick={this.handleSubmit}>
              حفظ
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default LinkToExternalSystemModal;
