import { Container, Row, Col } from 'reactstrap';

const UserHeader = (props) => {
  return (
    <>
      <div
        className="header pb-8 pt-5 pt-lg-8 d-flex align-items-center"
        style={{
          minHeight: '300px',
          backgroundImage: 'url(' + require('../../assets/img/theme/profile-cover.jpg') + ')',
          backgroundSize: 'cover',
          backgroundPosition: 'center top',
        }}>
        {/* Mask */}
        <span className="mask bg-gradient-default opacity-8" />
        {/* Header container */}
        <Container className="d-flex align-items-center" fluid>
          <Row>
            <Col lg="12" md="12">
              <h1 className="display-2 text-white">
                {props.user.firstName} {props.user.middleName} {props.user.lastName}
              </h1>
              <p className="text-white mt-0 mb-5">{props.user.userName}</p>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default UserHeader;
