import { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Container, Row, Table } from "reactstrap";
import PriceListService from "service/priceList";
import UtilityService from "service/utility";
import PriceListFilter from "./PriceListFilter";
import PriceTestModal from "./PriceTestModal";

const PriceListManagment = (props) => {
  const [priceList, setPriceList] = useState([]);
  const [filters, setFilters] = useState({ search: "", city: "", status: "all" });

  useEffect(() => {
    populatePriceList();
  }, [props]);

  const populatePriceList = () => {
    PriceListService.getAllPriceList().then((res) => setPriceList(res.data));
  };

  const filterAreaList = (price) => {
    let valid = true;
    const area = price.area;
    if (
      filters.search &&
      area.arabicName.toLocaleLowerCase().indexOf(filters.search.toLocaleLowerCase()) === -1 &&
      area.englishName.toLocaleLowerCase().indexOf(filters.search.toLocaleLowerCase()) === -1 &&
      area.code.toLocaleLowerCase().indexOf(filters.search.toLocaleLowerCase()) === -1
    ) {
      valid = false;
    }

    if (filters.city && area.city.toLocaleLowerCase().indexOf(filters.city.toLocaleLowerCase()) === -1) {
      valid = false;
    }

    if (
      filters.status &&
      price.enabled.toString().toLocaleLowerCase().indexOf(filters.status.toLocaleLowerCase()) === -1
    ) {
      valid = false;
    }

    return valid;
  };

  return (
    <>
      <Container className="pt-2 pt-md-8" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">قائمة الاسعار</h3>
                  </div>
                  <div className="col d-flex justify-content-end align-items-center">
                    <PriceTestModal />
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="p-0">
                <PriceListFilter
                  onChange={(search, city, status) => {
                    setFilters({ search, city, status });
                  }}
                />
                <Row className="m-0">
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th className="px-4">#</th>
                        <th>الاسم</th>
                        <th>المنطقة</th>
                        <th>المدينة</th>
                        <th>التكلفة</th>
                        <th>الاولوية</th>
                        <th>الحالة</th>
                      </tr>
                    </thead>
                    <tbody>
                      {priceList &&
                        priceList.filter(filterAreaList).map(function (price) {
                          return (
                            <tr key={price.id}>
                              <th className="px-4">{price.id}</th>
                              <td>{price.name}</td>
                              <td>{price.area ? price.area.arabicName + " (" + price.area.code + ")" : "-"}</td>
                              <td>{price.area?.city}</td>
                              <td>{UtilityService.formatPrice(price.cost)}</td>
                              <td>{price.precedence}</td>
                              <td>
                                <span className={price.enabled ? "badge badge-success" : "badge badge-danger"}>
                                  {price.enabled ? "نشط" : "غير نشط"}
                                </span>
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default PriceListManagment;
