const UserAvatar = (props) => {
  const userImage = props.photo;
  const bgColor = "bg-" + (props.bgColor ? props.bgColor : "primary");
  const textColor = "text-" + (props.textColor ? props.textColor : "white");

  return (
    <>
      {userImage && (
        <div
          className="avatar"
          style={{ width: props.width ? props.width : 48, height: props.height ? props.height : 48 }}>
          <img
            alt="..."
            className="rounded-circle"
            style={{ width: props.width ? props.width : 48, height: props.height ? props.height : 48 }}
            src={userImage}
          />
        </div>
      )}
      {!userImage && (
        <div
          className={`icon icon-shape ${bgColor} ${textColor} rounded-circle shadow text-uppercase font-weight-bold`}>
          <i className="fas fa-user"></i>
        </div>
      )}
    </>
  );
};

export default UserAvatar;
