import { Col, Row } from "reactstrap";

const OrganizationInfo = (props) => {
  const { organization } = props;
  let phone = "";
  if (organization && organization.branch && organization.branch.phone) {
    phone = organization.branch.phone;
  } else if (organization && organization.phone) {
    phone = organization.phone;
  }

  return (
    <Row className="m-0">
      <Col lg="12">
        <h3>معلومات التاجر</h3>
      </Col>
      <Col className="col-12 mt-2 d-flex">
        <div>
          <p className="m-0 font-weight-bold">
            اسم الشركة / التاجر: <span className="font-weight-normal">{organization.name}</span>
          </p>
          <p className="m-0 mt-2 font-weight-bold">
            تلفون : <span className="font-weight-normal">{organization.branch?.phone}</span>
          </p>
        </div>
        <div className="mr-4 border-right pr-4">
          <p className="m-0 font-weight-bold">
            الفرع: <span className="font-weight-normal">{organization.branch?.name}</span>
          </p>
          <p className="m-0 mt-2 font-weight-bold">
            العنوان : <span className="font-weight-normal">{organization.branch?.address}</span>
          </p>
        </div>
        <div className="d-flex mr-auto">
          <div className="mr-4 d-flex">
            <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
              <i className="fas fa-comment-dots"></i>
            </div>
          </div>
          {phone && (
            <div className="mr-4 d-flex">
              <a href={`tel:${phone}`}>
                <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
                  <i className="fas fa-phone"></i>
                </div>
              </a>
            </div>
          )}
        </div>
      </Col>
    </Row>
  );
};

export default OrganizationInfo;
