import {
  MRT_GlobalFilterTextField,
  MRT_ShowHideColumnsButton,
  MRT_ToggleFullScreenButton,
  MRT_ToggleGlobalFilterButton,
} from "material-react-table";

import { Box, lighten } from "@mui/material";

const CollectionTableTopBar = (props) => {
  const { table } = props;

  return (
    <Box
      sx={(theme) => ({
        backgroundColor: lighten(theme.palette.background.default, 0.05),
        display: "flex",
        gap: "0.5rem",
        p: "8px",
        justifyContent: "space-between",
      })}>
      <Box sx={{ display: "flex", gap: "0.5rem", alignItems: "center" }}>
        {/* import MRT sub-components */}
        <MRT_ToggleFullScreenButton table={table} />
        <MRT_ShowHideColumnsButton table={table} />
        <MRT_ToggleGlobalFilterButton table={table} />
        <MRT_GlobalFilterTextField table={table} />
      </Box>
    </Box>
  );
};

export default CollectionTableTopBar;
