import { MessageAPI } from "api/message";

const MessageService = {
  gerMessageSummary: function (pageSize) {
    return MessageAPI.gerMessageSummary({ pageSize });
  },
  getMessageList: function (userId, pageSize, pageNumber) {
    return MessageAPI.gerMessageList({ userId, pageSize, pageNumber });
  },
  sendMessage: function (userId, message) {
    return MessageAPI.sendMessage({ userId, message });
  },
};

export default MessageService;
