import CreateNewBranchModal from "components/Modals/CreateNewBranchModal";
import { trackPromise } from "react-promise-tracker";
import { Card, CardBody, CardHeader, Row, Table } from "reactstrap";
import OrganizationService from "service/organization";
import UtilityService from "service/utility";
import BranchActions from "../BranchActions";

const BrachList = (props) => {
  const { organization, onRefresh } = props;
  const branchList = organization?.branchList || [];

  const onDisableBranch = (branchId) => {
    trackPromise(
      OrganizationService.updateBranchStatus(branchId, false).then((res) => {
        onRefresh();
      })
    );
  };

  const onEnableBranch = (branchId) => {
    trackPromise(
      OrganizationService.updateBranchStatus(branchId, true).then((res) => {
        onRefresh();
      })
    );
  };

  const onEditBranch = () => {
    onRefresh();
  };

  return (
    <>
      <Card className="shadow">
        <CardHeader className="bg-transparent">
          <Row className="align-items-center">
            <div className="col">
              <h3 className="mb-0">الفروع</h3>
            </div>
            <div className="col text-left d-flex justify-content-end align-items-center">
              <CreateNewBranchModal title="انشاء فرع جديد" organizationId={organization.id} onChange={onRefresh} />
            </div>
          </Row>
        </CardHeader>
        <CardBody className="p-0">
          <Row className="m-0">
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th className="pr-4">#</th>
                  <th>الاسم</th>
                  <th>تايخ الانشاء</th>
                  <th>العنوان</th>
                  <th>هاتف</th>
                  <th>الحالة</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {branchList.map(function (branch) {
                  return (
                    <tr key={branch.id}>
                      <td className="pr-4">{branch.id}</td>
                      <td>{branch.name}</td>
                      <td>{UtilityService.formatTimestamp(branch.created)}</td>
                      <td>
                        {UtilityService.renderCity(branch.city)}, {branch.address}
                      </td>
                      <td>{branch.phone}</td>
                      <td>{UtilityService.renderActiveOrInactiveStatus(branch.status)}</td>
                      <td>
                        <BranchActions
                          branch={branch}
                          onDisableBranch={() => onDisableBranch(branch.id)}
                          onEnableBranch={() => onEnableBranch(branch.id)}
                          onEditBranch={onEditBranch}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default BrachList;
