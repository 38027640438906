import AreaAPI from "api/area";

const AddressService = {
  getAreaByCity: function (city) {
    return AreaAPI.getAreaByCity(city);
  },
  getAllArea: function () {
    return AreaAPI.getAllArea();
  },
};

export default AddressService;
