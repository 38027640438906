import { useMemo, useState, useEffect } from "react";

import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";

//Date Picker Imports - these should just be in your Context Provider
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";

import { MRT_Localization_AR } from "material-react-table/locales/ar/index.js";

import { ThemeProvider, createTheme, useTheme } from "@mui/material";
import { arSA } from "@mui/material/locale";

import CustomerTableTopBar from "./CustomerTableTopBar";
import { Rating } from 'react-simple-star-rating'

const Table = (props) => {
  const { data, totalCustomers } = props;
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  useEffect(() => {
    //when the pagination state changes
    props.onDataChange({ pageNumber: pagination.pageIndex, pageSize: pagination.pageSize });
  }, [pagination.pageIndex, pagination.pageSize]);

  const roundRating = (totalDeliveredPackage, totalReturnedPackage) => {
    const RatingNumber = (totalDeliveredPackage / (totalDeliveredPackage + totalReturnedPackage) * 100 / 20).toFixed(1);
    const intNumber = Math.floor(RatingNumber);
    const decimalNumber = RatingNumber - intNumber;

    if (decimalNumber < 0.25)
      return intNumber;
    else if (decimalNumber >= 0.25 && decimalNumber < 0.75)
      return intNumber + 0.5;
    else
      return intNumber + 1;
  }

  const columns = useMemo(
    () => [
      {
        accessorFn: (originalRow) =>
          `${originalRow.firstName} 
           ${originalRow.middleName ? originalRow.middleName : ""}
           ${originalRow.lastName ? originalRow.lastName : ""}`,
        id: "customerName",
        header: "اسم المستقبل",
        size: 280,
      },
      {
        accessorFn: (originalRow) => originalRow.mobile,
        id: "customerMobile",
        header: "هاتف المستقبل",
        size: 250,
      },
      {
        accessorFn: (originalRow) => originalRow.created ? new Date(originalRow.created) : "",
        header: "تاريخ الإنشاء",
        size: 250,
        Cell: ({ cell }) => cell.getValue() ? cell.getValue().toLocaleDateString() : "",
      },
      {
        accessorFn: (originalRow) => originalRow.totalDeliveredPackage,
        header: "تم توصيلها",
        size: 250,
      },
      {
        accessorFn: (originalRow) => originalRow.totalReturnedPackage,
        header: "مرجعة",
        size: 250,
      },
      {
        accessorFn: (originalRow) => roundRating(originalRow.totalDeliveredPackage, originalRow.totalReturnedPackage),
        header: " التقيم",
        size: 250,
        Cell: ({ cell }) => (
          <Rating
            initialValue={cell.getValue()}
            size={28}
            fillColor={"#fb9550"}
            readonly
            allowFraction

          />),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data,
    pageIndex: pagination.pageNumber,
    enableColumnResizing: true,
    columnResizeDirection: "rtl",
    enableRowSelection: true,
    enableRowActions: false,
    initialState: {
      showColumnFilters: false,
      columnPinning: { right: ["mrt-row-actions"] },
      pagination: { pageSize: 10, pageIndex: 0 },
    },
    localization: { ...MRT_Localization_AR },
    enableFullScreenToggle: true,
    muiSearchTextFieldProps: {
      size: "small",
      variant: "outlined",
    },
    enableSorting: false,
    enablePagination: true,
    positionToolbarAlertBanner: "head-overlay",

    manualPagination: true,
    rowCount: totalCustomers,
    onPaginationChange: setPagination,
    state: { pagination }, //pass the pagination state to the table
    muiTableBodyCellProps: { align: "right" },
    renderTopToolbar: CustomerTableTopBar,
  });

  return <MaterialReactTable table={table} />;
};

const CustomerTable = (props) => {
  const theme = useTheme(); //replace with your theme/createTheme

  // define direction for table to solve pagination Icons is in wrong direction
  const direction = { direction: "rtl" };

  return (
    //Setting Material UI locale as best practice to result in better accessibility
    <ThemeProvider theme={createTheme(theme, arSA, direction)}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Table data={props.data} totalCustomers={props.totalCustomers} onDataChange={props.onDataChange} />
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default CustomerTable;
