import { Link } from "react-router-dom";
import UtilityService from "service/utility";

const PackageIdentifier = (props) => {
  const packageDetails = props.package;

  return (
    <div className="d-flex flex-column align-items-start" style={{ gap: 5 }}>
      <Link className="text-dark" to={"/packages/" + packageDetails.id}>
        {UtilityService.formatPackageId(packageDetails.id)}
      </Link>
      {UtilityService.isAdmin() && packageDetails.externalSystem && (
        <span className="badge badge-danger text-dark">
          {packageDetails.externalSystem} - {packageDetails.externalNumber}
        </span>
      )}
    </div>
  );
};

export default PackageIdentifier;
