import { useState } from "react";
import { Container } from "reactstrap";
import PackageService from "service/package";
import HistoryPackageTable from "./HistoryPackageTable";

const PackagesHistory = (props) => {
  const [packageList, setPackageList] = useState([]);
  const [totalPackage, setTotalPackage] = useState([]);

  const processFilters = (props) => {
    const filters = {
      searchTerm: props.globalFilter,

      // react table pagination : (pageIndex, pageSize), backend pagination (API): (pageNumber, pageSize)
      pageSize: props.pagination.pageSize,
      pageNumber: props.pagination.pageIndex,
      ...props.columnFilters.reduce((acc, { id, value }) => {
        acc[id] = value;
        return acc;
      }, {}),
    };
    if (filters.status) {
      filters.status = filters.status.join(",");
    }
    return filters;
  };

  const populatePackgeHistoryList = (props) => {
    const filters = processFilters(props);
    PackageService.getPackageHistoryList({ ...filters }).then((res) => {
      setTotalPackage(res.data.totalPackages);
      setPackageList(res.data.packageList);
    });
  };

  return (
    <>
      <Container className="pt-2 pt-md-8" fluid>
        {packageList && (
          <HistoryPackageTable
            data={packageList}
            totalPackage={totalPackage}
            onDataChange={populatePackgeHistoryList}
          />
        )}
        <p></p>
      </Container>
    </>
  );
};

export default PackagesHistory;
