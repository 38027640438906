import "@fortawesome/fontawesome-free/css/all.min.css";
import "assets/plugins/nucleo/css/nucleo.css";
import "assets/scss/argon-dashboard-react.scss";
import ReactDOM from "react-dom/client";
import { usePromiseTracker } from "react-promise-tracker";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import AdminLayout from "./layouts/Admin.js";
import AuthLayout from "./layouts/Auth.js";
import FullPageLayout from "./layouts/FullPage.js";
import PrivateRoute from "./privetRoute";

import { RingSpinnerOverlay } from "react-spinner-overlay";

const root = ReactDOM.createRoot(document.getElementById("root"));

const LoadingIndicator = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  return promiseInProgress && <RingSpinnerOverlay loading={true} size={100} color="#FE6F2F" borderWidth="4" />;
};

root.render(
  <>
    <BrowserRouter>
      <Routes>
        <Route path="/auth/*" element={<AuthLayout />} />

        <Route path="/not-found/*" exact element={<FullPageLayout />} />

        <Route path="*" element={<PrivateRoute />}>
          <Route path="*" element={<AdminLayout />} />
        </Route>
      </Routes>
    </BrowserRouter>
    <LoadingIndicator />
  </>
);
