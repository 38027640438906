import { useEffect, useState } from "react";
import { Card, CardHeader, Row, Progress, Table } from "reactstrap";
import StatisticService from "service/statistic";
import UtilityService from "service/utility";
import { SUPPORTED_CITY } from "variables/cities";

const colorClasses = ["danger", "success", "warning", "primary", "info", "secondary", "dark"];

const CustomerStatistic = (props) => {
  const [statisticData, setStatisticData] = useState([]);
  const [totalCustomer, setTotalCustomer] = useState(0);

  useEffect(() => {
    StatisticService.getCustomerStatistic().then((res) => {
      setStatisticData(res.data);
      const sum = res.data.reduce((accumulator, object) => {
        return accumulator + object.totalCustomer;
      }, 0);
      setTotalCustomer(sum);
    });
  }, [props]);

  const data = statisticData.reduce((result, obj) => {
    const city = obj.city;

    // Check if a group for the city already exists
    if (!result[city]) {
      result[city] = { city: city, totalCustomer: 0 };
    }

    // Push the object to the corresponding city group
    result[city].totalCustomer = obj.totalCustomer + result[city].totalCustomer;

    return result;
  }, []);

  // Adding missinc city with 0 value
  SUPPORTED_CITY.forEach((obj) => {
    const city = obj.key.toUpperCase();
    if (!data[city]) {
      data[city] = { city: city, totalCustomer: 0 };
    }
  });

  return (
    <Card className="shadow">
      <CardHeader className="border-0">
        <Row className="align-items-center">
          <div className="col">
            <h3 className="mb-0">نسبة الزبائن / مدينة</h3>
          </div>
        </Row>
      </CardHeader>
      <Table className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">المدينة</th>
            <th scope="col">عدد الزبائن</th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          {data &&
            Object.values(data).map((row, index) => {
              const percent = totalCustomer > 0 ? Math.round((row.totalCustomer / totalCustomer) * 100) : 0;
              return (
                <tr key={index}>
                  <th scope="row">{UtilityService.renderCity(row.city)}</th>
                  <td>{row.totalCustomer}</td>
                  <td>
                    <div className="d-flex align-items-center">
                      <span className="ml-2">{percent}%</span>
                      <div>
                        <Progress max="100" value={percent} barClassName={"bg-gradient-" + colorClasses[index]} />
                      </div>
                    </div>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </Card>
  );
};

export default CustomerStatistic;
