export const packageTransition = {
  N: ["M", "J"],
  M: ["J", "P"],
  P: ["A", "L"],
  A: ["O", "E", "G", "L"],
  O: ["V", "R", "D", "L"],
  V: ["R", "L"],
  E: ["O", "L"],
  R: ["C", "L"],
  C: ["X", "L"],
};
