import UserAPI from "api/user";

const UserService = {
  getCurrentUser: function () {
    return JSON.parse(localStorage.getItem("user"));
  },
  fetchCurrentUser: function () {
    return UserAPI.getCurrentUser();
  },
  updateCurrentUserProfile: function (firstName, middleName, lastName, email, mobile) {
    return UserAPI.updateCurrentUserProfile({ firstName, middleName, lastName, email, mobile });
  },
  updateCurrentUserPassword: function (oldPassword, newPassword) {
    return UserAPI.updateCurrentUserPassword({ oldPassword, newPassword });
  },
  updateCurrentUserPrimaryAddress: function (address, city, postalCode) {
    return UserAPI.updateCurrentUserPrimaryAddress({ address, city, postalCode });
  },
  getCurrentUserPrimaryAddress: function () {
    return UserAPI.getCurrentUserPrimaryAddress();
  },
};

export default UserService;
