import React from "react";
import { Button, Col, Form, FormGroup, Input, Modal, Row } from "reactstrap";
import DriverService from "service/driver";

const defaultState = {
  firstName: "",
  middleName: "",
  lastName: "",
  userName: "",
  mobile: "",
  email: "",
  password: "",
  gender: "MALE",
  confirmPassword: "",
};

class CreateNewDriverModal extends React.Component {
  state = {
    ...defaultState,
    isOpen: false,
    showError: false,
    validation: false,
    errorMessage: "",
  };

  closeModal = () => {
    this.setState({
      isOpen: false,
    });
  };

  openModal = () => {
    this.setState({
      ...defaultState,
      isOpen: true,
      showError: false,
      validation: false,
      errorMessage: "",
    });
  };

  handelSubmit = () => {
    this.setErrorMessage("");
    const self = this;
    if (this.validateForm()) {
      const { firstName, middleName, lastName, mobile, email, userName, gender, password } = this.state;

      DriverService.createNewDriver(firstName, middleName, lastName, userName, gender, mobile, email, password)
        .then((res) => {
          if (self.props.onChange) {
            self.props.onChange();
          }
          self.closeModal();
        })
        .catch((error) => {
          this.setErrorMessage("حدث خطا اثناء حفظ البيانات, يرجى المحاولة مرة اخرى");
        });
    }
  };

  validateForm = () => {
    this.setState({ validation: true });
    const { userName, firstName, lastName, password, confirmPassword, mobile } = this.state;
    let isValid = true;

    if (firstName.trim().length === 0 || lastName.trim().length === 0 || mobile.trim().length === 0) {
      isValid = false;
      this.setErrorMessage("الرجاء ادخال جميع الحقول المطلوبة.");
    } else if (userName.trim().length === 0 || password.trim().length === 0) {
      isValid = false;
      this.setErrorMessage("الرجاء ادخال جميع الحقول المطلوبة.");
    } else if (password !== confirmPassword) {
      isValid = false;
      this.setErrorMessage("كلمة المرور يجب ان تكون مطابقة.");
    }

    return isValid;
  };

  setErrorMessage = (message) => {
    this.setState({ errorMessage: message });
  };

  render() {
    const {
      errorMessage,
      validation,
      userName,
      firstName,
      middleName,
      lastName,
      password,
      confirmPassword,
      email,
      mobile,
      gender,
    } = this.state;

    return (
      <>
        <Button color="success" type="button" size="sm" onClick={(e) => this.openModal(e)}>
          سائق جديد
        </Button>

        <Modal className="modal-dialog-centered modal-lg" isOpen={this.state.isOpen} toggle={() => this.closeModal()}>
          <div className="modal-header border-bottom">
            <h3 className="modal-title">سائق جديد</h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.closeModal()}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Form>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="3">
                    <FormGroup>
                      <label className="form-control-label">الاسم الاول</label>
                      <Input
                        className={`form-control ${validation && !firstName ? "is-invalid" : ""}`}
                        type="text"
                        value={firstName}
                        onChange={(e) => this.setState({ firstName: e.target.value.trim() })}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="3">
                    <FormGroup>
                      <label className="form-control-label">اسم الاب</label>
                      <Input
                        className="form-control"
                        type="text"
                        value={middleName}
                        onChange={(e) => this.setState({ middleName: e.target.value.trim() })}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="3">
                    <FormGroup>
                      <label className="form-control-label">اسم العائلة</label>
                      <Input
                        className={`form-control ${validation && !lastName ? "is-invalid" : ""}`}
                        type="text"
                        value={lastName}
                        onChange={(e) => this.setState({ lastName: e.target.value.trim() })}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="3">
                    <FormGroup>
                      <label className="form-control-label">الجنس</label>
                      <select
                        className="form-control"
                        value={gender}
                        onChange={(e) => this.setState({ gender: e.target.value })}>
                        <option value="MALE">ذكر</option>
                        <option value="FEMALE">انثى</option>
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label">رقم الموبايل</label>
                      <Input
                        className={`form-control ${validation && !mobile ? "is-invalid" : ""}`}
                        type="text"
                        value={mobile}
                        onChange={(e) => this.setState({ mobile: e.target.value.trim() })}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label">الايميل</label>
                      <Input
                        className="form-control"
                        type="email"
                        value={email}
                        onChange={(e) => this.setState({ email: e.target.value.trim() })}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <hr className="col-12" />
                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label">اسم المستخدم</label>
                      <Input
                        className={`form-control ${validation && !userName ? "is-invalid" : ""}`}
                        type="text"
                        value={userName}
                        autoComplete="off"
                        onChange={(e) => this.setState({ userName: e.target.value.trim() })}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label">كلمة المرور</label>
                      <Input
                        className={`form-control ${
                          validation && (!password || password !== confirmPassword) ? "is-invalid" : ""
                        }`}
                        type="password"
                        autoComplete="off"
                        onChange={(e) => this.setState({ password: e.target.value.trim() })}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label">تاكيد كلمة المرور</label>
                      <Input
                        className={`form-control ${
                          validation && (!confirmPassword || password !== confirmPassword) ? "is-invalid" : ""
                        }`}
                        type="password"
                        autoComplete="off"
                        onChange={(e) => this.setState({ confirmPassword: e.target.value.trim() })}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
          <div className="modal-footer border-top">
            <span className="text-danger ml-auto">{errorMessage}</span>
            <Button color="secondary" data-dismiss="modal" type="button" onClick={() => this.closeModal()}>
              اغلاق
            </Button>
            <Button color="primary" type="button" onClick={this.handelSubmit}>
              حفظ
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default CreateNewDriverModal;
