import { Col, Container, Row } from "reactstrap";
import CustomerStatistic from "./CustomerStatistic";
import GeneralStatistic from "./GeneralStatistic";
import OrdersStatistic from "./OrdersStatistic";
import PackageByCityStatistic from "./PackageByCityStatistic";

const Index = (props) => {
  return (
    <>
      <Container className="pt-2 pt-md-8" fluid>
        <GeneralStatistic />

        <hr />

        <OrdersStatistic />

        <hr />

        <Row className="mt-5 mb-4">
          <Col className="mb-5 mb-xl-0" xl="4">
            <PackageByCityStatistic />
          </Col>
          <Col xl="4">
            <CustomerStatistic />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Index;
