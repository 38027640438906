import { useEffect, useState } from "react";
import { FormGroup } from "reactstrap";
import AddressService from "service/address";

const AreaSelect = (props) => {
  const [areaList, setAreaList] = useState([]);
  const [selectedAreaCode, setSelectedAreaCode] = useState(props.defaultValue || "");

  useEffect(() => {
    if (props.city) {
      AddressService.getAreaByCity(props.city).then((res) => {
        setAreaList(res.data);
      });
    }
  }, [props.city]);

  const handleChange = (e) => {
    setSelectedAreaCode(e.target.value);
    if (props.onChange) props.onChange(e);
  };

  return (
    <FormGroup>
      <label className="form-control-label">المنطقة</label>
      <select
        disabled={areaList.length === 0}
        className={`form-control ${props.showError && !selectedAreaCode ? "is-invalid" : ""}`}
        type="text"
        value={selectedAreaCode}
        onChange={handleChange}>
        <option value=""></option>
        {areaList.map((area) => {
          return (
            <option key={area.code} value={area.code}>
              {area.arabicName}
            </option>
          );
        })}
      </select>
    </FormGroup>
  );
};

export default AreaSelect;
