import { Dropdown } from "antd";
import AdminResetUserPassword from "components/Modals/AdminResetUserPassword";
import EditDriverModal from "components/Modals/drivers/EditDriverModal";

const DriverActions = (props) => {
  const driver = props.driver;

  const handleMenuClick = (e) => {
    switch (e.key) {
      case "ENABLE_USER":
        props.onEnableDriver();
        break;
      case "DISABLE_USER":
        props.onDisableDriver();
        break;
      default:
        break;
    }
  };

  const actions = [
    {
      label: <EditDriverModal driver={driver} onChange={props.onEditDriver} />,
      key: "EDIT_USER",
      icon: <i className="fas fa-edit" />,
    },
    {
      label: "تعطيل",
      key: "DISABLE_USER",
      icon: <i className="fas fa-times" />,
      disabled: !driver.status,
    },
    {
      label: "تفعيل",
      key: "ENABLE_USER",
      icon: <i className="fas fa-check" />,
      disabled: driver.status,
    },
    {
      label: <AdminResetUserPassword userId={driver.id} />,
      key: "REST_PASSWORD",
      icon: <i className="fas fa-key" />,
    },
  ];

  return (
    <Dropdown.Button trigger={["click"]} menu={{ items: actions, onClick: handleMenuClick }}>
      خيارات
    </Dropdown.Button>
  );
};

export default DriverActions;
