// reactstrap components
import { NavItem, NavLink, Nav, Container, Row, Col } from "reactstrap";

const Login = () => {
  return (
    <>
      <footer className="py-2">
        <Container>
          <Row className="text-center justify-content-center">
            <Col className="d-flex mb-4 col-md-7 col-lg-5">
              <div>
                <a href="https://apps.apple.com/il/app/ibiza/id6468534070" className="p-0" target="_blank">
                  <img className="brand-loog w-75" alt="..." src={require("../../assets/img/icons/apple.png")} />
                </a>
              </div>
              <div>
                <a href="https://play.google.com/store/apps/details?id=com.ibiza.app" className="p-0" target="_blank">
                  <img className="brand-loog w-75" alt="..." src={require("../../assets/img/icons/google.png")} />
                </a>
              </div>
            </Col>
            <Col xl="12 mb-3">
              <div className="copyright text-center text-white">
                {new Date().getFullYear()} ©{" "}
                <a className="font-weight-bold ml-1" href="https://www.ibiza.ps" target="_blank" rel="noreferrer">
                  Ibiza
                </a>
              </div>
            </Col>
            <Col xl="12">
              <Nav className="nav-footer justify-content-center p-0">
                <NavItem>
                  <NavLink href="https://www.ibiza.ps/terms" target="_blank">
                    شروط الاستخدام
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href="https://www.ibiza.ps/policy" target="_blank">
                    سياسة الخصوصية
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};

export default Login;
