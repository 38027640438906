import { Link } from "react-router-dom";
import { Col, Row } from "reactstrap";
import CustomerDisplay from "views/packages/CustomerDisplay";

const CustomersResult = (props) => {
  const { customers } = props;

  return (
    <Col lg="3" className="px-1">
      <h2 className="mb-3">
        <span className="pb-1 border-bottom border-primary">الزبائن</span>
      </h2>
      <Row>
        {customers.length === 0 && <Col lg="12">ناسف! لم يتم العثور عل اي نتائج.</Col>}
        {customers.map((customer) => (
          <Col lg="12" className="mb-4">
            <CustomerDisplay user={customer} address={customer.address} />
          </Col>
        ))}
      </Row>
      <div className="mt-1">
        <Link className="text-dark text-underline" to="/customers">
          جميع الزبائن...
        </Link>
      </div>
    </Col>
  );
};

export default CustomersResult;
