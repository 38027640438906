import PackageStatusSelect from "components/Package/PackageStatusSelect";
import React from "react";
import { Button, FormGroup, Modal } from "reactstrap";
import { MESSAGES } from "variables/messages";

class ChangeStatusModal extends React.Component {
  state = {
    status: null,
    errorMessage: "",
  };

  onStatusChange = (status) => {
    this.setState({ status: status });
  };

  handleSubmit = () => {
    this.setState({ errorMessage: "" });
    const self = this;
    if (this.state.status) {
      this.props.onChange(this.state.status);
    } else {
      this.setState({ errorMessage: "الرجاء اختيار الحالة" });
    }
  };

  renderStatusHint = () => {
    if (this.state.status) {
      const msg = MESSAGES["PACKAGE_STATUS_" + this.state.status];
      if (msg) {
        return msg.hint;
      }
    }
    return "";
  };

  render() {
    return (
      <Modal className="modal-dialog-centered" isOpen={this.props.isOpen} toggle={this.props.onClose}>
        <div className="modal-body pb-0">
          <div justify-content="center">
            <div>
              <div>
                <FormGroup className="mb-2">
                  <label className="form-control-label">الرجاء اختيار الحالة:</label>
                  <PackageStatusSelect onChange={this.onStatusChange} />
                  <p className="m-0 pt-2">{this.renderStatusHint()}</p>
                </FormGroup>
              </div>
              <p className="text-danger mb-0 mt-2">&nbsp;{this.state.errorMessage}</p>
            </div>
          </div>
        </div>

        <div className="modal-footer pt-0">
          <Button color="primary" type="button" className="w-100" onClick={this.handleSubmit}>
            حفظ
          </Button>
        </div>
      </Modal>
    );
  }
}

export default ChangeStatusModal;
