import React from "react";
import UtilityService from "service/utility";
import { Button, Col, Form, Modal, Row } from "reactstrap";
import CollectionService from "service/collection";

class CreateCollectionInvoiceModel extends React.Component {
  state = {
    isOpen: false,
    totalPackage: 0,
    totalCollection: 0.0,
    totalCost: 0.0,
    totalForMerchant: 0.0,
    selectedRows: [],
  };

  closeModal = () => {
    this.setState({
      isOpen: false,
    });
  };

  openModal = () => {
    this.setState({
      isOpen: true,
      selectedRows: this.props.selectedRows,
      totalPackage: this.props.selectedRows.length,
      totalCollection: this.props.selectedRows.reduce((acc, row) => acc + row.totalCollection, 0),
      totalCost: this.props.selectedRows.reduce((acc, row) => acc + row.cost, 0),
      totalForMerchant: this.props.selectedRows.reduce((acc, row) => acc + (row.totalCollection - row.cost), 0),
    });
  };

  handleCreateInvoice = () => {
    const ids = this.state.selectedRows.map((row) => row.id);
    CollectionService.createInvoice(ids)
      .then((res) => {
        this.props.navigate("/collections/" + res.data.id, { replace: true });
      })
      .catch((error) => {
        console.error("Error creating collections invoice:", error);
      });
  };

  render() {
    return (
      <>
      <span onClick={(e) => this.openModal(e)}><i className="fas fa-print mx-1" /> إنشاء كشف التحصيل</span>
      <Modal className="modal-dialog-centered" isOpen={this.state.isOpen} toggle={this.closeModal}>
      <div className="modal-header border-bottom pb-2">
        <h3 className="modal-title">هل أنت متأكد من إنشاء كشف التحصيل ؟  </h3>
        <button
          aria-label="Close"
          className="close"
          data-dismiss="modal"
          type="button"
          onClick={() => this.closeModal()}>
          <span aria-hidden={true}>×</span>
        </button>
      </div>
      <div className="modal-body">
        <Form>
          <Row className="bg-primary mx-0 rounded py-2">
            <Col lg="12">
              <div className="d-flex justify-content-between align-items-center text-white">
                <p className="mb-0 font-weight-bold">مجموع الطرود:</p>
                <p className="mb-0 font-weight-bold">{this.state.totalPackage}</p>
              </div>
            </Col>
            <Col lg="12">
              <div className="d-flex justify-content-between align-items-center text-white">
                <p className="mb-0 font-weight-bold">مجموع التحصيل:</p>
                <p className="mb-0 font-weight-bold">{UtilityService.formatPrice(this.state.totalCollection)}</p>
              </div>
            </Col>
            <Col lg="12">
              <div className="d-flex justify-content-between align-items-center text-white">
                <p className="mb-0 font-weight-bold">مجموع التكاليف:</p>
                <p className="mb-0 font-weight-bold">{UtilityService.formatPrice(this.state.totalCost)}</p>
              </div>
            </Col>
            <Col lg="12">
              <div className="d-flex justify-content-between align-items-center text-white">
                <p className="mb-0 font-weight-bold">المطلوب للتاجر:</p>
                <p className="mb-0 font-weight-bold">{UtilityService.formatPrice(this.state.totalForMerchant)}</p>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
      <div className="modal-footer border-top p-2">
        <Button color="secondary" data-dismiss="modal" type="button" onClick={() => this.closeModal()}>
          اغلاق
        </Button>
        <Button color="primary" type="button" onClick={() => this.handleCreateInvoice()}>
          انشاء
        </Button>
      </div>
    </Modal>
    </>
    );
  }
}

export default CreateCollectionInvoiceModel;
