import { useEffect, useState } from "react";
import { Card, CardBody, CardHeader, Container, Row, Table } from "reactstrap";
import { LogsService } from "service/logs";
import UtilityService from "service/utility";

const Logs = (props) => {
  const [logFileList, setLogFileList] = useState([]);

  useEffect(() => {
    populateLogsFileList();
  }, [props]);

  const populateLogsFileList = () => {
    LogsService.getLogsFileList().then((res) => {
      setLogFileList(res.data);
    });
  };

  const handleViewFile = (file) => {
    LogsService.getLogsFileContent(file.name).then((res) => {
      const newWindow = window.open("", "_blank", "width=800,height=800");
      newWindow.document.write("<pre>" + res.data + "</pre>");
    });
  };

  const handleDownloadFile = (file) => {
    LogsService.downloadLogsFile(file.name).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const a = document.createElement("a");
      a.href = url;
      a.download = file.name;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    });
  };

  return (
    <>
      <Container className="pt-2 pt-md-8" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent">
                <Row className="align-items-center">
                  <div className="col">
                    <h3 className="mb-0">السجلات</h3>
                  </div>
                  <div className="col text-left d-flex align-items-center justify-content-end">
                    <i className="fas fa-redo mx-2" role="button" onClick={populateLogsFileList}></i>
                  </div>
                </Row>
              </CardHeader>
              <CardBody className="p-0">
                <Row className="m-0">
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th className="px-4">اسم الملف</th>
                        <th>المسار</th>
                        <th>الحجم</th>
                        <th>اخر تعديل</th>
                        <th>تنزيل</th>
                      </tr>
                    </thead>
                    <tbody>
                      {logFileList &&
                        logFileList.map(function (file, index) {
                          return (
                            <tr key={index}>
                              <td className="px-4">{file.name}</td>
                              <td className="px-4">{file.path}</td>
                              <td className="px-4">{UtilityService.formatBytes(file.size)}</td>
                              <td className="px-4">
                                {UtilityService.formatDate(file.lastModifiedTime)}{" "}
                                {UtilityService.formatTime(file.lastModifiedTime)}
                              </td>
                              <td>
                                <i
                                  className="fas fa-download mx-2"
                                  role="button"
                                  onClick={() => handleDownloadFile(file)}></i>
                                {file.name.endsWith(".log") && (
                                  <i
                                    className="fas fa-file mx-2"
                                    role="button"
                                    onClick={() => handleViewFile(file)}></i>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </Table>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Logs;
