import { Col, Container, Row } from "reactstrap";

const Settings = () => {
  return (
    <>
      <Container fluid>
        <Row>
          <Col className="mb-3 mt-4" lg="6">
            Comming soon
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Settings;
