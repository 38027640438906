import ConfigAPI from "api/config";

export const ConfigService = {
  getAllConfig: function () {
    return ConfigAPI.getAllConfig();
  },
  saveConfig: function (name, value) {
    return ConfigAPI.saveConfig({ name, value });
  },
  deleteConfig: function (name) {
    return ConfigAPI.deleteConfig({ name });
  },
  refreshConfig: function (name) {
    return ConfigAPI.refreshConfig({ name });
  },
  refreshAllConfig: function () {
    return ConfigAPI.refreshAllConfig();
  },
};
