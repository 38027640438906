import { Link } from "react-router-dom";
import { Card, CardBody, CardTitle } from "reactstrap";

const OrdersStatisticWidget = (props) => {
  const PackagesHistoryStatus=['Z', 'H', 'J', 'L'];

  return (
    <Card className={"card-stats mb-0 mb-md-4 mb-xl-0 text-white"} style={{ border: `1px solid ${props.color}` }}>
      <CardBody>
        <Link to={PackagesHistoryStatus.includes(props.status) ? "/packages/history?status=" + props.status  : "/packages?status=" + props.status} >
          <div className="col text-center py-1">
            <CardTitle tag="h2" className="text-uppercase mb-0 " style={{ color: props.color }}>
              {props.title}
            </CardTitle>
            <span className="h1 font-weight-bold mb-0" style={{ color: props.color }}>
              {props.value} / {props.totalPackage}
            </span>
          </div>
        </Link>
      </CardBody>
    </Card>
  );
};

export default OrdersStatisticWidget;
