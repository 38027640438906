import dayjs from "dayjs";
import React, { useState } from "react";
import { DatePicker, Tabs } from "antd";
import { FormGroup, Input, Modal, Button, Row, Col } from "reactstrap";
import moment from "moment";
import CollectionService from "service/collection";

function PaymentDialog(props) {
  const { collectionsId, show, onHide } = props;

  const initialBankAndWalletInfo = {
    paymentDate: moment().format('YYYY-MM-DD'),
    paymentMethod: "",
    paymentType: "BANK", // default tab is Bank Transfer
    transactionNumber: "",
  };
  const initialCachInfo = {
    paymentDate: moment().format('YYYY-MM-DD'),
    paymentType: "CACH",
  }

  const [paymentInfo, setPaymentInfo] = useState(initialBankAndWalletInfo);
  const [cachInfo, setCachInfo] = useState(initialCachInfo);
  const [activeTap, setActiveTap] = useState(1);
  const [displayError, setDisplayError] = useState(false);

  const CloseModal = () => {
    onHide();
    setPaymentInfo(initialBankAndWalletInfo);
    setDisplayError(false);
  };

  const handleTabChange = (activeKey) => {
    activeKey = parseInt(activeKey, 10); // convert string key to int

    setActiveTap(activeKey);
    setDisplayError(false);

    //set paymentType depend on activeKey
    if (activeKey === 1) {
      setPaymentInfo({
        ...initialBankAndWalletInfo,
        paymentType: "BANK",
      });
    } else if (activeKey === 2) {
      setCachInfo(initialCachInfo);
    } else if (activeKey === 3) {
      setPaymentInfo({
        ...initialBankAndWalletInfo,
        paymentType: "WALLET",
      });
    }
  };

  const handleClick = () => {
    if (activeTap === 1 || activeTap === 3) {
      if (paymentInfo.paymentMethod !== "" && paymentInfo.transactionNumber !== "") {
        CollectionService.payCollections(collectionsId, paymentInfo)
          .then((success) => {
            if (success) {
              setDisplayError(false);
              setPaymentInfo(initialBankAndWalletInfo);
            } else {
              setDisplayError(true);
            }
          })
          .catch(() => {
            setDisplayError(true);
          });
      }
      else {
        setDisplayError(true);
      }
    } else if (activeTap === 2) {
      CollectionService.payCollections(collectionsId, cachInfo)
        .then((success) => {
          if (success) {
            setDisplayError(false);
            setCachInfo(initialCachInfo);
          } else {
            setDisplayError(true);
          }
        })
        .catch(() => {
          setDisplayError(true);
        });
    }
  };
  const handleDate = (dateString) => {
    const selectedMoment = moment(dateString, "YYYY-MM-DD");
    const selectedDateOnly = selectedMoment.format("YYYY-MM-DD");

    if (activeTap === 1 || activeTap === 3) {
      setPaymentInfo({
        ...paymentInfo,
        paymentDate: selectedDateOnly,
      });
    } else if (activeTap === 2)
      setCachInfo({
        ...cachInfo,
        paymentDate: selectedDateOnly,
      });
  };

  const { TabPane } = Tabs;
  return (
    <>
      <Modal className="modal-dialog-centered" isOpen={show} toggle={CloseModal}>
        <div className="modal-body">
          <Tabs defaultActiveKey="1" onChange={handleTabChange}>
            <TabPane tab="حوالة بنكية" key="1" style={{ height: "180px" }} margin=" 0 32px 0 32px">
              <Row className="customer-list p-3">
                <Col lg="6">
                  <FormGroup>
                    <div>
                      <label className="form-control-label">التاريخ:</label>
                      <DatePicker
                        defaultValue={dayjs()}
                        onChange={handleDate}
                        style={{ width: "180px" }}
                      />
                    </div>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <Row>
                    <Col>
                      <label className="form-control-label">طريقة الدفع:</label>
                    </Col>
                  </Row>
                  <select
                    className="form-control form-control-sm"
                    onChange={(e) => {
                      setPaymentInfo({
                        ...paymentInfo,
                        paymentMethod: e.target.value,
                      });
                    }}
                  >
                    <option>--- يرجى الاختيار ---</option>
                    <option value="BOP">بنك فلسطين</option>
                    <option value="ARAB_BANK"> بنك العربي</option>
                    <option value="REFLECT">ريفليكت</option>
                    <option value="JAWWAL_PAU">جوال باي</option>
                  </select>
                </Col>
              </Row>
              <Row className="customer-list p-3">
                <Col lg="6">
                  <label className="form-control-label"> الرقم المرجعي:</label>
                  <Input
                    className="form-control form-control-sm"
                    type="number"
                    onChange={(e) => {
                      setPaymentInfo({
                        ...paymentInfo,
                        transactionNumber: e.target.value,
                      });
                    }}
                  />
                </Col>
              </Row>
              {displayError && (
                <div>
                  <p className="text-danger">{"خطأ في تعبئة البيانات  "}</p>
                </div>
              )}
            </TabPane>
            <TabPane tab="تسليم نقدي" key="2" style={{ height: "180px" }} margin=" 0 32px 0 32px">
              <Row>
                <Col lg="6">
                  <FormGroup>
                    <div className="pl-lg-4">
                      <label className="form-control-label">التاريخ:</label>
                    </div>
                    <DatePicker
                      defaultValue={dayjs()}
                      onChange={handleDate}
                      style={{ width: "205px", marginRight: "10px" }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              {displayError && (
                <div>
                  <p className="text-danger">{"خطأ في تعبئة البيانات  "}</p>
                </div>
              )}
            </TabPane>
            <TabPane tab="محفظة إلكترونية" key="3" style={{ height: "180px" }} margin=" 0 32px 0 32px">
              <Row className="customer-list p-3">
                <Col lg="6">
                  <FormGroup>
                    <div>
                      <label className="form-control-label">التاريخ:</label>
                      <DatePicker
                        defaultValue={dayjs()}
                        onChange={handleDate}
                        style={{ width: "180px" }}
                      />
                    </div>
                  </FormGroup>
                </Col>
                <Col lg="6">
                  <Row>
                    <Col>
                      <label className="form-control-label">طريقة الدفع:</label>
                    </Col>
                  </Row>
                  <select
                    className="form-control form-control-sm"
                    onChange={(e) => {
                      setPaymentInfo({
                        ...paymentInfo,
                        paymentMethod: e.target.value,
                      });
                    }}>
                    <option>--- يرجى الاختيار ---</option>
                    <option value="BOP">بنك فلسطين</option>
                    <option value="ARAB_BANK"> بنك العربي</option>
                    <option value="REFLECT">ريفليكت</option>
                    <option value="JAWWAL_PAU">جوال باي</option>
                  </select>
                </Col>
              </Row>
              <Row className="customer-list p-3">
                <Col lg="6">
                  <label className="form-control-label"> الرقم المرجعي:</label>
                  <Input
                    className="form-control form-control-sm"
                    type="number"
                    onChange={(e) => {
                      setPaymentInfo({
                        ...paymentInfo,
                        transactionNumber: e.target.value,
                      });
                    }}
                  />
                </Col>
              </Row>
              {displayError && (
                <div>
                  <p className="text-danger">{"خطأ في تعبئة البيانات  "}</p>
                </div>
              )}
            </TabPane>
          </Tabs>
        </div>

        <div className="modal-footer">
          <Button color="secondary" data-dismiss="modal" type="button" onClick={CloseModal}>
            اغلاق
          </Button>
          <Button color="primary" type="button" onClick={handleClick}>
            حفظ
          </Button>
        </div>
      </Modal>
    </>
  );
}
export default PaymentDialog;
