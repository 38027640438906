import { DropdownMenu, DropdownToggle, Nav, UncontrolledDropdown } from "reactstrap";
import NotificationItem from "./NotificationItem";

const NotificationBox = () => {
  return (
    <Nav className="align-items-center d-none d-md-flex notification-box" navbar>
      <UncontrolledDropdown nav>
        <DropdownToggle className="p-0" nav>
          <i className="fas fa-bell fa-2x"></i>
        </DropdownToggle>
        <DropdownMenu className="notification-result" right>
          {false && (
            <>
              <NotificationItem icon="fas fa-cubes" />
              <NotificationItem icon="fas fa-shopping-cart" />
              <NotificationItem icon="fas fa-file-invoice" />
              <NotificationItem icon="fas fa-cog" />
            </>
          )}
        </DropdownMenu>
      </UncontrolledDropdown>
    </Nav>
  );
};

export default NotificationBox;
