import CitySelect from "components/Address/CitySelect";
import React from "react";
import { Button, Col, Form, FormGroup, Input, Modal, Row } from "reactstrap";
import OrganizationService from "service/organization";

class EditBranchModal extends React.Component {
  state = {
    isOpen: false,
    branchName: null,
    address: null,
    city: null,
    phone: null,
    showError: false,
    errorMessage: "",
    organizationId: null,
  };

  closeModal = () => {
    this.setState({
      isOpen: false,
    });
  };

  openModal = (e) => {
    this.setState({
      isOpen: true,
      branchName: this.props.branch?.name || "",
      address: this.props.branch?.address || "",
      city: this.props.branch?.city || "",
      phone: this.props.branch?.phone || "",
      showError: false,
      errorMessage: "",
    });
  };

  handelSubmit = () => {
    const self = this;
    this.setState({ showError: true, errorMessage: "" });

    if (this.validateForm()) {
      OrganizationService.updateBranch(
        this.props.branch.id,
        this.state.branchName,
        this.state.city,
        this.state.address,
        this.state.phone
      )
        .then((res) => {
          self.closeModal();
          if (self.props.onChange) {
            self.props.onChange();
          }
        })
        .catch((error) => {
          self.setState({ errorMessage: "خطا في حفظ البيانات. الرجاء المحاولة مرة اخرى" });
        });
    }
  };

  validateForm = () => {
    let isValid = true;
    if (!this.state.branchName || !this.state.address || !this.state.city) {
      isValid = false;
    }

    return isValid;
  };

  render() {
    const { branchName, showError, errorMessage, address, city, phone, organizationId } = this.state;

    return (
      <>
        <span onClick={(e) => this.openModal(e)}>تعديل</span>

        <Modal className="modal-dialog-centered modal-lg" isOpen={this.state.isOpen} toggle={() => this.closeModal()}>
          <div className="modal-header border-bottom">
            <h3 className="modal-title">{this.props.title}</h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.closeModal()}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <Form>
              <div className="pl-lg-4">
                <Row>
                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label">اسم الفرع</label>
                      <Input
                        className={`form-control ${showError && !branchName ? "is-invalid" : ""}`}
                        type="text"
                        value={branchName}
                        onChange={(e) => this.setState({ branchName: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="4">
                    <CitySelect
                      defaultValue={city}
                      onChange={(e) => this.setState({ city: e.target.value })}
                      showError={showError}
                    />
                  </Col>
                  <Col lg="4">
                    <FormGroup>
                      <label className="form-control-label">هاتف</label>
                      <Input
                        className="form-control"
                        type="text"
                        value={phone}
                        onChange={(e) => this.setState({ phone: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                  <Col lg="12">
                    <FormGroup>
                      <label className="form-control-label">العنوان</label>
                      <Input
                        className={`form-control ${showError && address.trim().length === 0 ? "is-invalid" : ""}`}
                        type="text"
                        value={address}
                        onChange={(e) => this.setState({ address: e.target.value })}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </div>
            </Form>
          </div>
          <div className="modal-footer border-top">
            <span className="text-danger ml-auto">{errorMessage}</span>
            <Button color="secondary" data-dismiss="modal" type="button" onClick={() => this.closeModal()}>
              اغلاق
            </Button>
            <Button color="success" type="button" onClick={this.handelSubmit}>
              حفظ
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default EditBranchModal;
