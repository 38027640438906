import OrganizationSelect from "components/Organization/OrganizationSelect";
import React from "react";
import { Button, FormGroup, Input, Modal } from "reactstrap";
import PriceListService from "service/priceList";

class PriceTestModal extends React.Component {
  state = {
    isOpen: false,
    result: "",
    showError: false,
    organizationId: null,
    areaCode: "",
  };

  closeModal = () => {
    this.setState({
      isOpen: false,
    });
  };

  openModal = () => {
    this.setState({
      isOpen: true,
      showError: false,
      organizationId: null,
      areaCode: null,
    });
  };

  onOrganizationChange = (org) => {
    this.setState({ organizationId: org.id });
  };

  onAreaCodeChange = (e) => {
    this.setState({ areaCode: e.target.value });
  };

  hadnleTest = () => {
    if (this.state.organizationId && this.state.areaCode) {
      this.setState({ result: "" });
      PriceListService.resolvePrice(this.state.organizationId, this.state.areaCode).then((res) => {
        this.setState({ result: "تكلفة التوصيل هي: " + res.data.cost });
      });
    } else {
      this.setState({ result: "الرجاء اختيار الشركة و ادخال المنطقة" });
    }
  };

  render() {
    const { result, showError, areaCode } = this.state;

    return (
      <>
        <Button color="success" type="button" onClick={() => this.openModal()} size="sm">
          فحص السعر
        </Button>

        <Modal className="modal-dialog-centered" isOpen={this.state.isOpen} toggle={() => this.closeModal()}>
          <div className="modal-header border-bottom">
            <h3 className="modal-title">فحص السعر</h3>
            <button
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => this.closeModal()}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <OrganizationSelect onChange={this.onOrganizationChange} showError={showError} />
            <FormGroup>
              <label className="form-control-label">رمز المنطقة:</label>
              <Input
                className={`form-control ${showError ? "is-invalid" : ""}`}
                type="text"
                value={areaCode}
                onChange={this.onAreaCodeChange}
              />
            </FormGroup>
            <Button color="primary" className="w-100 mr-0" type="button" onClick={() => this.hadnleTest()}>
              فحص
            </Button>
            <p className="text-primary mt-4 mb-0">{result}</p>
          </div>
          <div className="modal-footer border-top">
            <Button color="secondary" data-dismiss="modal" type="button" onClick={() => this.closeModal()}>
              اغلاق
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default PriceTestModal;
