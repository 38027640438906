import { authAPI } from 'api/auth';

export const authService = {
  login: function (userName, password) {
    return authAPI.login(userName, password);
  },
  isLogin: function () {
    if (localStorage.getItem('jwtToken') !== null) return true;
    else if (localStorage.getItem('jwtToken') === null) return false;
  },
  logout: function () {
    localStorage.removeItem('jwtToken');
    localStorage.removeItem('user');
  },
  resetPassword: function (userName) {
    return authAPI.resetPassword({ userName: userName });
  },
};
