import StatisticWidget from "components/Headers/StatisticWidget";
import { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import StatisticService from "service/statistic";
import UtilityService from "service/utility";

const GeneralStatistic = (props) => {
  const [statisticData, setStatisticData] = useState(null);

  useEffect(() => {
    if (!statisticData) {
      StatisticService.getSummaryStatistic().then((res) => {
        setStatisticData(res.data);
      });
    }
  }, [props]);

  return (
    <>
      {statisticData && (
        <Row>
          <Col lg="3" xl="2" className="mb-4">
            <StatisticWidget
              url="/packages/history"
              title="اجمالي الطرود"
              value={statisticData.totalPackages}
              icon="fas fa-cubes"
              color="primary"
            />
          </Col>
          <Col lg="3" xl="2" className="mb-4">
            <StatisticWidget
              url="/packages"
              title="الطرود النشطة"
              value={statisticData.totalActivePackages}
              icon="fas fa-cubes"
              color="success"
            />
          </Col>
          <Col lg="3" xl="2" className="mb-4">
            <StatisticWidget
              url="/collections"
              title="اجمالي التحصيلات"
              value={UtilityService.formatPrice(statisticData.totalCollection)}
              icon="fas fa-money-bill"
              color="info"
            />
          </Col>
          <Col lg="3" xl="2" className="mb-4">
            <StatisticWidget
              url="/collections"
              title="تحصيلات قيد التنفيذ"
              value={UtilityService.formatPrice(statisticData.totalActiveCollection)}
              icon="fas fa-money-bill"
              color="danger"
            />
          </Col>
          <Col lg="3" xl="2" className="mb-4">
            <StatisticWidget
              url="/customers"
              title="الزبائن"
              value={statisticData.totalCustomers}
              icon="fas fa-users"
              color="dark"
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default GeneralStatistic;
