import APIClient from './axios.js';
import { SEARCH_API_URL } from '../variables/constant';

const SearchAPI = {
  search: function (data) {
    return APIClient.get(SEARCH_API_URL, { params: data });
  },
};

export default SearchAPI;
