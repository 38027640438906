import { useEffect, useState } from "react";
import MessageService from "service/message";

const ChatDetails = (props) => {
  const { userId } = props;
  const [data, setData] = useState(null);
  const [message, setMessage] = useState("");

  const fetchData = () => {
    if (userId) {
      MessageService.getMessageList(userId, 0, 10).then((res) => setData(res.data));
    }
  };

  useEffect(() => {
    if (userId) {
      fetchData();
    }
  }, [userId]);

  const renderPoto = () => {
    if (data.userPhoto) {
      return <img src={data.userPhoto} />;
    } else {
      return (
        <div className="icon icon-shape bg-primary text-white rounded-circle shadow">
          <i className="fas fa-user"></i>
        </div>
      );
    }
  };

  const submitMessage = () => {
    MessageService.sendMessage(userId, message).then(() => {
      setMessage("");
      fetchData();
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      submitMessage();
    }
  };
  return (
    <>
      {data && (
        <div>
          <div className="chat-details-header border-bottom px-4 py-2 d-flex">
            {renderPoto()}
            <div className="mr-2">
              <p className="m-0 name">{data.userName}</p>
              <p className="m-0 mobile">{data.userMobile}</p>
            </div>
          </div>
          {data.messageList && data.messageList.length > 0 && (
            <ul className="p-4">
              {data.messageList?.map((message, index) => {
                return (
                  <div
                    key={index}
                    className={"message-item d-flex " + (message.senderId == userId ? "received " : "sent")}>
                    <p className="message-text m-0">{message.text}</p>
                  </div>
                );
              })}
            </ul>
          )}
          {!data.messageList ||
            (data.messageList.length === 0 && (
              <div className="text-center">
                <i className="fas fa-comment-dots fa-4x mt-8"></i>
                <h1 className="mt-4">لا يوجد رسائل</h1>
              </div>
            ))}
          <div className="chat-details-footer border-top px-4 py-2 d-flex">
            <div class="mb-0 form-group">
              <div class="input-group-alternative input-group">
                <input
                  placeholder="Aa"
                  value={message}
                  type="text"
                  class="form-control"
                  onSubmit={submitMessage}
                  onKeyDown={handleKeyDown}
                  onChange={(e) => setMessage(e.target.value)}
                />
                <div class="input-group-append">
                  <span class="input-group-text" onClick={submitMessage}>
                    <i class="fas fa-arrow-alt-circle-left"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChatDetails;
