import UtilityService from "service/utility";
import { PACKAGE_STATUS_LIST } from "variables/constant";

const PackageStatusSelect = (props) => {
  return (
    <select onChange={(event) => props.onChange(event.target.value)} className="form-control">
      <option value="">-- اختر الحالة --</option>
      {PACKAGE_STATUS_LIST.map((status) => {
        return (
          <option key={status} value={status}>
            {UtilityService.renderStatusMessage("PACKAGE", status, false, true)}
          </option>
        );
      })}
    </select>
  );
};

export default PackageStatusSelect;
