import { Button, Card, CardBody, CardHeader, Col, Form, FormGroup, Input, Row } from "reactstrap";

import { useEffect, useState } from "react";
import UserService from "service/user";
import CitySelect from "components/Address/CitySelect";
import AreaSelect from "components/Address/AreaSelect";

const PrimaryAddress = (props) => {
  const [city, setCity] = useState("");
  const [address1, setAddress1] = useState("");
  const [areaCode, setAreaCode] = useState("");

  const [loading, setLoading] = useState(true);

  const [message, setMessage] = useState("");
  const [messageClass, setMessageClass] = useState("");

  useEffect(() => {
    UserService.getCurrentUserPrimaryAddress().then((res) => {
      if (res.data) {
        setAddress1(res.data.address1);
        setCity(res.data.area?.city);
        setAreaCode(res.data.area?.code);
      }
      setLoading(false);
    });
  }, []);

  const handleSaveAddress = () => {
    setMessage("");
    setMessageClass("");
    if (validateForm()) {
      UserService.updateCurrentUserPrimaryAddress({
        address1: address1,
        areaCode: areaCode,
      })
        .then(() => {
          setMessage("تم تعديل العنوان بنجاح!");
          setMessageClass("success");
        })
        .catch(() => {
          setMessage("فشل في تعديل العنوان. الرجاء المحاولة مرة اخرى لاحقا!");
          setMessageClass("danger");
        });
    }
  };

  const validateForm = () => {
    if (!address1 || areaCode) {
      setMessage("جميع الحقول مطلوبة");
      setMessageClass("danger");
      return false;
    }
    return true;
  };

  return (
    <Card className="bg-secondary shadow">
      <CardHeader className="bg-white border-0">
        <Row className="align-items-center">
          <Col xs="8">
            <h3 className="mb-0">العنوان الرئسي</h3>
          </Col>
          <Col className="text-left" xs="4">
            <Button color="success" onClick={handleSaveAddress}>
              حفظ
            </Button>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        {!loading && (
          <Form>
            <div className="pl-lg-4">
              <Row>
                <Col md="12">
                  <FormGroup>
                    <label className="form-control-label">العنوان</label>
                    <Input
                      className="form-control"
                      placeholder="العنوان"
                      type="text"
                      value={address1}
                      onChange={(e) => {
                        setAddress1(e.target.value);
                      }}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg="6">
                  <CitySelect defaultValue={city} onChange={(e) => setCity(e.target.value)} />
                </Col>
                <Col lg="6">
                  <AreaSelect defaultValue={areaCode} city={city} onChange={(e) => setAreaCode(e.target.value)} />
                </Col>
              </Row>
            </div>
          </Form>
        )}
        <span className={`text-${messageClass} mx-2`}>{message}</span>
      </CardBody>
    </Card>
  );
};

export default PrimaryAddress;
