import React from "react";
import { Button, FormGroup, Modal } from "reactstrap";
import { DatePicker } from "antd";
import dayjs from "dayjs";
class ChangeDeliveryDateModel extends React.Component {
  state = {
    deliveryDate: null,
    errorMessage: "",
    isStatusChangeChecked:false,
  };

  onDeliveryDateChange = (deliveryDate) => {
  
    this.setState({ deliveryDate: deliveryDate });
    this.setState({ errorMessage: "" });
  };
  
  onStatusChangeChecked= () => {
    this.setState({isStatusChangeChecked: !this.state.isStatusChangeChecked});
  };

  handleSubmit = () => {
    const self = this;
    if (this.state.deliveryDate) {
      const formattedDate =  dayjs(this.state.deliveryDate).format('YYYY-MM-DDTHH:mm:ss.SSSZ');
      this.props.onChange(formattedDate,this.state.isStatusChangeChecked);
      this.handleCloseModal();
    } else {
      this.setState({ errorMessage: "الرجاء اختيار تاريخ التوصيل الجديد" });
    }
  };
  
  handleCloseModal = () => {
    this.setState({ deliveryDate: null });
    this.setState({ errorMessage: "" });
    this.setState({ isStatusChangeChecked: false });
    this.props.onClose();
};


  render() {
    return (
      <Modal className="modal-dialog-centered" isOpen={this.props.isOpen} toggle={this.handleCloseModal} >
        <div className="modal-body pb-0">
          <div justify-content="center">
            <div>
              <div>
                <FormGroup className="mb-2">
                  <label className="form-control-label">{" "} الرجاء اختيار تاريخ التوصيل الجديد:{" "} </label>
                  <DatePicker className="form-control" placeholder="-- الرجاء اختيار تاريخ التوصيل الجديد --"  onChange={this.onDeliveryDateChange}/>
                </FormGroup>
                <FormGroup  className="mt-3 mb-0">
                  <input onChange={this.onStatusChangeChecked} type="checkbox"  checked={this.state.isStatusChangeChecked}/>
                  <label className="mr-2 form-control-label">تغير الحالة الى مؤجل</label>
                </FormGroup>
              </div>
              <p className="text-danger mb-0 mt-2">
                &nbsp;{this.state.errorMessage}
              </p>
            </div>
          </div>
        </div>

        <div className="modal-footer pt-0">
          <Button color="primary" type="button" className="w-100" onClick={this.handleSubmit}> حفظ</Button>
        </div>
      </Modal>
    );
  }
}

export default ChangeDeliveryDateModel;
