const FloatingLAbleInput = (props) => {
  return (
    <div className="floating-lable-input">
      <lable className="floating-lable-input-lable">{props.lable}</lable>
      <input className="form-control floating-lable-input-input" onChange={props.onChange} />
    </div>
  );
};

export default FloatingLAbleInput;
