import React from 'react';
import { Button, Col, Input, Modal, Row } from 'reactstrap';
import OrganizationService from 'service/organization';

class AdminResetUserPassword extends React.Component {
  state = {
    isOpen: false,
    password: '',
    errorMessage: '',
  };

  openModal = () => {
    this.setState({
      isOpen: true,
      password: this.generateRandomPassword(),
      errorMessage: '',
    });
  };

  closeModal = () => {
    this.setState({
      isOpen: false,
    });
  };

  generateRandomPassword = () => {
    let password = '';
    const length = 8;
    const characters = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZ';

    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * characters.length);
      password += characters[randomIndex];
    }

    return password;
  };

  handleResetPassword = () => {
    this.setState({ errorMessage: '' });
    if (this.state.password) {
      OrganizationService.resetUserPassword(this.props.userId, this.state.password)
        .then((res) => {
          this.closeModal();
        })
        .catch(() => {
          this.setState({ errorMessage: 'ناسف, فشل في اعادت تعين كلمة المرور. الرجاء المحاولة مرة اخرى لاحقا.' });
        });
    } else {
      this.setState({ errorMessage: 'الرجاء ادخال كلمة مرور جديدة.' });
    }
  };

  render() {
    const { isOpen } = this.state;
    return (
      <>
        <span onClick={(e) => this.openModal(e)}>اعادة تعين كلمة المرور</span>

        <Modal className="modal-dialog-centered" isOpen={isOpen} toggle={() => this.closeModal()}>
          <div className="modal-body pb-0">
            <div justify-content="center">
              <h1>هل تريد اعادة تعين كلمة المرور؟</h1>

              <div>
                <p>قم بادخال كلمة المرور الجديدة.</p>
                <Row className="row">
                  <Col>
                    <Input
                      className={'form-control ' + (this.state.errorMessage ? 'is-invalid' : '')}
                      type="text"
                      value={this.state.password}
                      onChange={(e) => {
                        this.setState({ password: e.target.value });
                      }}
                    />
                  </Col>
                  <Col>
                    <Button color="success" type="button" onClick={(e) => this.setState({ password: this.generateRandomPassword() })}>
                      انشاء كلمة مرور جديدة
                    </Button>
                  </Col>
                </Row>
                <p className="mt-2">سيتم ارسال كلمة مرور الجديدة الى الهاتف المسجل لدينا كرسالة نصية SMS.</p>
                <p className="text-danger mb-0 mt-2">&nbsp;{this.state.errorMessage}</p>
              </div>
            </div>
          </div>

          <div className="modal-footer pt-0">
            <Button color="primary" type="button" className="w-100" onClick={this.handleResetPassword}>
              اعادة تعين كلمة المرور
            </Button>
          </div>
        </Modal>
      </>
    );
  }
}

export default AdminResetUserPassword;
