import React from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
// reactstrap components
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import { adminRoutes, adminSidebarRoutes, sidebarRoutes } from "routes.js";

const Admin = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      const Component = prop.component;
      return <Route path={prop.path} exact element={<Component />} key={key} />;
    });
  };

  const getBrandText = (path) => {
    for (let i = 0; i < adminRoutes.length; i++) {
      if (props?.location?.pathname.indexOf(adminRoutes[i].path) !== -1) {
        return adminRoutes[i].name;
      }
    }
    return "Brand";
  };

  return (
    <>
      <Sidebar
        {...props}
        routes={sidebarRoutes}
        adminRoutes={adminSidebarRoutes}
        logo={{
          innerLink: "/",
          imgSrc: require("../assets/img/brand/full_logo.png"),
          imgAlt: "...",
        }}
      />
      <div className="main-content" ref={mainContent}>
        <AdminNavbar {...props} brandText={getBrandText(props?.location?.pathname)} />
        <Routes>
          {getRoutes(adminRoutes)}
          <Route path="*" element={<Navigate to="/not-found" replace={true} />} />
        </Routes>
      </div>
    </>
  );
};

export default Admin;
